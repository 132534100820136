import React, { FC, useCallback } from "react";
import { Button } from "../Primitives/Button";
import { Simulate } from "react-dom/test-utils";

type Paginator = {
  currentPage: number;
  maxPage: number;
  havePrev: boolean;
  haveNext: boolean;

  changePage?: (newPage: number) => void;
};

const Paginator: FC<Paginator> = ({
  currentPage,
  maxPage,
  havePrev,
  haveNext,
  changePage = () => {},
}) => {
  const handlePrevPage = useCallback(() => {
    if (havePrev) {
      changePage(currentPage - 1);
    }
  }, [havePrev, currentPage]);

  const handleNextPage = useCallback(() => {
    if (haveNext) {
      changePage(currentPage + 1);
    }
  }, [haveNext, currentPage]);

  return (
    <div>
      <Button disabled={!havePrev} onClick={handlePrevPage}>
        {"<"}
      </Button>
      {currentPage}/{maxPage}
      <Button disabled={!haveNext} onClick={handleNextPage}>
        {">"}
      </Button>
    </div>
  );
};

export default Paginator;
