import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 350px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  background: #ffffff;
  margin-bottom: 34px;
  display: flex;
  padding: 32px 20px;
  > div {
    padding: 0 20px;
  }
`;
export const Col = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
`;
export const Span = styled.span`
  position: relative;
`;
export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Title = styled.h3`
  position: relative;
  color: #2a0f51;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;
export const Content = styled.div`
  position: relative;
  margin-top: auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;    
  }
`;
