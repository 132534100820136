import { AccessToken, FlatStructure, StructureDetail, StructureId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type SubStructuresResponse = {
    structures: FlatStructure[];
};

export async function getSubStructureSimple(id: StructureId): Promise<SubStructuresResponse> {
    const response = await axios.get(`${settings.apiBasePath}/api/structure/sub-structures/simple/${id}`);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
