import {useTranslation} from "react-i18next";
import {FlatStructure} from "../modules/api/api-types";

export default () => {
    const { t } = useTranslation();

    const placeSubTitle = (structure: FlatStructure): string => {
        const title: string[] = []

        if(structure.floorNumber){
            title.push(structure.floorNumber.toString());
        }

        if(structure.flatNumber){
            title.push(structure.flatNumber.toString());
        }

        return `${t('structure.place')} ${title.join('.')}`;
    }

    const createTitle = (structure: FlatStructure): string => {
        if(structure.customName !== '') {
            return structure.customName;
        }

        return placeSubTitle(structure);
    }

    const haveSubtitle = (structure: FlatStructure): boolean => {
        return structure.customName !== '';
    }

    const fullTitle = (structure: FlatStructure): string => {
        const subTitle = placeSubTitle(structure);

        if(structure.customName !== '') {
            return `${structure.customName} (${subTitle})`
        }

        return subTitle;
    }

    return {
        placeSubTitle,
        createTitle,
        haveSubtitle,
        fullTitle
    }
}