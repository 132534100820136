export type BaseAction<T, P = unknown> = {
    type: T;
    payload?: P;
};

export function createAction<T, P>(type: T, payload?: P): BaseAction<T, P> {
    return {
        type,
        payload,
    };
}
