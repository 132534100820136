export default {
  customName: "Eigenname",
  description: "Beschreibung",
  access: {
    email: "Email",
    start: "Anfang",
    end: "Ende",
    immediately: "Sofort zugreifen",
    lifetime: "Unbegrenzte Gültigkeit",
  },
  noDevices: "In diesem Raum ist kein Messgerät installiert",
  place: "Räumlichkeit",
  noAccess: "Dieser Bereich hat keinen anderen Zugriff zugewiesen.",
  reallyRemoveAccess: "Möchten Sie diesen Zugriff löschen?",
};
