export default {
    loadingData: 'Načítám',
    noData: 'Bez dat',
    todayConsumption: 'Denní spotřeba',
    monthlyConsumption: 'Měsíční spotřeba',
    thisMonthConsumption: 'Spotřeba tento měsíc',
    lastMonthConsumption: 'Spotřeba minulý měsíc',
    temp: 'Teplota',
    setTemp: 'Nastavená teplota',
    setCommandTemp: 'Nová požadovaná teplota',
    humidity: 'Vlhkost',
    childLock: 'Dětský zámek',
    valve: 'Ventil',
    changesCanTakeFewMinutesToTakeEffect: 'Může trvat několik minut než se hodnota propíše do zařízení.',
    measurementState: {
        cold: 'Stav vodoměru',
        hot: 'Stav vodoměru',
        itn: 'Stav RTN',
        cal: 'Stav kalorimetru',
        cntrl: 'Stav vodoměru',
        thermo: 'Teplota',
        '-': 'Stav',
    },
    measurementType: {
        cold: 'Studená voda',
        hot: 'Teplá voda',
        itn: 'RTN',
        cal: 'Kalorimetr',
        cntrl: 'Kontrolní vodoměr',
        thermo: 'Termohlavice',
        '-': 'Neznámé zařízení',
    },
    export: {
        title: 'Export',
        runningExportDevice: 'Spouštím export zařízení',
        runningExportFinalMeasurements: 'Spouštím export koncových náměrů',
        runningExportFailed:
            'Export se nezdařil. Náš tým byl o tomto incidentu informován, brzo se jej pokusíme vyřešit.',
        button: 'Export náměrů v období',
        buttonStructure: 'Export koncových náměrů v období',
    },
    errors: {
        leak: 'Únik',
        'battery warning': 'Blíží se vybití baterie',
        'no consumption': 'Žádná spotřeba',
        tamper: 'Pokus o ovlivnění',
        removal: 'Odstranění modulu',
        'reverse installation': 'Opačná instalace',
        oversized: 'Naddimenzovaný měřič',
        undersized: 'Poddimenzovaný měřič',
        burst: 'Prasklina',
        'battery EOL': 'Konec životnosti baterie',
        dry: 'Vodoměr "na suchu"',
        frost: 'Zamrzá!',
        backflow: 'Zpětný průtok',
        'battery prewarning': 'Blíží se vybití baterie',
    },
    errorDescriptions: {
        leak: 'Průtok měřidlem je nenulový více než 24 hodin, zkontrolujte vedení a spotřebiče.',
        'battery warning': 'Stav baterie indikuje 3 měsíce do vybití vysílače, kontaktujte správce.',
        'no consumption': 'U vodoměru není delší dobu zaznamnána spotřeba, může se jednat o nevyužitý prostor.',
        tamper: 'Podezření na vnější ovlivnění spotřeby, proveďte vizuální kontrolu měřidla.',
        removal: 'Podezření na odstranění modulu z měřiče, proveďte vizuální kontrolu měřidla.',
        'reverse installation': 'Obrácený průtok měřidlem více než 12 hodin, kontaktujte servisního technika.',
        oversized: 'Nastane, pokud průtok nikdy nepřekročí 10% Q3 (po dobu 30 dnů)',
        undersized: 'Nastane, pokud je průtok bez přerušení větší než Q3 (po dobu 6 hodin)',
        burst: 'Únik je vyšší nez 30% nominálního průtoku za dobu 30 minut, uzavřete přívod vody a zkontrolujte vedení.',
        'battery EOL': 'Baterie měřidla se vybila, kontaktujte správce pro výměnu měřidla.',
        dry: 'Zavřený přívod vody do měřidla. Zkontrolujte uzávěr vody.',
        frost: 'Riziko zamrznutí a poškození těla vodoměru. Nutná kontrola servisního technika.',
        backflow:
            'Měřidlo zaznamenalo zpětný průtok, doporučení kontroly směšovacích zařízení a montáže zpětné klapky.',
        'battery prewarning': 'Stav baterie indikuje 3 roky do vybití vysílače, kontaktujte správce.',
    },
};
