export default {
    save: 'Uložit',
    logout: 'Odhlásit se',
    loginForm: {
        fill: 'Vyplň svůj e-mail a heslo. ',
        badCredentials: 'Nespravný e-mail nebo heslo.',
    },
    fromDate: 'Od',
    toDate: 'Do',

    add: 'Přidat',
    settings: 'Nastavení',
    change: 'Změnit',
    close: 'Zavřít',
    edit: 'Upravit',
    accesses: 'Přístupy',
    removeAccess: 'Odstranit přístup',
    unlimited: 'Neomezený',

    search: 'Vyhledat',
    remove: 'Odstranit',

    user: {
        user: 'Uživatel',
        firstName: 'Jméno',
        lastName: 'Příjmení',
        email: 'E-mail',
        emailOrUsername: 'E-mail/Uživatelské jméno',
        currentPassword: 'Současné heslo',
        password: 'Heslo',
        passwordAgain: 'Heslo znovu',
        newPassword: 'Nové heslo',
        newPasswordAgain: 'Nové heslo znovu',
        contactEmail: 'Kontaktní email',
        contactPhone: 'Kontaktní telefon',
    },
    users: 'Uživatelé',

    status: 'Status',
    invitationStatus: {
        active: 'Aktivní',
        unactive: 'Neaktivní'
    },

    messages: {
        successfullySaved: 'Úspěšně uloženo',
        globalError: 'Něco se rozbilo, zkuste to prosím později.',
    },

    introMessage: {
        odecty: 'Vítáme Vás v aplikaci dálkových odečtů.',
    },

    languages: {
        cs: 'Česky',
        en: 'English',
        de: 'Deutsche',
        sk: 'Slovensky',
    },

    start: 'Start',
    end: 'Konec',

    days: 'Dny',
    daysShort: {
        mo: 'Po', 
        tu: 'Út', 
        we: 'St', 
        th: 'Čt', 
        fr: 'Pá', 
        su: 'So', 
        sa: 'Ne'
    },
    daysNames: {
        mo: 'Pondělí', 
        tu: 'Úterý', 
        we: 'Středa', 
        th: 'Čtvrtek', 
        fr: 'Pátek', 
        su: 'Sobota', 
        sa: 'Neděle'
    }
};
