import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../auth';
import { Access, StructureAccessId, StructureId } from './api-types';
import { getStructureAccesses, postInviteToStructure } from './structure';
import { postDisableAccess } from './structure/postDisableAccess';

const useStructureAccesses = (id: StructureId) => {
    const [structureAccesses, setStructureAccesses] = useState<Access[] | null>(null);

    const loadData = useCallback(async () => {
        if (id) {
            try {
                const result = await getStructureAccesses(id);

                setStructureAccesses(result.accesses);
            } catch (e) {}
        }
    }, [id]);

    const inviteToStructure = useCallback(
        async (values: any): Promise<boolean> => {
            if (id) {
                const result = await postInviteToStructure(id, values);
            }

            return true;
        },
        [, id],
    );

    const removeAccessById = useCallback(async (accessId: StructureAccessId): Promise<boolean> => {
        const result = await postDisableAccess(accessId);

        return true;
    }, []);

    useEffect(() => {
        loadData().then();
    }, [id]);

    return {
        refreshData: loadData,
        structureAccesses,
        inviteToStructure,
        removeAccessById,
    };
};

export default useStructureAccesses;
