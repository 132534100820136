import React, {createRef, useEffect, useState} from "react";
import {throttle} from "lodash";

export default function useVisibility<Element extends HTMLElement>(
    wrapper: React.RefObject<Element>|null = null,
    offset = 0,
    throttleMilliseconds = 100
): [Boolean, React.RefObject<Element>] {
    const [isVisible, setIsVisible] = useState(false);
    const currentElement = createRef<Element>();

    const onScroll = throttle(() => {
        if (!currentElement.current) {
            setIsVisible(false);
            return;
        }
        const top = currentElement.current.getBoundingClientRect().top;
        setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
    }, throttleMilliseconds);

    useEffect(() => {
        if(wrapper && wrapper.current){
            wrapper.current.addEventListener("scroll", onScroll);
            onScroll();
            return () => {
                if(wrapper && wrapper.current){
                    wrapper.current.removeEventListener("scroll", onScroll);
                }
            }
        }
    });

    return [isVisible, currentElement];
}
