import React, { FC } from 'react';
import { Button } from '../../components/Primitives/Button';
import { useHistory } from 'react-router';
import { BigTitle } from '../../modals/styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RightAndLeft } from '../../components/Primitives/aligment';
import { UsersFilterProvider } from '../../modules/users/containers/usersFilterContainer';
import UsersFilterList from '../../modules/users/components/UsersFilterList';

const PageWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px;
    @media (max-width: 1023px) {
        min-width: 300px;
    }
    @media (max-width: 479px) {
        padding: 24px 10px;
    }
`;

const ListUsersPage: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <UsersFilterProvider>
            <PageWrapper>
                <RightAndLeft>
                    <BigTitle>{t('global.users')}</BigTitle>
                    <Button variant="big" onClick={() => history.push('/users/new')}>
                        {t('user.newUser')}
                    </Button>
                </RightAndLeft>

                <UsersFilterList />
            </PageWrapper>
        </UsersFilterProvider>
    );
};

export default ListUsersPage;
