import React, {FC} from "react";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  border-top: 1px solid ${(props) => props.theme.colors.main};
`;

export const Box = styled.div`
  position: relative;
  min-height: 350px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  background: #ffffff;
  margin-bottom: 34px;
  padding: 32px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
  grid-gap: 60px 30px;
  @media (max-width: 1920px) {
    grid-template-columns: repeat(auto-fit, minmax(470px, 1fr));
  }
  @media (max-width: 1439px) {
    grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 479px) {
    padding: 24px 15px;
  }
`;

export const DeviceBox: FC = ({ children }) => {
    return (
        <Wrapper>
            <Box>
                {children}
            </Box>
        </Wrapper>
    )
}
