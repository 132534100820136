import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 200px;
    height: 68px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    background: #ffffff;
    padding: 12px 22px 12px 5px;
    margin-bottom: 22px;
    img {
        flex: 0 0 auto;
    }
`;
export const Wrap = styled.div`
    position: relative;
    margin-left: 6px;
    flex: 1 1 auto;
`;
export const Title = styled.h2`
    position: relative;
    margin: 0;
    color: #011736;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
`;
export const Text = styled.h3`
    position: relative;
    margin: 1px 0 0;
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
`;
