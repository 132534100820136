import axios from './../axios';
import settings from '../settings';

type ThermalDeviceInput = {
    device: number,
    setTemp: number,
    childLock: boolean,
}

const postThermalDeviceAction = async (input: ThermalDeviceInput): Promise<boolean> => {
    const response = await axios.post(`${settings.apiBasePath}/api/control-device/thermal`, input);

    return response.status === 200;
}

export {
    postThermalDeviceAction
}
