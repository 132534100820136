import React, { FC } from 'react';
import { Wrapper, Title, Row, Col, Link, Text, LinkBigger, Span, ColLeft } from './styles';
import { FlatStructure, Structure, StructureDetail } from '../../../modules/api/api-types';
import { Button } from '../../Primitives/Button';
import { useModals } from '../../../modals/useModals';
import { useTranslation } from 'react-i18next';
import useStructureName from '../../../helper/useStructureName';
import { Thumbnail } from '../../Thumbnail';
import styled from 'styled-components';

type HeaderProps = {
    structureDetail: StructureDetail;
};

const ButtonsWrapper = styled.div`
    position: relative;
    display: flex;
`;

export const Header: FC<HeaderProps> = ({ structureDetail }) => {
    const { t } = useTranslation();
    const { openStructureSettingsModal, openStructureAccessesModal, openExportFlatModal } = useModals();
    const { createTitle, haveSubtitle, placeSubTitle } = useStructureName();

    const editStructure = () => {
        openStructureSettingsModal(structureDetail.structure.id);
    };

    const editAccesses = () => {
        openStructureAccessesModal(structureDetail.structure.id);
    };

    const openExport = () => {
        openExportFlatModal(structureDetail.structure.id);
    };

    return (
        <Wrapper>
            <Col>
                <Row>
                    {/* <ColLeft> */}
                    {/* <Thumbnail /> */}
                    {/* </ColLeft> */}
                    <Col>
                        <Title>{createTitle(structureDetail.structure)}</Title>
                        <Text>
                            {haveSubtitle(structureDetail.structure) && placeSubTitle(structureDetail.structure)}
                        </Text>
                    </Col>
                </Row>
            </Col>
            <ButtonsWrapper>
                {structureDetail.canEditDeviceSettings && <Button onClick={editStructure}>{t('global.edit')}</Button>}
                &nbsp;
                {structureDetail.canEditAccesses && <Button onClick={editAccesses}>{t('global.accesses')}</Button>}
                <LinkBigger onClick={openExport}>
                    <img src="/img/icon-excel.png" alt="Excel" width="18" />
                    <Span>Export .xml</Span>
                </LinkBigger>
            </ButtonsWrapper>
        </Wrapper>
    );
};
