import React, { createRef, FC } from 'react';
import { Wrapper } from './styles';
import { FlatBox } from '../FlatBox';
import { DeviceBox } from '../UI/DeviceBox';
import { Box } from '../FlatBox/Box';
import { useStructuresContext } from '../../modules/api/structure/StructuresProvider';

export const Content: FC = () => {
    const wrapper = createRef<HTMLDivElement>();
    const { masterDevices, subStructure } = useStructuresContext();

    return (
        <Wrapper ref={wrapper}>
            {masterDevices.length > 0 && (
                <DeviceBox>
                    {masterDevices.map((item) => (
                        <Box key={`box_device_${item.id}`} device={item} scrollWrapper={wrapper} />
                    ))}
                </DeviceBox>
            )}
            {subStructure.map((item) => (
                <FlatBox key={`flatbox_${item.structure.id}`} structureDetails={item} wrapper={wrapper} />
            ))}
        </Wrapper>
    );
};
