import {DeviceId, GraphData, GraphType, Error} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type DeviceMeasurementsParams = {
    id: DeviceId
    dateFrom: Date,
    dateTo: Date,
    type?: GraphType
}

type DeviceMeasurementsResponse = {
    graphData: GraphData
    thisMonthConsumption: number;
    lastMonthConsumption: number;
    errors: Error[]
    enable: string[]
}

export async function getDeviceMeasurements(params: DeviceMeasurementsParams): Promise<DeviceMeasurementsResponse>{
    const response = await axios.post(`${settings.apiBasePath}/api/device/measurements/${params.id}`, {
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        type: params.type? params.type : 'consumption',
    });

    if(response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
