import {Device, StructureDetail, StructureId} from '../api-types';
import axios from './../axios';
import settings from '../settings';

type SubStructuresResponse = {
    structures: StructureDetail[];
    masterDevices: Device[]
};

export const getSubStructure = async (id: StructureId): Promise<SubStructuresResponse> => {
    const response = await axios.get(`${settings.apiBasePath}/api/structure/sub-structures/${id}`);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}

type SubStructureDetailResponse = {
    subStructure: StructureDetail[]
}

export const getSubstructureDetail = async (id: StructureId): Promise<SubStructureDetailResponse> => {
    const response = await axios.get(`${settings.apiBasePath}/api/structure/sub-structure-detail/${id}`);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}