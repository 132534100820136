import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  border-bottom: solid 1px #ececec;
  min-height: 86px;
`;
export const Col = styled.div`
  position: relative;
`;
export const Headline = styled.h1`
  color: #11181a;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;
export const Text = styled.p`
  color: #6a6a6a;
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
`;
