export default {
    newUser: 'Nový uživatel',
    editUser: 'Editace uživatele',

    userAdd: {
        success: 'Uživatel úspěšně vytvořen. Pokračujte přidáním přístupů.'
    },

    userEdit: {
        success: 'Uživatel úspěšně upraven.'
    }
};
