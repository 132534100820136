import styled from 'styled-components';
import { margin, MarginProps } from 'styled-system';
import React, { FC, ReactNode } from 'react';

const RowsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Col = styled.div<MarginProps>`
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    padding: 7px 4px;
    flex-basis: auto;
    width: 50%;
    ${margin}
`;

type TwoColumnsProps = {
    items: ReactNode[];
};

export const TwoColumns: FC<TwoColumnsProps> = ({ items }) => {
    return (
        <RowsWrapper>
            {items.map((item) => (
                <Col>{item}</Col>
            ))}
        </RowsWrapper>
    );
};
