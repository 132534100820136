import { Access, StructureId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type StructureAccessResponse = {
    accesses: Access[];
};

export async function getStructureAccesses(id: StructureId): Promise<StructureAccessResponse> {
    const response = await axios.get(`${settings.apiBasePath}/api/structure/accesses/${id}`);

    if (response.status === 200) {
        return {
            // @ts-ignore
            accesses: response.data.accesses.map((access) => ({
                ...access,
                start: access.start ? new Date(access.start) : null,
                end: access.end ? new Date(access.end) : null,
            })),
        };
    }

    throw new Error(response.data.message);
}
