import React, { Context, createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

export type BaseFilter = {
    fullText: string;
    page: number;
    limit: number;
};

type State<Filter extends BaseFilter> = {
    filter: Filter;
    setFilter: Dispatch<SetStateAction<Filter>>;
    reset: () => void;
    setFullText: (fullText: string) => void;
    nextPage: () => void;
    prevPage: () => void;
    goToPage: (page: number) => void;
    setLimit: (limit: number) => void;
};

const FilterContext = createContext<State<any>>({} as State<any>);

type useFilterContext = <Filter extends BaseFilter>() => State<Filter>;
export const useFilterContext: useFilterContext = () => useContext(FilterContext);

interface Props<T> {
    children: ReactNode;
    initValues: T;
}

export const FilterProvider = <Filter extends BaseFilter>({ children, initValues }: Props<Filter>) => {
    const [filter, setFilter] = useState(initValues);

    const value = {
        filter,
        setFilter,
        reset: () => {
            setFilter(initValues);
        },
        setFullText: (fullText: string) => {
            setFilter({
                ...filter,
                fullText,
            });
        },
        nextPage: () => {
            setFilter({
                ...filter,
                page: filter.page + 1,
            });
        },
        prevPage: () => {
            setFilter({
                ...filter,
                page: filter.page + 1,
            });
        },
        goToPage: (page: number) => {
            setFilter({
                ...filter,
                page,
            });
        },
        setLimit: (limit: number) => {
            setFilter({
                ...filter,
                limit,
            });
        },
    };

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
