export default {
    save: 'Uložiť',
    logout: 'Odhlásiť sa',
    loginForm: {
        fill: 'Vyplň svoj e-mail a heslo.',
        badCredentials: 'Nespravný e-mail alebo heslo.',
    },
    fromDate: 'Od',
    toDate: 'Do',

    add: 'Pridať',
    settings: 'Nastavenia',
    change: 'Zmeniť',
    close: 'Zavrieť',
    edit: 'Upraviť',
    accesses: 'Prístupy',
    removeAccess: 'Odstrániť prístup',
    unlimited: 'Neobmedzený',

    search: 'Vyhľadať',
    remove: 'Odstrániť',

    user: {
        firstName: 'Meno',
        lastName: 'Priezvisko',
        email: 'E-mail',
        emailOrUsername: 'E-mail/Užívateľské meno',
        currentPassword: 'Súčasné heslo',
        password: 'Heslo',
        passwordAgain: 'Heslo znovu',
        newPassword: 'Nové heslo',
        newPasswordAgain: 'Nové heslo znovu',
        contactEmail: 'Kontaktný email',
        contactPhone: 'Kontaktný telefón',
    },

    messages: {
        successfullySaved: 'Úspešne uložené',
        globalError: 'Niečo sa rozbilo, skúste to prosím neskôr.',
    },

    introMessage: {
        odecty: 'Vítame Vás v aplikácii diaľkových odpočtov.',
    },

    languages: {
        cs: 'Česky',
        en: 'English',
        de: 'Deutsche',
        sk: 'Slovensky',
    },

    daysShort: {
        mo: 'Po', 
        tu: 'Ut', 
        we: 'St', 
        th: 'Št', 
        fr: 'Pi', 
        su: 'So', 
        sa: 'Ne'
    },
    days: {
        mo: 'Pondelok', 
        tu: 'Utorok', 
        we: 'Streda', 
        th: 'Štvrtok', 
        fr: 'Piatok', 
        su: 'Sobota', 
        sa: 'Nedeľa'
    }
};
