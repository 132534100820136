import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
`;

export const Content = styled.div`
    background-color: #fefefe;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.25rem;
    border: 0.0625rem solid #888;
    width: 50rem;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
`;

export const RightContent = styled.div`
    background-color: #fefefe;
    position: fixed;
    right: 0;
    height: 100%;
    max-width: 25rem;
    width: 100%;
    padding: 1.25rem 1.25rem 0 1.25rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const CloseButton = styled.span`
    color: #aaa;
    float: right;
    font-size: 1.75rem;
    font-weight: bold;
    &:hover,
    &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
`;
