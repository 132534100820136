import axios from './../axios';
import settings from '../settings';
import { AccessToken, StructureAccessId } from '../api-types';

export async function postDisableAccess(id: StructureAccessId): Promise<any> {
    const response = await axios.post(`${settings.apiBasePath}/api/structure/disable-access/${id}`, {});

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
