import { UserListing, UsersFilter } from '../api-types';
import axios from './../axios';
import { CancelToken } from 'axios';
import settings from '../settings';

type GetFilterUsers = {
    filter: UserListing;
};

export async function filterUsers(filter: UsersFilter, cancelToken?: CancelToken): Promise<GetFilterUsers> {
    const response = await axios.get(settings.apiBasePath + '/api/user/filter-users', {
        params: filter,
        cancelToken,
    });

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
