import { exportBuildingData, exportDeviceData } from '../api/export';
import { DeviceId, StructureId } from '../api/api-types';
import { downloadFile } from '../../tools/downloadFileFromResponse';
import { exportFlatData } from '../api/export/exportFlatData';
import usePortal from '../../portal/usePortal';

export const useExportMeasurements = () => {
    const { currentLanguage } = usePortal();

    const exportDevice = async (deviceId: DeviceId, from: Date, to: Date) => {
        const data = await exportDeviceData({
            language: currentLanguage,
            device: deviceId,
            from: from, //new Date (new Date().getTime() - 30 * 24 * 3600 * 1000),
            to: to, //new Date(),
        });

        downloadFile('export.xlsx', data);
    };

    const exportBuilding = async (structureId: StructureId, from: Date, to: Date) => {
        const data = await exportBuildingData({
            language: currentLanguage,
            building: structureId,
            from,
            to,
        });

        downloadFile('export.xlsx', data);
    };

    const exportFlat = async (flat: StructureId, from: Date, to: Date) => {
        const data = await exportFlatData({
            language: currentLanguage,
            flat,
            from,
            to,
        });

        downloadFile('export.xlsx', data);
    };

    return {
        exportDevice,
        exportBuilding,
        exportFlat,
    };
};
