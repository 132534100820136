import React, { FC, useMemo } from 'react';
import SelectField from '../../../components/Primitives/SelectField';
import { useTranslation } from 'react-i18next';

type RoleSelectField = {
    label: string;
    name: string;
};

const RoleSelectField: FC<RoleSelectField> = ({ label, name }) => {
    const { t } = useTranslation();

    const choices = useMemo(() => {
        return [
            { value: '1', label: t('structure.access.roleLevel.1') },
            { value: '100', label: t('structure.access.roleLevel.100') },
        ];
    }, []);

    return <SelectField label={label} name={name} choices={choices} />;
};

export default RoleSelectField;
