import styled from "styled-components";

export const BigTitle = styled.h2`
`;

export const Title = styled.h3`
`;

export const Text = styled.p`
`
