import React, { FC } from 'react';
import { UsersFilter } from '../../api/api-types';
import { FilterProvider, useFilterContext } from '../../../helper/Filter';

const defaultFilter: UsersFilter = {
    fullText: '',
    page: 1,
    limit: 30,
};

export const UsersFilterProvider: FC = ({ children }) => {
    return <FilterProvider<UsersFilter> initValues={defaultFilter}>{children}</FilterProvider>;
};

export const useUserFilterContext = () => useFilterContext<UsersFilter>();
