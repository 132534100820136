import React, { FC, MouseEventHandler, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Wrapper, Content, CloseButton, RightContent } from './styles';
import { useModalsContext } from '../../modals/modals-context';
import { closeAllModalsAction } from '../../modals/modals-reducer';
import styled from 'styled-components';

type ModalProps = {
    title?: string;
    position?: 'centered' | 'right';
    enableCloseButton?: boolean;
    requestClose?: () => void;
};

const Header = styled.div`
    display: flex;
    justify-items: center;
    justify-content: space-between;
`;

export const Modal: FC<ModalProps> = ({
    title,
    position = 'centered',
    children,
    enableCloseButton = true,
    requestClose = () => {},
}) => {
    const wrapper = useRef<HTMLDivElement>(null);
    const { modalsDispatch } = useModalsContext();

    const close = () => {
        requestClose();
        modalsDispatch(closeAllModalsAction());
    };

    const closeByWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === wrapper.current) {
            close();
        }
    };

    return createPortal(
        <Wrapper ref={wrapper} onClick={(e) => closeByWrapper(e)}>
            {position === 'centered' ? (
                <Content>
                    {enableCloseButton && <CloseButton onClick={close}>&times;</CloseButton>}
                    {children}
                </Content>
            ) : (
                <RightContent>
                    <Header>
                        <h2>{title}</h2>
                        {enableCloseButton && <CloseButton onClick={close}>&times;</CloseButton>}
                    </Header>

                    {children}
                </RightContent>
            )}
        </Wrapper>,
        document.body,
    );
};
