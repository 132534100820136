import React, { FC, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Header } from './Header';
import { BuildingListItem, DeviceListItem, FlatListItem, List } from './List';
import { useStructures } from '../../modules/api/useStructures';
import { BuildingDetails, Device, FlatStructure, StructureDetail } from '../../modules/api/api-types';
import { Button } from '../Primitives/Button';
import { useModals } from '../../modals/useModals';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStructuresContext } from '../../modules/api/structure/StructuresProvider';

type Mode = 'devices' | 'buildings';

export const SideBar: FC = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const { changeSelectedUserBuilding, scrollToFlat } = useStructures();
    const { masterDevices, selectedUserBuilding, userBuildings, subStructure } = useStructuresContext();

    const [collapsed, setCollapsed] = useState(false);
    const [mode, setMode] = useState<Mode>('devices');

    const { openExportBuildingModal } = useModals();

    const openExportModal = () => {
        openExportBuildingModal();
    };

    useEffect(() => {
        setCollapsed(!collapsed);
    }, [location]);

    const change = (selectedDetails: BuildingDetails) => {
        changeSelectedUserBuilding(selectedDetails);
        setMode('devices');
        setCollapsed(true);
    };

    const scroll = (flat: FlatStructure) => {
        scrollToFlat(flat);
        setCollapsed(true);
    };

    return (
        <Wrapper collapsed={collapsed}>
            {selectedUserBuilding && (
                <Header
                    buildingStructure={selectedUserBuilding.structure}
                    enablesChange={userBuildings.length > 1}
                    buttonText={mode === 'devices' ? t('global.change') : t('global.close')}
                    changeClicked={() => setMode(mode === 'devices' ? 'buildings' : 'devices')}
                />
            )}
            {selectedUserBuilding && selectedUserBuilding.canExportAll && (
                <Button onClick={openExportModal}>{t('device.export.title')}</Button>
            )}
            {/*<Search/>*/}
            <List>
                {mode === 'devices' && (
                    <>
                        {masterDevices.length > 0 && <DeviceListItem masterDevices={masterDevices} />}
                        {subStructure.map((item) => (
                            <FlatListItem
                                key={`sub_structure_item_${item.structure.id}`}
                                flat={item.structure}
                                onClick={() => {
                                    scroll(item.structure);
                                }}
                            />
                        ))}
                    </>
                )}

                {mode === 'buildings' &&
                    selectedUserBuilding &&
                    userBuildings
                        .filter((item) => item.structure.id !== selectedUserBuilding.structure.id)
                        .map((item) => (
                            <BuildingListItem
                                key={`building_${item.structure.id}`}
                                building={item.structure}
                                onClick={() => change(item)}
                            />
                        ))}
            </List>
        </Wrapper>
    );
};
