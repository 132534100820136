import styled from 'styled-components';

type HaveError = {
    error?: boolean;
};

export const TempWrapper = styled.div`
    border: 0.0625rem solid #f0f0f0;
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
    padding: 1.5rem;
    margin-bottom: 1.875rem;
`;

export const TempShow = styled.span`
    font-size: 2.5rem;
    font-weight: 700;
`;

export const TempButton = styled.div`
    border: 0.125rem solid #f0f0f0;
    border-radius: 100vmax;
    width: 10.3125rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    cursor: pointer;
`;

export const InstructionReadWrapper = styled.div`
    border: 0.0625rem solid #f0f0f0;
    border-radius: 0.625rem;
    padding: 1.25rem;
`;

export const InstructionWrapper = styled.div`
    background-color: #f8f8f8;
    border-radius: 0.625rem;
`;

export const TempHeader = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
    line-height: calc(16 / 14);
    color: #8b8b8b;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 0.875rem;
`;

export const TempDropdownButton = styled.button`
    display: grid;
    place-items: center;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
`;
type TempDropdownBoxProps = {
    hidden: boolean;
};
export const TempDropdownBox = styled.div<TempDropdownBoxProps>`
    background: #ffffff;
    box-shadow: 0px 120px 48px rgba(0, 0, 0, 0.01), 0px 68px 41px rgba(0, 0, 0, 0.05), 0px 30px 30px rgba(0, 0, 0, 0.09),
        0px 8px 17px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    position: absolute;
    top: 100%;
    right: 0;
    display: grid;
    gap: 0.125rem;
    width: max-content;
    min-width: 15.4375rem;
    max-height: 20rem;
    overflow: auto;
    padding-block: 0.5rem;
    transition: all 300ms ease;
    pointer-events: ${(props) => (props.hidden ? 'none' : 'auto')};
    opacity: ${(props) => (props.hidden ? '0' : '1')};
    translate: ${(props) => (props.hidden ? '0 -.625rem' : '0 0')};
    z-index: 9;
`;
export const TempDropdownItem = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: calc(19 / 16);
    color: #121f23;
    cursor: pointer;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    text-align: left;
`;

export const TempContent = styled.div`
    flex: 1;
    padding-bottom: 1.5rem;
`;
export const TempNothing = styled.div`
    height: 100%;
    display: grid;
    place-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: calc(16 / 14);
    color: #8b8b8b;
`;

export const TempPlanBox = styled.div<HaveError>`
    background: #f8f8f8;
    border-radius: 0.5rem;
    ${(props) => (props.error ? 'border-color: rgb(214, 29, 29);' : null)};
    /* max-height: 19rem; */
`;

export const TempPlanItem = styled.div`
    padding: 1rem 1.3125rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: solid 1px #eaeaea;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }
`;
export const TempPlanItemButton = styled(TempPlanItem)<HaveError>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${(props) => (props.error ? 'background: #FFCCCC;' : null)};
`;

export const TempPlanText = styled.div`
    font-weight: 500;
    font-size: 1rem;
    line-height: calc(19 / 16);
    color: #121f23;
`;
export const TempPlanLabel = styled.div`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: calc(14 / 12);
    color: #8b8b8b;
    + ${TempPlanText} {
        margin-top: 0.3125rem;
    }
`;
export const TempPlanItemFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
`;

export const TempPlanHeader = styled.div`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: calc(16 / 14);
    color: #8b8b8b;
    padding: 1.1875rem 1.3125rem 0.375rem 1.3125rem;
`;

export const TempPlanFooter = styled.div`
    padding: 0.25rem 1.3125rem 0.375rem;
`;
export const TempPlanFooterButton = styled.div`
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;
    display: grid;
    place-items: center;
    border-radius: 0.5rem;
    min-height: 2.25rem;
    padding: 0;
`;
export const TempPlanFooterButtonAccept = styled(TempPlanFooterButton)`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: calc(16 / 14);
    color: #000000;
    border: 0.0625rem solid #e6e6e6;
`;
export const TempPlanFooterButtonReject = styled(TempPlanFooterButton)`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: calc(14 / 12);
    color: #d61d1d;
    padding: 1rem;
`;

export const TempPlanSwitchContent = styled.div`
    display: grid;
    gap: 1rem;
`;

export const TempPlanSwitchBox = styled.div<HaveError>`
    border: 0.0625rem solid #e0e0e0;
    border-radius: 0.5rem;
    ${(props) => (props.error ? 'border-color: rgb(214, 29, 29)' : null)};
    background: ${(props) => (props.error ? '#FFCCCC' : '#ffffff')};
`;
export const TempPlanSwitchHeader = styled.div`
    padding: 1rem 1.3125rem 0 1.3125rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const HeadlineWrap = styled.div`
    display: flex;

`

export const SmallButton = styled.button`
    height: 15px;
    width: 15px;
    border: none;
    background: none;
    padding: 0;
    margin-left: 10px;
`
