import {AuthTokens} from "../../api/api-types";

export const saveTokensLocally = (tokens: AuthTokens): boolean => {
    try {
        window.localStorage.setItem('AUTH', JSON.stringify(tokens));

        return true;
    } catch (e) {
        return false;
    }
}

export const clearTokens = (): void => {
    try {
        window.localStorage.removeItem('AUTH');
    } catch (e) {
        
    }
}

export const getTokens = (): AuthTokens | null => {
    const tokens = window.localStorage.getItem('AUTH');

    if (tokens) {
        return JSON.parse(tokens) as AuthTokens;
    }

    return null;
}
