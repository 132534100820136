import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { ThemeMode } from "../theme/theme";
import { Languages } from "./../translations";
import i18n from "i18next";
import { useCookies } from "react-cookie";
import {changeLanguageInterceptor} from "../modules/api/interceptors/languageInterceptor";

type PortalSettings = {
  style: ThemeMode;
  title: string;
  defaultLanguage: Languages;
  currentLanguage: Languages;
  introMessage?: string;
};

const solveContext = (hostName: String): PortalSettings => {
  if ("renova.goliash.cz" === hostName) {
    return {
      style: "renova",
      title: "Renova",
      defaultLanguage: "cs",
      currentLanguage: "cs",
    };
  } else if ("odectyvodomeru.cz" === hostName) {
    return {
      style: "renova",
      title: "Renova",
      defaultLanguage: "cs",
      introMessage: "global.introMessage.odecty",
      currentLanguage: "cs",
    };
  } else if ("pragostav.goliash.cz" === hostName) {
    return {
      style: "pragostav",
      title: "Pragostav DEMO",
      defaultLanguage: "cs",
      currentLanguage: "cs",
    };
  } else if ("vodomeryonline.sk" === hostName) {
    return {
      style: "vodomeryonline",
      title: "Vodomery online",
      introMessage: "global.introMessage.odecty",
      defaultLanguage: "sk",
      currentLanguage: "sk",
    };
  } else if ("fps.goliash.cz" === hostName) {
    return {
      style: "fps",
      title: "FPS Demo",
      defaultLanguage: "cs",
      currentLanguage: "cs",
    };
  } else {
    return {
      style: "goliash",
      title: "Goliash",
      defaultLanguage: "cs",
      currentLanguage: "cs",
    };
  }
};

const portalSettings: PortalSettings = solveContext(
  window.location.hostname.replace("www.", "")
);

export const PortalContext = createContext<PortalSettings>(portalSettings);

const usePortal = () => {
  const [cookies, setCookie] = useCookies(["lang"]);

  const state = useContext(PortalContext);

  return {
    ...state,

    changeLanguage: async (language: Languages) => {
      portalSettings.currentLanguage = language;
      setCookie("lang", language, { maxAge: 31556952 });
      await i18n.changeLanguage(language);
      changeLanguageInterceptor(cookies["lang"]);
    },
  };
};

export default usePortal;

export const PortalProvider: FC = ({ children }) => {
  const [cookies, setCookie] = useCookies(["lang"]);

  useEffect(() => {
    if (cookies["lang"]) {
      portalSettings.currentLanguage = cookies["lang"];
      i18n.changeLanguage(portalSettings.currentLanguage).then();
      changeLanguageInterceptor(cookies["lang"]);
    }else{
      i18n.changeLanguage(portalSettings.defaultLanguage).then();
      changeLanguageInterceptor(portalSettings.defaultLanguage);
    }
  }, []);

  return (
    <PortalContext.Provider value={portalSettings}>
      {children}
    </PortalContext.Provider>
  );
};
