import React, {FC} from "react";
import {AppLayout, MiddleContent} from "../../../components/Layout/AppLayout";
import {Logo} from "../../../components/Primitives/Logo";
import {ShadowedBox} from "../../../components/Primitives/Box";
import SetNewPasswordForm from "../../../components/Forms/SetNewPasswordForm";
import {useRouteMatch} from "react-router";

type Props = {
    token: string
}

export const SetNewPasswordPage: FC = () => {
    const { params } = useRouteMatch<Props>();
    return (
        <AppLayout>
            <MiddleContent>
                <Logo/>
                <ShadowedBox>
                    <SetNewPasswordForm token={params.token} />
                </ShadowedBox>
            </MiddleContent>
        </AppLayout>
    )
}
