import React, {FC} from 'react';
import {Wrapper, Row, Col, Headline, Text} from './styles';
import {Button} from "../../Primitives/Button";
import {BuildingStructure} from "../../../modules/api/api-types";

type HeaderProps = {
    buildingStructure: BuildingStructure,
    enablesChange: boolean,
    buttonText: string
    changeClicked: () => void
}

export const Header: FC<HeaderProps> = ({enablesChange, buildingStructure, changeClicked, buttonText}) => {


    return (
        <Wrapper>
            <Row>
                <Col>
                    <Headline>{buildingStructure.address.addressLine}</Headline>
                    <Text>{buildingStructure.address.cityLine}</Text>
                </Col>

                {enablesChange && (
                    <Col>
                        <Button onClick={changeClicked}>{buttonText}</Button>
                    </Col>
                )}
            </Row>
        </Wrapper>
    );
};
