export default {
  invitation: {
    successfullyAccepted: "Pozvánka úspešne prijatá.",
  },
  login: {
    login: "Prihlásiť sa",
  },
  editProfile: {
    title: "Užívateľský profil",
    editProfile: "Upraviť",
    editPassword: "Zmeniť heslo",
    changePassword: "",
    successfullySaved: "Profil úspešne uložený.",

    cantBeEdited: "Tento profil nemôže byť upravovaný",

    validation: {
      fillFirstName: "Meno nesmie zostať prázdne.",
      fillLastName: "Priezvisko nesmie zostať prázdne.",

      fillPassword:
        "Vyplň nové heslo pokiaľ má byť zmenené. V opačnom prípade nie je nutné vypĺňať súčasné heslo.",
      fillPasswordAgain: "Vyplň heslo znova, Musí byť rovnaké ako nové heslo.",
      passwordRequirements: "Nové heslo musí mať aspoň 5 znakov.",
    },
  },
};
