import React, {FC} from "react";
import {Modal} from "../components/Modal";
import {BigTitle, Text} from "./styles";
import {useModalArguments } from "./useModals";
import {useTranslation} from "react-i18next";

type Arguments = {
    error: string;
}

const ErrorInfoModal: FC = () => {
    const { t } = useTranslation();
    const { error } = useModalArguments<Arguments>();

    return (
        <Modal>
            <BigTitle>{t(`device.errors.${error}`)}</BigTitle>
            <Text>{t(`device.errorDescriptions.${error}`)}</Text>
        </Modal>
    )
}

export default ErrorInfoModal;
