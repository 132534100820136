import styled from "styled-components";
import {variant, margin, MarginProps} from 'styled-system';
import {MeasurementType} from "../../modules/api/api-types";

type Pillvariants = {
    variant?: MeasurementType
}
export const Pill = styled.div<MarginProps | Pillvariants>`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: #0179d4;
  border: 1px solid #fff;
  margin: 0 6px 0 0;
  ${margin}
    
  ${(props) => variant(
      {
    variants: {
        ...props.theme.measurementTypes
    }
})}
`;

Pill.defaultProps = {
    variant: "cold"
}
