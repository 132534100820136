import React, {FC} from "react";
import styled, {useTheme} from "styled-components";
import {AppTheme} from "../../../theme/theme";

const Img = styled.img`
  display: block;
  margin: 0 auto 50px;
  mix-blend-mode: multiply;
  position: relative;
`

export const Logo: FC = () => {
    const theme = useTheme() as AppTheme;

    return (
        <Img width={220} src={theme.logos.fullLogo.img} alt={theme.logos.fullLogo.alt}/>
    )
}
