import global from './global';
import device from './device';
import profile from './profile';
import structure from './structure';
import user from './user';
import thermal from './thermal';

export default {
    translation: {
        global,
        device,
        profile,
        structure,
        user,
        thermal
    },
};
