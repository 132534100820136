import styled, {css} from 'styled-components';

type Collapsible = {
    collapsed: boolean;
}

export const Wrapper = styled.div<Collapsible>`
  height: 100vh;
  background: #fcfbfa;
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.16);
  overflow: auto;
  transition: all 300ms ease;

  ${props => props.collapsed && css`
    @media (max-width: 1023px) {
      
      margin-left: -300px;
    }
  `}
  ${props => !props.collapsed && css`
      flex: 0 0 calc(300px - 56px);
  `}
`;
