import React, {FC, useCallback} from "react";
import {Form} from "react-final-form";
import {Link} from "react-router-dom";
import {Button} from "../../Primitives/Button";
import {RowSpaceBetween} from "../../Primitives/Row";
import InputField from "../../Primitives/InputField";

type SetNewPasswordProps = {
    token: string
}

type FormValues = {
    newPassword: string;
    newPasswordAgain: string;
}

const SetNewPasswordForm: FC<SetNewPasswordProps> = ({ token }) => {

    const handleSubmit = useCallback(async (values: FormValues) => {
        // const response =
    }, []);

    return (
        <>
            <Form<FormValues>
                initialValues={{ newPassword: '', newPasswordAgain: ''}}
                onSubmit={handleSubmit}>
                {(props) => (
                    <form onSubmit={props.handleSubmit}>

                        <InputField name='newPassword' label='global.user.newPassword' type='password'/>
                        <InputField name='newPasswordAgain' label='global.user.newPasswordAgain' type='password'/>

                        <RowSpaceBetween variant="alignCenter">
                            <Link to="/login">
                                <Button variant='link'>Zpět na přihlášení</Button>
                            </Link>
                            <Button disabled={props.submitting} variant='big'>Odeslat</Button>
                        </RowSpaceBetween>
                    </form>
                )}
            </Form>
        </>
    )
}

export default SetNewPasswordForm;
