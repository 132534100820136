import React, { FC } from "react";
import {Redirect} from "react-router";
import {useAuth} from "../modules/auth";
import {useStructures} from "../modules/api/useStructures";
import {useProfile} from "../modules/api/useProfile";

const LogoutPage: FC = () => {

    const { logoutAction } = useAuth();
    const { clearStructures } = useStructures();
    const { clearProfile } = useProfile();

    logoutAction();
    clearStructures();
    clearProfile();

    return (
        <Redirect to='/login' />
    )
}

export default LogoutPage;
