import React, { FC, useCallback } from 'react';
import { Button } from '../../Primitives/Button';
import { Form } from 'react-final-form';
import InputField from '../../Primitives/InputField';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { acceptInvitation } from '../../../modules/api/user';
import { showFlashMessage } from '../../FlashMessages';

type Params = {
    token: string;
};

type InvitationValues = {
    firstName: string;
    lastName: string;
    password: string;
    passwordAgain: string;
};

export const ReceiveInviteForm: FC = () => {
    const { t } = useTranslation();
    const { token } = useParams<Params>();
    const history = useHistory();

    const handleSubmit = useCallback(
        async (values: InvitationValues) => {
            if(values.password !== values.passwordAgain) {
                showFlashMessage('Hesla se musí shodovat', 'error');
                return;
            }

            if(values.password.length < 5) {
                showFlashMessage('Hesla se musí obsahovat alespoň 5 znaků', 'error');
                return;
            }

            try {
                const response = await acceptInvitation({ ...values, token });

                if (response) {
                    showFlashMessage(t('profile.invitation.successfullyAccepted'));
                    history.push('/login');
                }
            } catch (e) {
                showFlashMessage('Pozvánka je již neplatná. Pokud jste tuto stránku navštívili v minulosti, zkuste se prosím přihlásit s údaji, které byly zadány. ', 'error');
            }
        },
        [token, history, t],
    );

    return (
        <Form<InvitationValues> initialValues={{}} onSubmit={handleSubmit}>
            {(props) => (
                <form onSubmit={props.handleSubmit}>
                    <InputField name="firstName" label="global.user.firstName" type="text" />
                    <InputField name="lastName" label="global.user.lastName" type="text" />

                    <InputField name="password" label="global.user.password" type="password" />
                    <InputField name="passwordAgain" label="global.user.passwordAgain" type="password" />

                    <Button disabled={props.submitting}>{t('global.save')}</Button>
                </form>
            )}
        </Form>
    );
};

export default ReceiveInviteForm;
