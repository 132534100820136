import { SchemaOf } from "yup";
import { setIn } from "final-form";

// @ts-ignore
export const validateFormValues = (schema: SchemaOf) => async (values) => {
  if (typeof schema === "function") {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    // @ts-ignore
    return err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
  }
};
