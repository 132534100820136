import axios from './../axios';
import settings from '../settings';

export const assignProfileToDevice = async (profileId: number, deviceId: number): Promise<boolean> => {
    const response = await axios.post(
        `${settings.apiBasePath}/api/thermal-schedule/profile/${profileId}/assign/${deviceId}`,
    );

    return response.status === 200;
};

export const unassignProfileToDevice = async (profileId: number, deviceId: number): Promise<boolean> => {
    const response = await axios.post(
        `${settings.apiBasePath}/api/thermal-schedule/profile/${profileId}/unassign/${deviceId}`,
    );

    return response.status === 200;
};

type ThermalProfileInstructionInput = {
    id: number;
    temp: number;
    start: string;
    days: string[];
    active: boolean;
};

type ThermalProfileInput = {
    id: number;
    name: string;
    deviceId: null | number;
    instructions: ThermalProfileInstructionInput[];
};

export const editThermalProfile = async (input: ThermalProfileInput) => {
    const response = await axios.post(`${settings.apiBasePath}/api/thermal-schedule/profile`, input);

    return response.status === 200;
};

export const removeThermalProfile = async (profileId: number) => {
    const response = await axios.post(`${settings.apiBasePath}/api/thermal-schedule/profile/remove`, {
        id: profileId,
    });

    return response.status === 200;
};

export const getProfileById = async (profileId: number) => {
    const response = await axios.get(`${settings.apiBasePath}/api/thermal-schedule/profile/${profileId}`);

    if(response.status === 200) {
        return response.data.profile;
    }

    throw new Error('Cant load profile');
};
