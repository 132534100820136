import styled from 'styled-components';
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 0 0 56px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  overflow: auto;
  z-index: 2;
`;

export const Menu = styled.div`
  margin: 5px 0;
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 40px;
  margin: 13px 0;
  color: #6a6a6a;
  text-decoration: none;
  background: transparent;
  box-shadow: none;
  transition: all 300ms ease;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: ${props => props.theme.colors.main};
  }
  &:active,
  &.active {
    color: ${props => props.theme.colors.main};
    &:before {
      opacity: 1;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    box-shadow: 0 4px 5px ${props => props.theme.colors.shadowLighter};
    border-radius: 2px;
    background: ${props => props.theme.colors.main};
    transition: all 300ms ease;
    opacity: 0;
  }
`;


