import {AccessToken, LoggedUser} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type GetUserResponse = {
    user: LoggedUser
}

export async function getUser(accessToken: AccessToken): Promise<GetUserResponse> {
    const response = await axios.get(settings.apiBasePath + '/api/user/profile', {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
