import React from 'react';
import { Wrapper, Title, Text } from './styles';

type InfoProps = {
  title: string,
  text: string
}

export function Info(props: InfoProps) {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <Text>{props.text}</Text>
    </Wrapper>
  );
};
