import styled from 'styled-components';
import React, { FC } from 'react';
import { variant } from 'styled-system';

export const RightAndLeft = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PullRight = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

type ColProps = {
    size: number;
};

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    position: relative;
`;
const Col = styled.div<ColProps>`
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 100%;
    padding: 7px 4px;
    ${() =>
        variant({
            prop: 'size',
            variants: {
                2: {
                    width: '50%',
                },
                3: {
                    width: 'calc(100% / 3)',
                },
                4: {
                    width: '25%',
                    padding: '8px',
                },
                5: {
                    width: '20%',
                    padding: '10px',
                },
            },
        })}
`;

type AutoColumnsProps = {};

const AutoColumns: FC<AutoColumnsProps> = ({ children }) => {
    const childs = React.Children.toArray(children);

    return (
        <Row>
            {childs.map((child) => (
                <Col size={childs.length}>{child}</Col>
            ))}
        </Row>
    );
};

export { AutoColumns };
