import React, {FC, useCallback} from 'react';
import {Modal} from "../components/Modal";
import {BigTitle} from "./styles";
import {useTranslation} from "react-i18next";
import { Form } from 'react-final-form';
import InputField from "../components/Primitives/InputField";
import {Button} from "../components/Primitives/Button";
import {StructureId} from "../modules/api/api-types";
import useStructureDetails, { UpdateStructureDetailValues } from "../modules/api/useStructureDetails";
import {useModalArguments, useModals} from "./useModals";
import {showFlashMessage} from "../components/FlashMessages";

type Arguments = {
    id: StructureId;
}

const StructureSettingsModal: FC = () => {
    const { t } = useTranslation();
    const { id } = useModalArguments<Arguments>();
    const { closeAllModals } = useModals();

    const { structureDetails, updateStructureDetails } = useStructureDetails(id);

    const saveStructureSettings = useCallback(async (values: UpdateStructureDetailValues) => {
        const result = await updateStructureDetails(values);

        if(result){
            showFlashMessage(t('global.messages.successfullySaved'), "success");
            closeAllModals();
        }
    }, [updateStructureDetails]);

    return (
        <Modal>
            <BigTitle>{t('global.settings')}</BigTitle>

            <Form<UpdateStructureDetailValues>
                initialValues={{
                    customName: structureDetails?.customName || '',
                    description: structureDetails?.description || '',
                }}
                onSubmit={saveStructureSettings}>
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <InputField name='customName' label='structure.customName' type='text' />
                        <InputField name='description' label='structure.description' type='text' />

                        <Button disabled={props.submitting}>{t('global.save')}</Button>
                    </form>
                )}
            </Form>
        </Modal>
    )
}

export default StructureSettingsModal;
