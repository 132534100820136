import { createGlobalStyle } from 'styled-components';

import MainRegular from './Roboto-Regular.woff';
import MainRegular2 from './Roboto-Regular.woff2';
import MainMedium from './Roboto-Medium.woff';
import MainMedium2 from './Roboto-Medium.woff2';
import MainBold from './Roboto-Bold.woff';
import MainBold2 from './Roboto-Bold.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${MainRegular2}) format('woff2'),
        url(${MainRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${MainMedium2}) format('woff2'),
        url(${MainMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${MainBold2}) format('woff2'),
        url(${MainBold}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
`;
