import React, {FC, useEffect} from "react";
import {Route, RouteProps, useHistory} from "react-router";
import {useAuth} from "../useAuth";

export const PrivateRoute: FC<RouteProps> = ({component, ...rest}) => {

    const history = useHistory();
    const { authInProgress, enablePrivateRoutes } = useAuth()

    useEffect(() => {
        if(!authInProgress && !enablePrivateRoutes){
            history.replace('/login');
        }
    }, [authInProgress, enablePrivateRoutes])

    return enablePrivateRoutes? <Route {...rest} component={component} /> : null;
};
