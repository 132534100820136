import React, {FC, useEffect, useState} from 'react';
import SelectField, {Choice} from "../../../components/Primitives/SelectField";
import {useFormState} from "react-final-form";
import useAdminStructures from "../../api/useAdminStructures";
import useStructureName from "../../../helper/useStructureName";
import {FlatStructure} from "../../api/api-types";
import {useTranslation} from "react-i18next";

type AreaSelectField= {
    label: string
    name: string
    buildingSelectName: string
}

const AreaSelectField: FC<AreaSelectField> = ({
    label, name, buildingSelectName
                                              }) => {
    const { t } = useTranslation();
    const { values } = useFormState();
    const [choices, setChoices] = useState<Choice[]>([]);
    const { loadSubStructures } = useAdminStructures();
    const { fullTitle } = useStructureName();

    const structureField = values[buildingSelectName];

    useEffect(() => {
        setChoices([]);
        if(structureField) {
            (async () => {
                const result = await loadSubStructures(structureField);

                if(result) {
                    setChoices([
                        {
                            label: t('structure.access.all'),
                            value: ''
                        },
                        ...result.map((structure: FlatStructure) => ({
                            label: fullTitle(structure),
                            value: structure.id
                        }))
                    ]);
                }
            })().then();
        }
    }, [structureField])

    return (
        <SelectField label={label} name={name} choices={choices} />
    )
}

export default AreaSelectField;