import { useAuth } from "../auth";
import { useCallback, useEffect, useState } from "react";
import { getShowUser } from "./user/getShowUser";
import { Access, EditUser, User } from "./api-types";
import { postEditUser } from "./user/postEditUser";

const defaultUserData: User = {
  id: 0,
  email: "",
  firstName: "",
  lastName: "",
  contactEmail: null,
  contactPhone: null,
};

const useAdminUser = (id: number | null) => {
  const [user, setUserData] = useState<User>(defaultUserData);
  const [accesses, setAccesses] = useState<Access[]>([]);

  const loadUser = useCallback(async () => {
    if (id) {
      const response = await getShowUser( id);

      if (response) {
        setUserData(response.user);
        setAccesses(response.accesses);
      }
    } else {
      setUserData(defaultUserData);
    }
  }, []);

  const saveUser = useCallback(async (id: number | null, data: EditUser) => {

      const response = await postEditUser( id, data);

      console.log(response);

      return response;

  }, []);

  useEffect(() => {
    loadUser().then();
  }, [id]);

  return {
    user,
    accesses,
    saveUser,
  };
};

export default useAdminUser;
