import {Reducer} from "react";
import {FetchState, LoggedUser} from "./api-types";
import {initState} from "./profile-context";
import {BaseAction, createAction} from "../../tools/store";

const CLEAR = "clear";
const SET_LOGGED_USER = "loggedUser";
const SET_PROFILE_STATE = "profileState";

export type ProfileState = {
    state: FetchState
    user: LoggedUser|null
}

export type ProfileAction =
    | BaseAction<typeof CLEAR>
    | BaseAction<typeof SET_PROFILE_STATE>
    | BaseAction<typeof SET_LOGGED_USER, LoggedUser|null>
;

const ProfileReducer: Reducer<ProfileState, ProfileAction> = (state, action) => {
    switch(action.type) {
        case CLEAR:
            return {
                ...state,
                ...initState
            }
        case SET_LOGGED_USER:
            return {
                ...state,
                state: 'loaded',
                user: action.payload as LoggedUser
            }
        case SET_PROFILE_STATE:
            return {
                ...state,
                state: action.payload as FetchState
            }
        default:
            return state;
    }
}

export const clear = (): ProfileAction => createAction(CLEAR);

export const profileIsLoading = (): ProfileAction => createAction(SET_PROFILE_STATE, 'loading');
export const profileIsLoaded = (): ProfileAction => createAction(SET_PROFILE_STATE, 'loaded');

export const setLoggedUser = (user: LoggedUser): ProfileAction => createAction(SET_LOGGED_USER, user);
export const removeUser = (): ProfileAction => createAction(SET_LOGGED_USER, null);

export default ProfileReducer;
