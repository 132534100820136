import React, {ChangeEvent, DOMAttributes, FC, InputHTMLAttributes, useCallback} from "react";
import {useField} from "react-final-form";
import {FieldValidator} from "final-form";
import {SpaceProps} from "styled-system";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Container = styled.div`
  position: relative;
  margin: 0 0 16px;
`

const Label = styled.label<{error: boolean}>`
    display: block;
    margin: 0 0 4px;
    color: ${(props) => props.error? 'red' : '#707070'};
    font-size: .75rem;
    font-weight: 400;
    transition: all .3s ease;
`

const Input = styled.input<{error: boolean}>`
    display: flex;
    min-height: 40px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    border-top: 2px solid transparent;
    border-bottom: ${(props) => props.error ? '2px solid red': '2px solid transparent'};
    box-shadow: 0 0.063rem 0.188rem rgba(0,0,0,.2);
    border-radius: .25rem;
    background: #fff;
    color: #000;
    font-size: .875rem;
    font-weight: 400;
    outline: none;
    transition: all .3s ease;
    
    &:focus {
      box-shadow: 0 0 0 1px ${props => props.theme.colors.main};
    }
    &:disabled {
        background: #f0f0f0;
    }
`

const Error = styled.span`
  color: red;
`

type InputFieldProps = {
    label: string;
    name: string;
    max?: number|string;
    pattern?: string;
    validate?: FieldValidator<string>;
} & SpaceProps &
    InputHTMLAttributes<HTMLInputElement> &
    Pick<DOMAttributes<HTMLInputElement>, 'onBlur' | 'onFocus' | 'onMouseDown'>;

const InputField: FC<InputFieldProps> = ({
    label,
    placeholder,
    name,
    type = 'text',
    max,
    onChange,
    ...rest
}) => {
    const { t } = useTranslation();
    const { input, meta } = useField(name, { type: 'input', validate: rest.validate });

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;
            if (max !== undefined && typeof max == 'number') {
                value = value.slice(0, max);
            }

            if (rest.pattern) {
                const regExp = new RegExp(rest.pattern);
                if (!regExp.test(value)) {
                    value = value.slice(0, value.length - 1);
                }
            }

            event.target.value = value;

            input.onChange(event);
            if (onChange) {
                onChange(event);
            }
        },[onChange,rest.pattern, input]
    )

    return (
        <Container>
            <Label error={meta.touched && meta.error}>{t(label)}</Label>
            <Input
                {...input}
                type={type}
                error={meta.touched && meta.error}
                placeholder={placeholder}
                {...rest}
                onChange={handleChange}
            />
            {meta.touched && meta.error ? <Error>{t(meta.error)}</Error> : null}
        </Container>
    );
}

export default InputField;
