import {Reducer} from "react";
import {BuildingDetails, BuildingStructure, FetchState, FlatStructure, Structure, StructureId} from "./api-types";
import {initState} from "./structure-context.";
import {BaseAction, createAction} from "../../tools/store";

const CLEAR = 'clear';
const SET_STATE_USER_BUILDINGS = 'setStateUserBuildings';
const SET_USER_BUILDINGS = "setUserBuildings";
const SET_BUILDING_STRUCTURE = 'setBuildingStructure';
const SCROLL_TO_STRUCTURE = 'scrollToStructure';
const UPDATE_USER_BUILDING = 'updateUserBuilding';
const SET_SUB_STRUCTURE = 'setSubStructure'

export type StructureState = {
    stateUserBuildings: FetchState
    userBuildings: BuildingDetails[],
    selectedUserBuilding: BuildingDetails|null,
    subStructures: {[key: string] : FlatStructure[]},
    scrollToStructure: FlatStructure|null
}

export type StructureAction =
    | BaseAction<typeof CLEAR>
    | BaseAction<typeof SET_STATE_USER_BUILDINGS>
    | BaseAction<typeof SET_USER_BUILDINGS, BuildingDetails[]>
    | BaseAction<typeof SET_BUILDING_STRUCTURE, BuildingDetails>
    | BaseAction<typeof SCROLL_TO_STRUCTURE, FlatStructure>
    | BaseAction<typeof UPDATE_USER_BUILDING, BuildingStructure>
    | BaseAction<typeof SET_SUB_STRUCTURE, { id: StructureId, structures: FlatStructure[] }>
;

const StructureReducer: Reducer<StructureState, StructureAction> = (state, action) => {
    switch(action.type) {
        case CLEAR: {
            return {
                ...state,
                ...initState
            }
        }
        case SET_STATE_USER_BUILDINGS:
            return {
                ...state,
                stateUserBuildings: action.payload as FetchState
            }
        case SET_USER_BUILDINGS:
            return {
                ...state,
                userBuildings: action.payload as BuildingDetails[]
            }
        case SET_BUILDING_STRUCTURE:
            return {
                ...state,
                selectedUserBuilding: action.payload as BuildingDetails
            }
        case SCROLL_TO_STRUCTURE:
            return {
                ...state,
                scrollToStructure: action.payload as FlatStructure
            }
        case UPDATE_USER_BUILDING:
            const payload = action.payload as BuildingStructure
            const userBuildings = state.userBuildings;
            const toReplace = userBuildings.find(building => building.structure.id === payload.id);

            if(toReplace){
                userBuildings.splice(userBuildings.indexOf(toReplace), 1, {...toReplace, structure: {...toReplace.structure, ...payload}}) // ,{...toReplace, ...payload}
            }

            let selectedUserBuilding = state.selectedUserBuilding;
            if(selectedUserBuilding && selectedUserBuilding.structure.id === payload.id){
                selectedUserBuilding = userBuildings.find(structure => state.selectedUserBuilding && structure.structure.id === state.selectedUserBuilding.structure.id) || null;
            }

            return {
                ...state,
                userBuildings,
                selectedUserBuilding
            }
        case SET_SUB_STRUCTURE:
            const subStructures = state.subStructures;
            if(action.payload){
                subStructures[action.payload.id] = action.payload.structures;
            }
            return {
                ...state,
                subStructures
            }
        default:
            return state;
    }
}

export const clearState = (): StructureAction => createAction(CLEAR);

export const setUserBuildingLoading = (): StructureAction => createAction(SET_STATE_USER_BUILDINGS, 'loading');
export const setUserBuildingLoaded = (): StructureAction => createAction(SET_STATE_USER_BUILDINGS, 'loaded');

export const setUserBuildings = (buildings: BuildingDetails[]): StructureAction => createAction(SET_USER_BUILDINGS, buildings);
export const selectedUserBuilding = (buildings: BuildingDetails): StructureAction => createAction(SET_BUILDING_STRUCTURE, buildings);

export const scrollToBuilding = (flat: FlatStructure): StructureAction => createAction(SCROLL_TO_STRUCTURE, flat);

export const updateBuilding = (building: BuildingStructure): StructureAction => createAction(UPDATE_USER_BUILDING, building);

export const setSubStructure = (id: StructureId, structures: FlatStructure[]): StructureAction => createAction(SET_SUB_STRUCTURE, {id,structures})

export default StructureReducer;
