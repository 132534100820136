import axios from "./../axios";
import {AccessToken} from "../api-types";

type AuthInterceptorId = number;

const registerAuthInterceptor = (accessToken: AccessToken): AuthInterceptorId => {
    return axios.interceptors.request.use((config) => {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`
        }

        return config;
    })
}

const unregisterAuthInterceptor = (id: AuthInterceptorId): void => {
    axios.interceptors.request.eject(id);
}

export {
    registerAuthInterceptor,
    unregisterAuthInterceptor
}
