import { AccessToken, BuildingsListing, Filter, UserListing } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type GetFilterBuildings = {
    filter: BuildingsListing;
};

export async function filterBuildings(filter: Filter): Promise<GetFilterBuildings> {
    const response = await axios.get(settings.apiBasePath + '/api/structure/filter-buildings', {
        params: filter,
    });

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
