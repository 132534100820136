import { useEffect } from 'react';

const isDev = process.env.NODE_ENV === 'development';

const configure = (container: HTMLElement | null) => {
    if (isDev) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const contextDevtool = require('react-context-devtool');
        contextDevtool.debugContextDevtool(container, {
            debugReducer: true,
            debugContext: true,
            disable: true,
        });
    }
};

function useReactContextDevTool<T>(name: string, values: T): T {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (isDev && window._REACT_CONTEXT_DEVTOOL) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window._REACT_CONTEXT_DEVTOOL({ id: name.toLowerCase(), displayName: name, values });
        }
    }, [values, name]);

    return values;
}

export { configure, useReactContextDevTool };
