import React from 'react';
import { Wrapper, Title, Text, Wrap } from './styles';

type MeterBoxProps = {
  title: string,
  text: string,
  imgUrl?: string,
}

MeterBox.defaultProps = {
  imgUrl: '/img/meter-thumb.png'
}

export function MeterBox(props: MeterBoxProps) {
  return (
    <Wrapper>
      <img src={props.imgUrl} alt=""/>
      <Wrap>
        <Title>{props.title}</Title>
        <Text>{props.text}</Text>
      </Wrap>
    </Wrapper>
  );
};
