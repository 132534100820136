import React from "react";
import {AppLayout, MiddleContent} from "../../../components/Layout/AppLayout";
import {Logo} from "../../../components/Primitives/Logo";
import {ShadowedBox} from "../../../components/Primitives/Box";
import {RequestResetPasswordForm} from "../../../components/Forms/RequestResetPassword";

export default function RequestResetPasswordPage() {
    return (
        <AppLayout>
            <MiddleContent>
                <Logo/>
                <ShadowedBox>
                    <RequestResetPasswordForm/>
                </ShadowedBox>
            </MiddleContent>
        </AppLayout>
    );
}
