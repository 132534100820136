import React, { FC, useCallback, useState } from "react";
import { Modal } from "../components/Modal";
import { useTranslation } from "react-i18next";
import { BigTitle } from "./styles";
import { StructureAccessId, StructureId } from "../modules/api/api-types";
import { useModalArguments, useModals } from "./useModals";
import { Form } from "react-final-form";
import InputField from "../components/Primitives/InputField";
import { Button } from "../components/Primitives/Button";
import useStructureAccesses from "../modules/api/useStructureAccesses";
import { showFlashMessage } from "../components/FlashMessages";
import styled from "styled-components";
import { TwoColumns } from "../components/Primitives/Col";
import Table from "../components/Table/Table";
import Moment from "react-moment";
import moment from "moment";
import CheckboxField from "../components/Primitives/CheckboxField";

type InvitationValues = {
  email: string;
  firstName: string;
  lastName: string;
  startNow: boolean;
  lifetime: boolean;
  start?: string;
};

type Arguments = {
  id: StructureId;
};

const StructureAccessesModal: FC = () => {
  const { t } = useTranslation();
  const { id } = useModalArguments<Arguments>();

  const { closeAllModals } = useModals();
  const { structureAccesses, inviteToStructure, removeAccessById } =
    useStructureAccesses(id);

  const saveInvitation = useCallback(
    async (values: InvitationValues) => {
      const result = await inviteToStructure(values);

      if (result) {
        showFlashMessage(t("global.messages.successfullySaved"), "success");

        closeAllModals();
      }
    },
    [inviteToStructure]
  );

  const handleClickRemoveAccess = useCallback(
    async (id: StructureAccessId) => {
      if (window.confirm(t("structure.reallyRemoveAccess"))) {
        const result = await removeAccessById(id);

        if (result) {
          showFlashMessage(t("global.messages.successfullySaved"), "success");

          closeAllModals();
        }
      }
    },
    [removeAccessById]
  );

  return (
    <Modal>
      <BigTitle>{t("global.add")}</BigTitle>

      <Form<InvitationValues>
        initialValues={{
          start: moment().format("YYYY-MM-DD"),
          lifetime: true,
          startNow: true,
        }}
        onSubmit={saveInvitation}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <InputField
              name="email"
              label="structure.access.email"
              type="email"
            />
            {}
            <TwoColumns
              items={[
                <CheckboxField
                  name="startNow"
                  label="structure.access.immediately"
                />,
                <CheckboxField name="lifetime" label="structure.access.lifetime" />,
              ]}
            />
            <TwoColumns
              items={[
                !props.values.startNow ? (
                  <InputField
                    name="start"
                    label="structure.access.start"
                    type="date"
                  />
                ) : null,
                !props.values.lifetime ? (
                  <InputField
                    name="end"
                    label="structure.access.end"
                    type="date"
                  />
                ) : null,
                ,
              ]}
            />

            <Button disabled={props.submitting}>{t("global.save")}</Button>
          </form>
        )}
      </Form>

      <br />
      <BigTitle>{t("global.accesses")}</BigTitle>

      {structureAccesses && structureAccesses.length === 0 && (
        <p>{t("structure.noAccess")}</p>
      )}
      {structureAccesses && structureAccesses.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>{t('global.user.user')}</th>
              <th>{t('global.toDate')}</th>
              <th>{t('global.status')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {structureAccesses.length > 0 &&
              structureAccesses.map((access) => (
                <tr>
                  <th>{access.user.printableName}</th>
                  <th>
                    {access.end ? (
                      <Moment>{access.end}</Moment>
                    ) : (
                      t("global.unlimited")
                    )}
                  </th>
                  <th>
                    {access.user.enabled ? t('invitationStatus.active') : t('invitationStatus.unactive')}
                  </th>
                  <th>
                    <Button onClick={() => handleClickRemoveAccess(access.id)}>
                      {t("global.removeAccess")}
                    </Button>
                  </th>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </Modal>
  );
};

export default StructureAccessesModal;
