import {AccessToken, AddStructureAccessInput, AddStructureAccessResult} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

export async function addStructureAccessPost(input: AddStructureAccessInput): Promise<AddStructureAccessResult> {
    const response = await axios.post(settings.apiBasePath + '/api/structure/add-structure-access', input)

    if(response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
