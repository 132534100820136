import React, { FC } from 'react';
import styled from 'styled-components';
import Select, { ValueType } from 'react-select';
import { Languages } from '../../translations';
import { useField } from 'react-final-form';
import { width, WidthProps } from 'styled-system';

const customStyles = {
    // @ts-ignore
    menu: (provided) => {
        return { ...provided, 'z-index': 2 };
    },
    // @ts-ignore
    singleValue: (provided) => {
        return { ...provided, 'line-height': '15px' };
    },
};

const Wrapper = styled.div<WidthProps>`
    ${width};
    font-size: 12px;
`;

const Label = styled.label``;

export type Choice = {
    value: string|number;
    label: string;
};

type SelectFieldProps = {
    label: string;
    name: string;
    choices: Choice[];
    width?: number;
};

const SelectField: FC<SelectFieldProps> = ({ label, name, choices, width = 150 }) => {
    const { input, meta } = useField(name, { type: 'select' });

    const handleChange = (option: ValueType<Choice, false>) => {
        input.onChange(option?.value);
    };

    return (
        <Wrapper width={width}>
            <Label>{label}</Label>
            <Select<Choice>
                styles={customStyles}
                {...input}
                options={choices}
                onChange={handleChange}
                value={choices.find((option: Choice) => option.value === input.value.toString())}
            />
        </Wrapper>
    );
};

export default SelectField;
