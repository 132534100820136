import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px;
  @media (max-width: 479px) {
    padding: 0 15px 24px;  
  }
`;
export const Col = styled.div`
  position: relative;
`;
export const ColLeft = styled.div`
  margin-right: 16px;
`;
export const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
export const Title = styled.h2`
  position: relative;
  margin: 0;
  color: #11181a;
  font-size: 18px;
  font-weight: 700;
`;
export const Text = styled.h3`
  position: relative;
  margin: 0;
  padding: 0 8px 0 0;
  color: ${props => props.theme.colors.main};
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
`;
export const Link = styled.a`
  position: relative;
  margin: 0;
  padding: 0 8px;
  color: #6a6a6a;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 3px;
    height: 3px;
    background: #6a6a6a;
    opacity: 0.24;
    border-radius: 100px;
  }
  span {
    margin-left: 8px;
  }
`;
export const LinkBigger = styled(Link)`
  font-size: 14px;
  &:before {
    display: none;
  }
  cursor: pointer;
`;
export const Span = styled.span`
  position: relative;
`;
