import React, {FC, useCallback, useEffect, useState} from "react";
import {Modal} from "../components/Modal";
import {BigTitle} from "./styles";
import {useTranslation} from "react-i18next";
import InputField from "../components/Primitives/InputField";
import {Button} from "../components/Primitives/Button";
import {Form} from "react-final-form";
import {useExportMeasurements} from "../modules/export/useExportMeasurements";
import {useStructures} from "../modules/api/useStructures";
import {showFlashMessage} from "../components/FlashMessages";
import {useModals} from "./useModals";
import styled from "styled-components";

type DeviceFilterValues = {
    from: string;
    to: string;
}

const Row = styled.div`
  display: -webkit-flex;
  display: flex;
`

const Column = styled.div`
  padding-right: 15px;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`


const yearStart = (): string => {
    return `${(new Date()).getFullYear()}-01-01`;
}

const yearEnd = () : string => {
    const date = new Date();
    const zeroPad = (num: number) => String(num).padStart(2, '0')
    return `${date.getFullYear()}-${zeroPad(date.getMonth() + 1)}-${zeroPad(date.getDate())}`;
}

export const ExportBuildingModal: FC = () => {
    const { t } = useTranslation();

    const { exportBuilding } = useExportMeasurements();
    const {selectedUserBuilding} = useStructures();
    const { closeAllModals } = useModals();

    const handleSubmit = useCallback(async (values: DeviceFilterValues) => {
        if(selectedUserBuilding) {
            showFlashMessage(t(`device.export.runningExportFinalMeasurements`));
            try{
                await exportBuilding(selectedUserBuilding.structure.id, new Date(values.from), new Date(values.to));

                closeAllModals();
            }catch(e){
                showFlashMessage(t(`device.export.runningExportFailed`), 'error');
            }
        }
    }, [selectedUserBuilding]);

    return (
        <Modal>
            <BigTitle>{t('device.export.title')}</BigTitle>

            <Form<DeviceFilterValues>
                initialValues={{
                    from: yearStart(),
                    to: yearEnd()
                }}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Row>
                            <Column><InputField name='from' label='global.fromDate' type='date' max={props.values.to}/></Column>
                            <Column><InputField name='to' label='global.toDate' type='date' min={props.values.from}/></Column>
                            <Column><Button variant='big' disabled={props.submitting}>{t(`device.export.buttonStructure`)}</Button></Column>
                        </Row>
                    </form>
                )}
            </Form>
        </Modal>);
}
