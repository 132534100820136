export default {
    loadingData: 'Wird geladen',
    noData: 'Keine Daten',
    todayConsumption: 'Heute Verbrauch',
    monthlyConsumption: 'Monatlicher Verbrauch',
    thisMonthConsumption: 'Verbrauch in diesem Monat',
    lastMonthConsumption: 'Verbrauch im letzten Monat',
    valve: 'Belüften',
    measurementState: {
        cold: 'Zustand des Wasserzählers',
        hot: 'Zustand des Wasserzählers',
        itn: 'Zustand des  RTN',
        cal: 'Kalorimeterzustand',
        cntrl: 'Zustand des Wasserzählers',
        thermo: 'Temperatur',
        '-': 'Zustand',
    },
    measurementType: {
        cold: 'Kaltes Wasser',
        hot: 'Warmes Wasser',
        itn: 'RTN',
        cal: 'Kalorimeter',
        cntrl: 'Wasserzähler kontrollieren',
        thermo: 'Thermischer Kopf',
        '-': 'Unbekanntes Gerätí',
    },
    export: {
        title: 'Export',
        runningExportDevice: 'Exportgerät starten',
        runningExportFinalMeasurements: 'Beginn des Exports der endgültigen Umfragen',

        runningExportFailed:
            'Export fehlgeschlagen. Unser Team wurde über diesen Vorfall informiert und wir werden versuchen, ihn bald zu beheben.',
        button: 'Messungen exportieren',
        buttonStructure: 'Endmessungen exportieren',
    },
    errors: {
        leak: 'Leck',
        'battery warning': 'Batteriewarnung',
        'no consumption': 'Kein Verbrauch',
        tamper: 'Ein Versuch zu beeinflussen',
        removal: 'Entfernen eines Moduls',
        'reverse installation': 'Umgekehrte Installation',
        oversized: 'Übergroßes Messgerät',
        undersized: 'Unterdimensionierter Meter',
        burst: 'Platzen',
        'battery EOL': 'Batterie',
        dry: 'Trocken',
        frost: 'Frost',
        backflow: 'Rückfluss',
        'battery prewarning': 'Batterie Vorwarnung',
    },
    errorDescriptions: {
        leak: 'Der Durchflussmesser ist mehr als 24 Stunden ungleich Null, überprüfen Sie die Leitungen und Geräte.',
        'battery warning':
            'Der Batteriestatus zeigt 3 Monate an, bis der Sender entladen ist, wenden Sie sich an den Administrator.',
        'no consumption': 'Der Wasserzähler erfasst den Verbrauch lange Zeit nicht, es kann ungenutzter Platz sein.',
        tamper: 'Bei Verdacht auf äußere Einflüsse auf den Verbrauch, Zähler visuell überprüfen.',
        removal:
            'Vermuten Sie das Entfernen des Moduls aus dem Zähler, führen Sie eine Sichtprüfung des Zählers durch.',
        'reverse installation': 'Rückflussmesser mit mehr als 12 Stunden, kontaktieren Sie einen Servicetechniker.',
        oversized: 'Tritt auf, wenn der Flow niemals 10 % von Q3 überschreitet (für 30 Tage)',
        undersized: 'Tritt auf, wenn der ununterbrochene Fluss größer als Q3 ist (für 6 Stunden)',
        burst: 'Die Leckage beträgt 30 Minuten lang mehr als 30% des Nenndurchflusses, schließen Sie die Wasserzufuhr und überprüfen Sie die Leitungen.',
        'battery EOL':
            'Die Batterie des Messgeräts ist erschöpft. Wenden Sie sich an Ihren Administrator, um das Messgerät auszutauschen.',
        dry: 'Geschlossene Wasserzufuhr zum Zähler. Überprüfen Sie die Wasserdichtung.',
        frost: 'Frostgefahr und Beschädigung des Wasserzählergehäuses. Notwendige Inspektion des Servicetechnikers.',
        backflow:
            'Der Zähler erfasste den Rückfluss, Empfehlungen zur Überprüfung der Mischanlage und den Einbau eines Rückschlagventils.',
        'battery prewarning':
            'Der Batteriestatus zeigt 3 Jahre an, bis der Sender entladen ist, wenden Sie sich an den Administrator.',
    },
};
