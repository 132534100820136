export default {
    changeTemp: 'Změnit teplotu',
    tempPlans: 'Plán vytápění',
    thereIsNoProfile: 'Zatím zde nic není. Vytvořte plán vytápění',

    pleaseFillTempProfileName: 'Vyplňte jméno profilu',
    pleaseEnterSomeInstructions: 'Zadejte alespoň jednu instrukci',
    pleaseChoseDays: 'Vyberte dny',

    profileName: 'Jméno profilu',

    thermalProfileSuccessfullySaved: 'Profil úspěšně uložen',
    thermalProfileNotSaved: 'Nepovedlo se uložit profil',

    instructionsCount: 'Počet instrukcí'
}