import LoginPage from "../pages/LoginPage";
import RequestResetPasswordPage from "../pages/RequestResetPasswordPage";
import ReceiveInvitePage from "../pages/ReceiveInvitePage";
import {SetNewPasswordPage} from "../pages/SetNewPasswordPage";

export const routes = [
    {
        path: '/login',
        component: LoginPage,
        exact: false,
        needAuth: false
    },
    {
        path: '/request-reset-password',
        component: RequestResetPasswordPage,
        exact: false,
        needAuth: false
    },
    {
        path: '/set-new-password/:token',
        component: SetNewPasswordPage,
    },
    {
        path: '/invite/:token',
        component: ReceiveInvitePage,
        exact: false,
        needAuth: false
    },
]
