import React, { createRef, FC, RefObject, useEffect, useState } from "react";
import { Wrapper, BowWrapper, Message } from "./styles";
import { FlatStructure, StructureDetail } from "../../modules/api/api-types";
import { Header } from "./Header";
import { Box } from "./Box";
import { useStructureContext } from "../../modules/api/structure-context.";
import { useTranslation } from "react-i18next";
import DeviceBox from "../DeviceBox";

type FlatBoxProps = {
  structureDetails: StructureDetail;
  wrapper: RefObject<HTMLDivElement>;
};

export const FlatBox: FC<FlatBoxProps> = ({ structureDetails, wrapper }) => {
  const elem = createRef<HTMLDivElement>();
  const { t } = useTranslation();
  const { state } = useStructureContext();

  useEffect(() => {
    if (elem.current) {
      if (
        state.scrollToStructure &&
        state.scrollToStructure.id === structureDetails.structure.id
      ) {
        elem.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [state]);

  return (
    <Wrapper ref={elem}>
      <Header structureDetail={structureDetails} />
      {structureDetails.structure.devices.length > 0 && (
        <BowWrapper>
          {/*{structureDetails.structure.devices.map((item) => (*/}
          {/*  <DeviceBox key={`box_device_${item.id}`} />*/}
          {/*))}*/}
          {structureDetails.structure.devices.map(item => <Box key={`box_device_${item.id}`} device={item} scrollWrapper={wrapper}/>)}
        </BowWrapper>
      )}
      {structureDetails.structure.devices.length === 0 && (
        <Message>{t("structure.noDevices")}</Message>
      )}
    </Wrapper>
  );
};
