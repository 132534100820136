import React, { FC } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

export type Color = 'warning' | 'error';

type Tag = {
    label: string;
    color: Color;
    onClick?: () => void;
};

type TagVariants = {
    color: Color;
    isClickable: boolean;
};

const S = {
    Tag: styled.div<TagVariants>`
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        ${(props) =>
            variant({
                prop: 'color',
                variants: {
                    warning: {
                        'background-color': '#ffc107',
                        color: '#212529',
                    },
                    error: {
                        'background-color': '#dc3545',
                        color: '#fff',
                    },
                },
            })}
        cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')}
    `,
};

const Tag: FC<Tag> = ({ label, color, onClick = () => {} }) => {
    return (
        <S.Tag color={color} isClickable={!!onClick} onClick={onClick}>
            {label}
        </S.Tag>
    );
};

export default Tag;
