import React, {useEffect, useMemo, useRef} from "react";
import styled, {useTheme} from "styled-components";
import {GraphData} from "../../modules/api/api-types";
import {AppTheme} from "../../theme/theme";
// @ts-ignore
import Chart from "./../../modules/chart";
import moment from "moment";
import {useTranslation} from "react-i18next";

const Graph = styled.canvas`
  max-width: calc(100vw - 56px - 10px*2 - 15px*2);
  max-height: 250px;
  width: 100% !important;
`

type ThermalChart = {
    graphData: GraphData
}

const ThermalChart: React.FC<ThermalChart> = ({graphData}) => {
    const { t } = useTranslation();
    const canvas = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        if(canvas.current) {
            const tempDataSet = {
                label: t('device.temp'),
                fill: false,
                borderColor: '#ffb822',
                data: graphData.measures.map(measurement => measurement.value),
                yAxisID: 'yTemp',
            }

            const setTempDataSet = {
                label: t('device.setTemp'),
                fill: false,
                borderColor: 'rgba(255, 184, 34, 0.50)',
                data: graphData.measures.map(measurement => measurement.setTemp),
                yAxisID: 'yTemp',
            }

            const humidityDataSet = {
                label: t('device.humidity'),
                fill: false,
                borderColor: '#0179D4',
                data: graphData.measures.map(measurement => measurement.humidity),
                yAxisID: 'yHumidity',
            }

            const valveDataSet = {
                label: t('device.valve'),
                fill: false,
                borderColor: '#C0C0C0',
                data: graphData.measures.map(measurement => measurement.valve),
                yAxisID: 'yHumidity',
            }

            const labels = graphData.measures.map(measurement => moment(measurement.date).format('HH:mm'));

            new Chart(canvas.current, {
                type: 'line',
                data: {
                    labels,
                    datasets: [
                        tempDataSet,
                        setTempDataSet,
                        humidityDataSet,
                        valveDataSet
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                id: 'yTemp',
                                type: 'linear',
                                display: true,
                                position: 'left',
                                ticks: {
                                    suggestedMin: 18,
                                    suggestedMax: 25,
                                    stepSize: 1,
                                    callback: (value, index, ticks) => {
                                        return `${value} °C`;
                                    }
                                },
                            },
                            {
                                id: 'yHumidity',
                                type: 'linear',
                                display: true,
                                position: 'right',
                                ticks: {
                                    suggestedMin: 10,
                                    suggestedMax: 90,
                                    callback: (value, index, ticks) => {
                                        return `${value} %`;
                                    }
                                }
                            }
                        ],
                    }
                },

            })
        }
    }, [canvas]);

    return (
        <Graph ref={canvas} width="100%" height="250"/>
    );
}

export default ThermalChart;


