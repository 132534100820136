import React from 'react';
import Routes from './router';
import GlobalFonts from './fonts/fonts';
import GlobalStyle from './css/globalStyle';
import { ThemeProvider } from './theme/ThemeProvider';
import { AuthProvider } from './modules/auth';
import { ProfileProvider } from './modules/api/profile-context';
import { StructureProvider } from './modules/api/structure-context.';
import { ModalsProvider } from './modals/modals-context';
import { FlashMessages } from './components/FlashMessages';
import { PortalProvider } from './portal/usePortal';
import Moment from 'react-moment';
import { QueryClient, QueryClientProvider } from 'react-query';

Moment.globalLocale = 'cs';
Moment.globalFormat = 'DD. MM. YYYY';

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <PortalProvider>
                <ThemeProvider>
                    <GlobalFonts />
                    <GlobalStyle />
                    <AuthProvider>
                        <ProfileProvider>
                            <StructureProvider>
                                <ModalsProvider>
                                    <Routes />
                                </ModalsProvider>
                            </StructureProvider>
                        </ProfileProvider>
                    </AuthProvider>
                    <FlashMessages />
                </ThemeProvider>
            </PortalProvider>
        </QueryClientProvider>
    );
}

export default App;
