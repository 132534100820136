export default {
    loadingData: 'Loading',
    noData: 'No data',
    todayConsumption: 'Today consumption',
    monthlyConsumption: 'Monthly consumption',
    thisMonthConsumption: 'Consumption this month',
    lastMonthConsumption: 'Consumption last month',
    valve: 'Valve',
    measurementState: {
        cold: 'Cold water state',
        hot: 'Hot water state',
        itn: 'State RTN',
        cal: 'Calorimeter state',
        cntrl: 'Water state',
        thermo: 'Temp',
        '-': 'State',
    },
    measurementType: {
        cold: 'Cold water',
        hot: 'Hot water',
        itn: 'RTN',
        cal: 'Calorimeter',
        cntrl: 'Control device',
        thermo: 'Thermal head',
        '-': 'Unknown device',
    },
    export: {
        title: 'Export',
        runningExportDevice: 'Starting device export',
        runningExportFinalMeasurements: 'Starting ends state of consumptions',
        runningExportFailed: 'Export failed. Our dev team was contacted. Please try it later.',
        button: 'Export measurements',
        buttonStructure: 'Export final measurements',
    },
    errors: {
        leak: 'Leak',
        'battery warning': 'Battery warning',
        'no consumption': 'No consumption',
        tamper: 'Attempt to influence',
        removal: 'Module removal',
        'reverse installation': 'Reverse installation',
        oversized: 'Oversized',
        undersized: 'Undersized',
        burst: 'Burst',
        'battery EOL': 'Battery EOL',
        dry: 'Drz',
        frost: 'Frost',
        backflow: 'Backflow',
        'battery prewarning': 'Battery prewarning',
    },
    errorDescriptions: {
        leak: 'The flow meter is non-zero for more than 24 hours, check the lines and appliances.',
        'battery warning':
            'The battery status indicates 3 months until the transmitter is discharged, contact the administrator.',
        'no consumption': 'The consumption of the water meter is not recorded for a long time, it may be unused space.',
        tamper: 'Suspected of external influences on consumption, visually inspect the meter.',
        removal: 'Suspect removal of the module from the meter, perform a visual inspection of the meter.',
        'reverse installation': 'Reverse flow meter with more than 12 hours, contact a service technician.',
        oversized: 'Occurs if the flow never exceeds 10% of Q3 (for 30 days)',
        undersized: 'Occurs when the uninterrupted flow is greater than Q3 (for 6 hours)',
        burst: 'Leakage is higher than 30% of the nominal flow for 30 minutes, close the water supply and check the lines.',
        'battery EOL': 'The meter battery is exhausted, contact your administrator to replace the meter.',
        dry: 'Closed water supply to the meter. Check the water seal.',
        frost: 'Risk of freezing and damage to the water meter body. Necessary inspection of the service technician.',
        backflow:
            'The meter recorded the backflow, recommendations for checking the mixing equipment and the installation of a non-return valve.',
        'battery prewarning':
            'The battery status indicates 3 years until the transmitter is discharged, contact the administrator.',
    },
};
