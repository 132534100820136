import {EditUser, User} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type GetUserResponse = {
    user: User
}

export async function postEditUser(id: number|null, data: EditUser): Promise<GetUserResponse> {
    const url = id ? `${settings.apiBasePath}/api/user/edit/${id}` : `${settings.apiBasePath}/api/user/create`;

    const response = await axios.post(url, data);

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
