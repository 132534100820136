import styled from "styled-components";

const S = {
  Table: styled.table`
    width: 100%;

    thead {
      text-align: left;
    }
    //display: inline-block;
    //vertical-align: top;
    //max-width: 100%;

    //overflow-x: auto;

    //white-space: nowrap;

    //border-collapse: collapse;
    //border-spacing: 0;
  `,
};

export default S;
