import StructureReducer, {
    selectedUserBuilding,
    setUserBuildingLoading,
    setUserBuildings,
    StructureAction,
    StructureState,
} from './structure-reducer';
import React, { createContext, Dispatch, FC, useContext, useEffect, useReducer, useState } from 'react';
import { useReactContextDevTool } from '../../tools/context-dev-tools';
import { useAuthContext } from '../auth';
import { getUserBuilding } from './structure';

const initState = {
    stateUserBuildings: 'notLoaded',
    userBuildings: [],
    subStructures: {},
    selectedUserBuilding: null,
    scrollToStructure: null,
} as StructureState;

type StructureContextState = {
    state: StructureState;
    dispatch: Dispatch<StructureAction>;
};

const StructureContext = createContext<StructureContextState>({
    state: initState,
    dispatch: () => {},
});

const useStructureContext = (): StructureContextState => useContext(StructureContext);

const StructureProvider: FC = ({ children }) => {
    const { authState } = useAuthContext();
    const [state, dispatch] = useReducer<React.Reducer<StructureState, StructureAction>>(StructureReducer, initState);

    const [contextValue, setContextValue] = useState({
        state,
        dispatch,
    });

    useEffect(() => {
        setContextValue((contextValue: StructureContextState) => ({
            ...contextValue,
            state,
        }));
    }, [state]);

    useEffect(() => {
        const action = async () => {
            const response = await getUserBuilding();

            if (response.buildings) {
                dispatch(setUserBuildings(response.buildings));

                if (response.buildings.length > 0) {
                    dispatch(selectedUserBuilding(response.buildings[0]));
                }
            }
        };

        if (authState.tokensOk && authState.authTokens?.accessToken && state.stateUserBuildings === 'notLoaded') {
            dispatch(setUserBuildingLoading());
            action().then();
        }
    }, [authState]);

    useReactContextDevTool('StructureContext', contextValue.state);

    return <StructureContext.Provider value={contextValue}>{children}</StructureContext.Provider>;
};

export { StructureProvider, useStructureContext, initState };
