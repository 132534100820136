import { useCallback, useEffect, useState } from 'react';
import { filterUsers } from './user/filterUsers';
import { UserListing, UsersFilter } from './api-types';
import { useDebouncedEffect } from '../../helper/useDeboundecEffect';
import axios, { CancelToken, CancelTokenSource } from 'axios';

const initData: UserListing = {
    page: 1,
    maxPage: 1,
    totalCount: 0,
    records: [],
    havePrev: false,
    haveNext: false,
};

const useUsersList = (filter: UsersFilter) => {
    const [isLoading, setLoading] = useState(false);
    const [currentCancelToken, setCurrentCancelToken] = useState<CancelTokenSource | null>(null);
    const [users, setUsers] = useState<UserListing>(initData);

    const loadData = useCallback(async () => {
        if (currentCancelToken) {
            currentCancelToken.cancel();
            setCurrentCancelToken(null);
        }

        const source = axios.CancelToken.source();

        setCurrentCancelToken(source);
        const response = await filterUsers(filter, source.token);
        setCurrentCancelToken(null);

        if (response) {
            setUsers(response.filter);
            setLoading(false);
        }
    }, [filter]);

    useEffect(() => {
        setUsers(initData);
        setLoading(true);
    }, [filter]);

    useDebouncedEffect(() => {
        loadData();
    }, [filter]);

    return {
        isLoading,
        users,
    };
};

export default useUsersList;
