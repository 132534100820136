import { AccessToken, StructureDetails, StructureId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type StructureDetailResponse = {
    structure: StructureDetails;
};

export async function getStructureDetails(id: StructureId): Promise<StructureDetailResponse> {
    const response = await axios.get(`${settings.apiBasePath}/api/structure/detail/${id}`);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
