import { useCallback } from 'react';
import {
    selectedUserBuilding,
    scrollToBuilding,
    updateBuilding,
    clearState,
} from './structure-reducer';
import { useStructureContext } from './structure-context.';
import { BuildingDetails, BuildingStructure, FlatStructure, StructureId } from './api-types';
import { getSubStructure } from './structure';

export const useStructures = () => {
    const { state, dispatch } = useStructureContext();

    const changeSelectedUserBuilding = useCallback(
        (buildingDetail: BuildingDetails) => {
            dispatch(selectedUserBuilding(buildingDetail));
        },
        [dispatch],
    );

    const updateUserBuilding = useCallback(
        (building: BuildingStructure) => {
            dispatch(updateBuilding(building));
        },
        [dispatch],
    );

    const scrollToFlat = useCallback(
        (flat: FlatStructure) => {
            dispatch(scrollToBuilding(flat));
        },
        [dispatch],
    );

    const clearStructures = useCallback(() => {
        dispatch(clearState());
    }, [dispatch]);

    const loadSubStructure = useCallback(async (id: StructureId) => {
        const response = await getSubStructure(id);

        if (response) {
            return response;
        }

        return null;
    }, []);

    return {
        userBuildings: state.userBuildings,
        selectedUserBuilding: state.selectedUserBuilding,
        changeSelectedUserBuilding,
        updateUserBuilding,
        scrollToFlat,
        clearStructures,
        loadSubStructure,
    };
};
