import { useAuth } from "../auth";
import { AddStructureAccessInput } from "./api-types";
import { useCallback } from "react";
import { addStructureAccessPost } from "./access/addStructureAccessPost";

const useAdminStructureAccess = () => {
  const addAccess = useCallback(
    async (input: AddStructureAccessInput) => {
        const result = await addStructureAccessPost(input);

        return result;

    },
    []
  );

  const removeAccess = useCallback(async () => {

  }, []);

  return {
    addAccess,
  };
};

export default useAdminStructureAccess;
