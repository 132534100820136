import { AccessToken, BuildingDetails } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type UserBuildingResponse = {
    buildings: BuildingDetails[];
};

export async function getUserBuilding(): Promise<UserBuildingResponse> {
    const response = await axios.get(`${settings.apiBasePath}/api/structure/user-buildings`);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
