import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Title, Header, Content } from './styles';
import { Info } from '../../Primitives/Info';
import { MeterBox } from '../../MeterBox';
import { Device, GraphData } from '../../../modules/api/api-types';
import { useTranslation } from 'react-i18next';
import { ConsuptionChart } from '../../ConsuptionChart';
import { useMeasurementData } from '../../../modules/api/useMeasurementData';
import { deviceName, formatWithUnit, image } from '../../../modules/units/format';
import useVisibility from '../../../tools/useVisibility';
import styled from 'styled-components';
import { Button } from '../../Primitives/Button';
import { useModals } from '../../../modals/useModals';
import Tag from '../../Primitives/Tag/Tag';
import ThermalChart from '../../ThermalChart';
import DeviceSettingsModal from '../../../modals/DeviceSettingsModal';
import { useStructuresContext } from '../../../modules/api/structure/StructuresProvider';

type BoxProps = {
    device: Device;
    scrollWrapper: RefObject<HTMLDivElement>;
};

type InfoBox = {
    label: string;
    value: string;
};

const FixedSizeText = styled.p`
    /* width: 266px; */
    text-align: center;
`;

export const Box: FC<BoxProps> = ({ device, scrollWrapper }) => {
    const { t } = useTranslation();

    const [deviceSettingsModal, setDeviceSettingsModal] = useState(false);
    const [isVisible, element] = useVisibility<HTMLDivElement>(scrollWrapper, -50);
    const { graphData, errors, thisMonthConsumption, lastMonthConsumption, enable, setEnableLoading } =
        useMeasurementData(device.id);
    const { openExportDeviceModal, openErrorInfoModal } = useModals();

    const lastState = useMemo(() => {
        if (device.lastState && device.lastState.value !== null) {
            return formatWithUnit(device.lastState.value as unknown as number, device.measurementTypeString);
        }

        return '-';
    }, [device]);

    const info = useMemo(() => {
        const info: InfoBox[] = [];

        if (device.measurementTypeString !== 'thermo') {
            info.push({
                label: t('device.thisMonthConsumption'),
                value:
                    thisMonthConsumption === null
                        ? '-'
                        : `Φ ${formatWithUnit(thisMonthConsumption, device.measurementTypeString)}`,
            });
            info.push({
                label: t('device.lastMonthConsumption'),
                value:
                    lastMonthConsumption === null
                        ? '-'
                        : `Φ ${formatWithUnit(lastMonthConsumption, device.measurementTypeString)}`,
            });
        } else {
            if (device.lastState?.data?.setTemp) {
                info.push({
                    label: t('device.setTemp'),
                    value: formatWithUnit(device.lastState?.data?.setTemp, device.measurementTypeString),
                });
            }
            if(graphData?.lastTempChange) {
                if (device.lastCommand?.setTemp !== graphData.lastTempChange.temp) {
                    info.push({
                        label: t('device.setCommandTemp'),
                        value: formatWithUnit(graphData.lastTempChange.temp, device.measurementTypeString),
                    });
                }
            }
            // if (device.lastCommand?.setTemp) {
                // if (device.lastCommand?.setTemp !== device.lastState?.data?.setTemp) {
                //     info.push({
                //         label: t('device.setCommandTemp'),
                //         value: formatWithUnit(device.lastCommand?.setTemp, device.measurementTypeString),
                //     });
                // }
            // }
            if (device.lastState?.data?.humidity) {
                info.push({
                    label: t('device.humidity'),
                    value: `${device.lastState?.data?.humidity.toFixed(1)} %`,
                });
            }
        }

        return info;
    }, [device, thisMonthConsumption, lastMonthConsumption, graphData]);

    useEffect(() => {
        if (isVisible) {
            setEnableLoading(true);
        }
    }, [isVisible, element]);

    return (
        <Col ref={element} data-id={device.id}>
            <Header>
                <Title>
                    {t(`device.measurementType.${device.measurementTypeString}`)}
                    {errors.map((error) => (
                        <>
                            &nbsp;
                            <Tag
                                color={error.error.level === 2 ? 'error' : 'warning'}
                                label={t(`device.errors.${error.error.errorState}`)}
                                onClick={
                                    error.error.showInfobox
                                        ? () => openErrorInfoModal(error.error.errorState)
                                        : undefined
                                }
                            />
                        </>
                    ))}
                    &nbsp;
                    {enable.includes('export') && (
                        <Button onClick={() => openExportDeviceModal(device.id)}>{t('device.export.title')}</Button>
                    )}
                    {enable.includes('tempSet') && (
                        <Button onClick={() => setDeviceSettingsModal(true)}>{t('global.settings')}</Button>
                    )}
                </Title>
            </Header>
            <Content>
                <Col>
                    <MeterBox
                        imgUrl={image(device.measurementTypeString)}
                        title={deviceName(device)}
                        text={device.deviceId}
                    />
                    <Info title={t(`device.measurementState.${device.measurementTypeString}`)} text={lastState} />
                    {info.map((infoRow) => (
                        <Info title={infoRow.label} text={infoRow.value} />
                    ))}
                </Col>
                <Col>
                    {graphData && <GraphView graphData={graphData} />}
                    {!graphData && <FixedSizeText>{t('device.loadingData')}</FixedSizeText>}
                </Col>
            </Content>
            {deviceSettingsModal && (
                <DeviceSettingsModal device={device} requestClose={() => setDeviceSettingsModal(false)} />
            )}
        </Col>
    );
};

type GraphViewProps = {
    graphData: GraphData;
};

const GraphView: FC<GraphViewProps> = ({ graphData }) => {
    const { t } = useTranslation();

    const displayMode = useMemo(() => {
        if (graphData.measures.length === 0) {
            return 'missing-data';
        }

        if (graphData?.measures.length) {
            const nonZeroValue = graphData?.measures.find((value) => value.value !== 0);

            if (!nonZeroValue) {
                // return 'zero-data';
            }
        }

        switch (graphData.type) {
            case 'thermal':
                return 'thermal';
            default:
                return 'default';
        }
    }, [graphData]);

    switch (displayMode) {
        case 'missing-data':
            return <FixedSizeText>{t('device.noData')}</FixedSizeText>;
        // case 'zero-data':
        //     return <FixedSizeText>{t('device.zeroData')}</FixedSizeText>;
        case 'thermal':
            return <ThermalChart graphData={graphData} />;
        default:
            return <ConsuptionChart graphData={graphData} />;
    }
};
