import {AccessToken, LoggedUser, UpdateProfileInput} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type UpdateProfileResponse = {
    user: LoggedUser
}

export async function updateProfile(accessToken: AccessToken, input: UpdateProfileInput): Promise<UpdateProfileResponse> {
    const response = await axios.post(settings.apiBasePath + '/api/user/profile', input, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
