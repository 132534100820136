import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { showFlashMessage } from '../components/FlashMessages';
import { Modal } from '../components/Modal';
import { Button } from '../components/Primitives/Button';
import InputField from '../components/Primitives/InputField';
import { Row } from '../components/Primitives/Row';
import { yearEnd, yearStart } from '../helper/date';
import { StructureId } from '../modules/api/api-types';
import { useExportMeasurements } from '../modules/export/useExportMeasurements';
import { BigTitle } from './styles';
import { useModalArguments, useModals } from './useModals';

type ExportFlatModalValues = {
    from: string;
    to: string;
};

type Arguments = {
    id: StructureId;
};

const Column = styled.div`
    padding-right: 15px;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExportFlatModal: FC = () => {
    const { t } = useTranslation();
    const { id } = useModalArguments<Arguments>();
    const { closeAllModals } = useModals();
    const { exportFlat } = useExportMeasurements();

    const handleSubmit = useCallback(
        async (values) => {
            if (id) {
                showFlashMessage(t(`device.export.runningExportDevice`));

                try {
                    await exportFlat(id, new Date(values.from), new Date(values.to));                
                    closeAllModals();
                } catch (e) {
                    showFlashMessage(t(`device.export.runningExportFailed`), 'error');
                }
            }
        },
        [id],
    );

    return (
        <Modal>
            <BigTitle>{t('device.export.title')}</BigTitle>

            <Form<ExportFlatModalValues>
                initialValues={{
                    from: yearStart(),
                    to: yearEnd(),
                }}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Row>
                            <Column>
                                <InputField name="from" label="global.fromDate" type="date" max={props.values.to} />
                            </Column>
                            <Column>
                                <InputField name="to" label="global.toDate" type="date" min={props.values.from} />
                            </Column>
                            <Column>
                                <Button variant="big" disabled={props.submitting}>
                                    {t('device.export.button')}
                                </Button>
                            </Column>
                        </Row>
                    </form>
                )}
            </Form>
        </Modal>
    );
};

export default ExportFlatModal;
