import cs from "./cs";
import en from "./en";
import de from "./de";
import sk from "./sk";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";

export type Languages = "cs" | "en" | "de" | "sk";
export const AVAILABLE_LANGUAGES: Languages[] = ["cs", "en", "de", "sk"];

export const BrowserLangCodes: Record<Languages,string> = {
  cs: 'cs-CZ,cs;',
  en: 'cs_CZ',
  de: 'cs_CZ',
  sk: 'cs_CZ',
}

export const resources = {
  cs,
  en,
  de,
  sk,
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: "cs",
  interpolation: {
    escapeValue: false,
  },
});

export {
  cs,
  en,
  de,
  sk,
  i18n
};
