export type ThemeMode = 'goliash' | 'renova' | 'fps' | 'pragostav' | 'vodomeryonline';

export const variants = {
    goliash: {
        logos: {
            appLogo: {
                img: '/img/goliash/logo.png',
                alt: 'Goliash',
            },
            fullLogo: {
                img: '/img/goliash/full-logo.png',
                alt: 'Goliash',
            },
        },
        colors: {
            main: '#0179D4',
            shadow: 'rgba(1, 121, 212, 0.33)',
            shadowLighter: 'rgba(1, 121, 212, 0.51)',
            highlightedText: '#0179D4',
            lightBackground: 'rgba(1, 121, 212, 0.11)',
        },
        measurementTypes: {
            cold: {
                background: '#0179d4',
            },
            hot: {
                background: '#FA8072',
            },
            itn: {
                background: '#ffb822',
            },
            cal: {
                background: '#ffb822',
            },
            thermo: {
                background: '#ffb822',
            },
        },
    },
    renova: {
        logos: {
            appLogo: {
                img: '/img/renova/logo.png',
                alt: 'Renova',
            },
            fullLogo: {
                img: '/img/renova/full-logo.png',
                alt: 'Renova',
            },
        },
        colors: {
            main: '#f9a51b',
            shadow: 'rgba(249, 165, 27, 0.33)',
            shadowLighter: 'rgba(249, 165, 27, 0.51)',
            highlightedText: '#a46907',
            lightBackground: 'rgba(249, 165, 27, 0.11)',
        },
        measurementTypes: {
            cold: {
                background: '#0179d4',
            },
            hot: {
                background: '#FA8072',
            },
            itn: {
                background: '#ffb822',
            },
            cal: {
                background: '#ffb822',
            },
            thermo: {
                background: '#ffb822',
            },
        },
    },
    fps: {
        logos: {
            appLogo: {
                img: '/img/fps/logo.png',
                alt: 'FPS',
            },
            fullLogo: {
                img: '/img/fps/full-logo.png',
                alt: 'FPS',
            },
        },
        colors: {
            main: '#0179D4',
            shadow: 'rgba(1, 121, 212, 0.33)',
            shadowLighter: 'rgba(1, 121, 212, 0.51)',
            highlightedText: '#0179D4',
            lightBackground: 'rgba(1, 121, 212, 0.11)',
        },
        measurementTypes: {
            cold: {
                background: '#0179d4',
            },
            hot: {
                background: '#FA8072',
            },
            itn: {
                background: '#ffb822',
            },
            cal: {
                background: '#ffb822',
            },
            thermo: {
                background: '#ffb822',
            },
        },
    },
    pragostav: {
        logos: {
            appLogo: {
                img: '/img/pragostav/logo.png',
                alt: 'Pragostav',
            },
            fullLogo: {
                img: '/img/pragostav/full-logo.png',
                alt: 'Pragostav',
            },
        },
        colors: {
            main: '#E05206',
            shadow: 'rgba(224, 82, 6, 0.33)',
            shadowLighter: 'rgba(224, 82, 6, 0.51)',
            highlightedText: '#FCA100',
            lightBackground: 'rgba(1, 121, 212, 0.11)',
        },
        measurementTypes: {
            cold: {
                background: '#0179d4',
            },
            hot: {
                background: '#FA8072',
            },
            itn: {
                background: '#ffb822',
            },
            cal: {
                background: '#ffb822',
            },
            thermo: {
                background: '#ffb822',
            },
        },
    },
    vodomeryonline: {
        logos: {
            appLogo: {
                img: '/img/vodomeryonline/logo.png',
                alt: 'Renova',
            },
            fullLogo: {
                img: '/img/vodomeryonline/full-logo.png',
                alt: 'Renova',
            },
        },
        colors: {
            main: '#f9a51b',
            shadow: 'rgba(249, 165, 27, 0.33)',
            shadowLighter: 'rgba(249, 165, 27, 0.51)',
            highlightedText: '#a46907',
            lightBackground: 'rgba(249, 165, 27, 0.11)',
        },
        measurementTypes: {
            cold: {
                background: '#0179d4',
            },
            hot: {
                background: '#FA8072',
            },
            itn: {
                background: '#ffb822',
            },
            cal: {
                background: '#ffb822',
            },
            thermo: {
                background: '#ffb822',
            },
        },
    },
};

export type AppTheme = typeof variants['goliash'];

export const baseTheme = {};
