import React, {FC, useCallback, useState, KeyboardEvent} from "react";
import {useHistory} from "react-router";
import {Input} from "../../Primitives/Form";
import {Button } from "../../Primitives/Button";
import {RowSpaceBetween} from "../../Primitives/Row";
import {finishAuthentication, startAuthentication, throwError, useAuthContext} from "../../../modules/auth";
import {Link} from "react-router-dom";
import {loginCheck} from "../../../modules/api/user";
import {AccessToken, RefreshToken} from "../../../modules/api/api-types";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";

const initLoginForm = {
    username: '',
    password: ''
}
type LoginForm = typeof initLoginForm;

export const LoginForm: FC = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const [loginForm, setLoginForm] = useState<LoginForm>(initLoginForm);
    const [error, setError] = useState<string|null>(null);
    const {authState, authDispatch} = useAuthContext();

    const submit = async () => {
        setError(null)
        if(loginForm.username !== "" && loginForm.password !== ''){
            authDispatch(startAuthentication());

            try{
                const loginCheckResult = await loginCheck(loginForm);

                if (loginCheckResult.token) {
                    authDispatch(finishAuthentication({
                        accessToken: loginCheckResult.token as AccessToken,
                        refreshToken: loginCheckResult.refresh_token as RefreshToken,
                    }));

                    history.push('/');
                }
            }catch(e){
                const err = t('global.loginForm.badCredentials');
                setError(err);
                authDispatch(throwError(err));
            }
        }else{
            setError(t('global.loginForm.fill'));
        }
    }

    const submitByEnterPress = useCallback((event: KeyboardEvent<HTMLInputElement>): void => {
        if(event.key === 'Enter'){
            submit().then();
        }
    }, [submit]);

    return (
        <>
            <Input label={t('global.user.emailOrUsername')} initValue={loginForm.username} inputType='email'
                   onChange={username => setLoginForm({...loginForm, username })}
                   onKeyPress={submitByEnterPress}
            />
            <Input label={t('global.user.password')} initValue={loginForm.password} inputType='password'
                   onChange={password => setLoginForm({...loginForm, password })}
                   onKeyPress={submitByEnterPress}
            />

            {error && <p>{error}</p>}

            <RowSpaceBetween variant="alignCenter">

                <LanguageSelector/>
                {/*<Link to="/request-reset-password">*/}
                {/*    <Button variant='link'>Zapomněl jsem heslo</Button>*/}
                {/*</Link>*/}

                <Button variant='big' onClick={submit}>{t('profile.login.login')}</Button>
            </RowSpaceBetween>
        </>
    )
}
