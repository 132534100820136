import {LoginCheckParams} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type LoginCheckResponse = {
    token: string
    refresh_token: string
}

export async function loginCheck(params: LoginCheckParams): Promise<LoginCheckResponse> {
    const response = await axios.post(settings.apiBasePath + '/api/login_check', params, {
        validateStatus: () => true
    });

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
