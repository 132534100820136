import React from 'react';

const IconArrowPlus: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" fill="none">
            <path
                fill="#d61d1d"
                d="M9.64.265l7.333 7.333a.905.905 0 010 1.281l-.855.855a.906.906 0 01-1.279.002L9 3.924 3.161 9.736a.905.905 0 01-1.279-.002l-.855-.855a.905.905 0 010-1.281L8.36.265a.905.905 0 011.281 0z"
            ></path>
        </svg>
    );
};

export default IconArrowPlus;
