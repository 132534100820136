import { StructureId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type InviteToStructureResponse = {};

export async function postInviteToStructure(id: StructureId, data: any): Promise<InviteToStructureResponse> {
    const response = await axios.post(`${settings.apiBasePath}/api/structure/invite/${id}`, data);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
