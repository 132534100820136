export default {
    changeTemp: 'Zmena teploty',
    tempPlans: 'Plán vykurovania',
    thereIsNoProfile: 'Zatiaľ tu nie je nič. Vytvoriť plán vykurovania',

    pleaseFillTempProfileName: 'Vyplňte názov profilu',
    pleaseEnterSomeInstructions: 'Zadajte aspoň jeden pokyn',
    pleaseChoseDays: 'Vyberte dni',

    profileName: 'Názov profilu',

    thermalProfileSuccessfullySaved: 'Profil bol úspešne uložený',
    thermalProfileNotSaved: 'Neúspešné uloženie profilu',

    instructionsCount: 'Počet pokynov'
}