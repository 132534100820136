import React, {FC} from "react";
import styled, {useTheme, withTheme} from "styled-components";
import {AppTheme} from "../../../theme/theme";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86px;
`;

export const Logo: FC = (props) => {
    const theme = useTheme() as AppTheme;

    return (
        <LogoWrapper>
            <img src={theme.logos.appLogo.img} alt={theme.logos.appLogo.alt}/>
        </LogoWrapper>
    )
}
