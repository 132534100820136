import React, {useEffect} from "react";
import {AppLayout} from "../components/Layout/AppLayout";
import {SideMenu} from "../components/SideMenu";
import {Switch, Route, useRouteMatch} from 'react-router';
import ListUsersPage from "./User/ListUsersPage";
import EditUserPage from "./User/EditUserPage";

export default function UsersPage() {
    let { path, url } = useRouteMatch();

    return (
        <AppLayout>
            <SideMenu/>
            <Switch>
                <Route path={`${path}`} exact={true} component={ListUsersPage} />
                <Route path={`${path}/new`} component={EditUserPage} />
                <Route path={`${path}/edit/:id`} component={EditUserPage} />
            </Switch>
        </AppLayout>
    );
}