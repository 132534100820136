import React, {FC, useEffect, useState} from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import {baseTheme, variants, ThemeMode} from './theme'
import usePortal from "../portal/usePortal";

const getTheme = (theme: ThemeMode) => {
    return {
        ...baseTheme,
        ...variants[theme]
    }
}

export const ThemeProvider: FC = ({ children }) => {
    const { style, title } = usePortal();

    useEffect(() => {
        document.title = title;
    }, [title])

    const theme = getTheme(style);

    return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
}
