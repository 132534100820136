import axios from "./../axios";
import settings from "../settings";

type RequestNewPasswordResponse = {

}

export async function requestNewPassword(email: string): Promise<RequestNewPasswordResponse>{
    const response = await axios.post(settings.apiBasePath + '/unprotected-api/user/request-new-password', {
        email
    });

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
