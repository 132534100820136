export default {
    changeTemp: 'Change the temperature',
    tempPlans: 'Heating schedule',
    thereIsNoProfile: 'There is nothing here yet. Create a heating plan',

    pleaseFillTempProfileName: 'Fill in the profile name',
    pleaseEnterSomeInstructions: 'Enter at least one instruction',
    pleaseChoseDays: 'Select the days',

    profileName: 'Profile name',

    thermalProfileSuccessfullySaved: 'Profile successfully saved',
    thermalProfileNotSaved: 'Failed to save profile',

    instructionsCount: 'Number of instructions'
}