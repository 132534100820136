import React from 'react';

const IconArrowMinus: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" fill="none">
            <path
                fill="#0179d4"
                d="M8.36 9.735L1.026 2.401a.905.905 0 010-1.281l.855-.855A.906.906 0 013.16.263L9 6.076 14.839.264a.905.905 0 011.279.002l.855.855a.905.905 0 010 1.281L9.64 9.735a.905.905 0 01-1.281 0z"
            ></path>
        </svg>
    );
};

export default IconArrowMinus;
