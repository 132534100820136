import axios from "./../axios";
import settings from "../settings";

type AcceptInvitationInput = {
    token: string;
    firstName: string;
    lastName: string;
    password: string;
}

type AcceptInvitationResponse = {

}

export async function acceptInvitation(input: AcceptInvitationInput): Promise<AcceptInvitationResponse> {
    const response = await axios.post(settings.apiBasePath + '/unprotected-api/user/invitation', input);

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
