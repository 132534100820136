import React from 'react';
import { AppLayout } from '../components/Layout/AppLayout';
import { SideMenu } from '../components/SideMenu';
import { SideBar } from '../components/SideBar';
import { Content } from '../components/Content';
import StructuresProvider from '../modules/api/structure/StructuresProvider';

export default function AppPage() {
    return (
        <StructuresProvider>
            <AppLayout>
                <SideMenu />
                <SideBar />
                <Content />
            </AppLayout>
        </StructuresProvider>
    );
}
