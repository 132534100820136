export default {
  customName: "Vlastní jméno",
  description: "Popis",
  access: {
    email: "E-mail",
    start: "Začátek",
    end: "Konec",
    immediately: "Přístup ihned",
    lifetime: "Neomezená platnost",
    role: "Role",
    roleLevel: {
      1: "Běžný účet",
      100: "Správce",
    },
    all: "Všechny prostory",
  },
  noDevices: "Tento prostor nemá nainstalované žádné měřící zařízení",
  place: "Prostor",
  noAccess: "Tento prostor nemá přiřazený žádný další přístup.",
  reallyRemoveAccess: "Chcete odstranit tento přístup?",
  types: {
    flat: "Prostor",
    building: "Budova",
  },
};
