import React, {FC, useCallback, useEffect, useState} from "react";
import {Modal } from "../components/Modal";
import {useTranslation} from "react-i18next";
import {BigTitle,Title} from "./styles";
import { Button } from "../components/Primitives/Button";
import { useAuth, } from "../modules/auth";
import {useProfile} from "../modules/api/useProfile";
import {Form} from "react-final-form";
import InputField from "../components/Primitives/InputField";
import {formValidator} from "../validator/validator";
import {closeAllModalsAction} from "./modals-reducer";
import {FormApi, ValidationErrors} from "final-form";
import {showFlashMessage} from "../components/FlashMessages";
import {useModals} from "./useModals";
import {useHistory} from "react-router";
import LanguageSelector from "../components/LanguageSelector/LanguageSelector";

type UserFormValues = {
    firstName: string,
    lastName: string,
    contactEmail: string,
    contactPhone: string,

    currentPassword?: string,
    newPassword?: string,
    newPasswordAgain?: string,
}

export const constraintsUserInfo = {
    firstName: {
        presence: {message: 'profile.editProfile.validation.fillFirstName'},
    },
    lastName: {
        presence: {message: 'profile.editProfile.validation.fillLastName'},
    },
}

export const constraintsPassword = {
    currentPassword: {
        presence: true,
    },
    newPassword: {
        presence: {message: 'profile.editProfile.validation.fillPassword'},
        length:{
            minimum: 5,
            message: 'profile.editProfile.validation.passwordRequirements'
        },
    },
    newPasswordAgain: {
        presence: {message: 'profile.editProfile.validation.fillPasswordAgain'},
        equality: {
            attribute: "newPassword",
            message: 'profile.editProfile.validation.fillPasswordAgain'
        },
    },
}

export const UserProfileModal: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { loggedUser, saveProfile } = useProfile();
    const { closeAllModals } = useModals();

    const logout = () => {
        closeAllModals();
        history.push('/logout');
    }

    const handleSubmit = useCallback(async (values: UserFormValues, form: FormApi<UserFormValues>) => {
        try{
            const result = await saveProfile(values);

            if(result){
                showFlashMessage(t('profile.editProfile.successfullySaved'), "success");
            }else{
                showFlashMessage(t('global.messages.globalError'), "error");
            }
        }catch(e){
            showFlashMessage(t('global.messages.globalError'), "error");
        }

        setTimeout(form.reset);
    }, []);

    const validate = (values: UserFormValues): Promise<ValidationErrors> => {
        let errors = formValidator(values, constraintsUserInfo);

        if(values.currentPassword) {
            errors = {
                ...errors,
                ...formValidator(values, constraintsPassword)
            }
        }

        return errors as Promise<ValidationErrors>;
    }

    return (
        <Modal>
            <BigTitle>{t('profile.editProfile.title')}</BigTitle>

            {loggedUser && loggedUser.enableProfileUpdate && (
                <>
                    <Title>{t('profile.editProfile.editProfile')}</Title>
                    <Form<UserFormValues>
                        initialValues={{
                            firstName: loggedUser.firstName,
                            lastName: loggedUser.lastName,
                            contactEmail: loggedUser.contactEmail,
                            contactPhone: loggedUser.contactPhone,
                        }}
                        onSubmit={handleSubmit}
                        validate={validate}
                    >
                        {(props) => (
                            <form onSubmit={props.handleSubmit}>
                                <InputField name='firstName' label='global.user.firstName' type="text" />
                                <InputField name='lastName' label='global.user.lastName' type="text" />
                                <InputField name='contactEmail' label='global.user.contactEmail' type="email" />
                                <InputField name='contactPhone' label='global.user.contactPhone' type="text" />

                                <Title>{t('profile.editProfile.editPassword')}</Title>

                                <InputField name='currentPassword' label='global.user.currentPassword'type="password" />
                                <InputField name='newPassword' label='global.user.newPassword' type="password" />
                                <InputField name='newPasswordAgain' label='global.user.newPasswordAgain' type="password" />

                                <Button disabled={props.submitting}>{t('global.save')}</Button>
                            </form>
                        )}
                    </Form>
                </>
            )}

            {loggedUser && !loggedUser.enableProfileUpdate && (<span>{t('profile.editProfile.cantBeEdited')}</span>)}

            <hr/>

            <Button onClick={logout}>{t('global.logout')}</Button>
        </Modal>);
}
