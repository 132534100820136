export default {
    invitation: {
        successfullyAccepted: 'Einladung erfolgreich angenommen.'
    },
    login: {
        login: 'Einloggen'
    },
    editProfile: {
        title: 'Benutzerprofil',
        editProfile: 'Bearbeiten',
        editPassword: 'Kennwort ändern',
        changePassword: '',
        successfullySaved: 'Profil erfolgreich gespeichert.',

        cantBeEdited:'Dieses Profil kann nicht bearbeitet werden',

        validation: {
            fillFirstName: 'Der Name darf nicht leer bleiben.',
            fillLastName: 'Der Nachname darf nicht leer bleiben.',

            fillPassword: 'Geben Sie das neue Passwort ein, wenn es geändert werden soll. Andernfalls muss das aktuelle Passwort nicht eingegeben werden.',
            fillPasswordAgain: 'Geben Sie das Passwort erneut ein, es muss mit dem neuen Passwort übereinstimmen.',
            passwordRequirements: 'Das neue Passwort muss mindestens 5 Zeichen lang sein.'
        }
    }
}
