import React, { FC } from 'react';
import { Box, BoxContainer } from '../../../components/Box';
import { Link } from 'react-router-dom';
import { PullRight } from '../../../components/Primitives/aligment';
import Paginator from '../../../components/Paginator';
import useUsersList from '../../api/useUsersList';
import { useUserFilterContext } from '../containers/usersFilterContainer';
import { FilterBox } from '../../../components/Primitives/filter';
import { Input } from '../../../components/Primitives/Form';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Button } from '../../../components/Primitives/Button';
import { useHistory } from 'react-router';

const UsersFilterList: FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { filter, goToPage, setFullText } = useUserFilterContext();
    const { isLoading, users } = useUsersList(filter);

    return (
        <>
            <FilterBox>
                <Input label={t('global.search')} initValue={filter.fullText} onChange={(val) => setFullText(val)} />
            </FilterBox>

            {isLoading && <span>Loading</span>}
            {!isLoading &&
                (users.records.length ? (
                    <BoxContainer>
                        {users.records.map((user) => (
                            <Box key={`user_box_${user.id}`}>
                                {user.firstName} {user.lastName}
                                <br />
                                {user.email}
                                <br />
                                <Button onClick={() => history.push(`/users/edit/${user.id}`)}>{t('global.edit')}</Button>
                            </Box>
                        ))}
                    </BoxContainer>
                ) : (
                    <span>Žádný výsledek</span>
                ))}

            {users.maxPage > 1 && (
                <PullRight>
                    <Paginator
                        currentPage={users.page}
                        maxPage={users.maxPage}
                        havePrev={users.havePrev}
                        haveNext={users.haveNext}
                        changePage={(page) => goToPage(page)}
                    />
                </PullRight>
            )}
        </>
    );
};

export default UsersFilterList;
