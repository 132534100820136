import { StructureId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type ExportBuildingData = {
    language: string;
    building: StructureId;
    from: Date;
    to: Date;
};

export async function exportBuildingData(props: ExportBuildingData) {
    const response = await axios.get(`${settings.apiBasePath}/api/export/export-building-data`, {
        responseType: 'arraybuffer',
        params: props,
    });

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
