import { Device, MeasurementType } from '../api/api-types';

const toFixed = (value: number, places: number): string => {
    return value.toFixed(places);
};

export const deviceName = (device: Device): string => {
    switch (device.measurementTypeString) {
        case 'thermo':
            return 'Mclimate';
        default:
            return 'Zenner';
    }
};

export const image = (type: MeasurementType): string => {
    switch (type) {
        case 'cold':
        case 'hot':
        case 'cntrl':
            return '/img/device/water.jpg';
        case 'cal':
            return '/img/device/cal.jpg';
        case 'itn':
            return '/img/device/itn.png';
        case 'thermo':
            return '/img/device/thermo.png';
        default:
            return '/img/meter-thumb.png';
    }
};

export const formatValue = (value: number, type: MeasurementType): string => {
    switch (type) {
        case 'cold':
        case 'hot':
        case 'cntrl':
            return toFixed(value, 4);
        case 'cal':
            return toFixed(value, 3);
        case 'thermo':
            return toFixed(value, 1);
        default:
            return value.toString();
    }
};

export const getUnit = (type: MeasurementType): string => {
    switch (type) {
        case 'cold':
        case 'hot':
        case 'cntrl':
            return 'm³';
        case 'cal':
            return 'MWh';
        case 'itn':
            return 'j';
        case 'thermo':
            return '°C';
        default:
            return '';
    }
};

export const formatWithUnit = (value: number, type: MeasurementType): string => {
    return `${formatValue(value, type)} ${getUnit(type)}`.trim();
};
