import React, { FC, useCallback } from 'react';
import {useHistory, useParams} from 'react-router';
import { BigTitle } from '../../modals/styles';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/Primitives/InputField';
import { TwoColumns } from '../../components/Primitives/Col';
import { Button } from '../../components/Primitives/Button';
import useAdminUser from '../../modules/api/useAdminUser';
import { EditUser } from '../../modules/api/api-types';
import yup from '../../helper/translatedYup';
import styled from 'styled-components';
import { Box, BoxContainer } from '../../components/Box';
import { validateFormValues } from '../../helper/validateFormValues';
import AddStructureAccess from '../../modules/form/AddStructureAccess';
import { AutoColumns } from '../../components/Primitives/aligment';
import { i18n} from "./../../translations";
import {showFlashMessage} from "../../components/FlashMessages";

const PageWrapper = styled.div`
    height: 100vh;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px;
    @media (max-width: 1023px) {
        min-width: 300px;
    }
    @media (max-width: 479px) {
        padding: 24px 10px;
    }
`;

const schema = yup.object().shape({
    email: yup.string().email().required().label(i18n.t('global.user.email')),
    firstName: yup.string().required().label(i18n.t('global.user.firstName')),
    lastName: yup.string().required().label(i18n.t('global.user.lastName')),
    contactEmail: yup.string().email().nullable(),
    contactPhone: yup.string().nullable(),
});

const EditUserPage: FC = () => {
    const { t } = useTranslation();
    let { id } = useParams();
    const { user, accesses, saveUser } = useAdminUser(id);
    const history = useHistory();
    let isCreate = history.location.pathname.includes('new');

    console.log(id);

    const saveUserCallback = useCallback(async (values: EditUser, isCreate) => {
        const response = await saveUser(id, values);
        isCreate = history.location.pathname.includes('new');

        if (response) {
            if (isCreate){
                showFlashMessage(t(`user.userAdd.success`), 'success');
            } else {
                showFlashMessage(t(`user.userEdit.success`), 'success');
            }
            id = response.user.id;
            history.push(`/users/edit/${id}`)
        }
    }, []);

    const removeAccessCallback = useCallback(async () => {}, []);

    return (
        <PageWrapper>
            <BigTitle>{(isCreate ? t('global.add') : t('global.edit'))}</BigTitle>
            <Form onSubmit={saveUserCallback} validate={validateFormValues(schema)} initialValues={user}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <AutoColumns>
                            <InputField name="email" label="global.user.email" type="email" />
                        </AutoColumns>

                        <AutoColumns>
                            <InputField name="firstName" label="global.user.firstName" />
                            <InputField name="lastName" label="global.user.lastName" />
                        </AutoColumns>

                        <AutoColumns>
                            <InputField name="contactEmail" label="global.user.contactEmail" type="email" />
                            <InputField name="contactPhone" label="global.user.contactPhone" type="tel" />
                        </AutoColumns>

                        <Button disabled={submitting}>{t('global.save')}</Button>
                    </form>
                )}
            </Form>

            {id && (
                <>
                    <BigTitle>{isCreate ? t('global.add') : t('global.edit')}</BigTitle>
                    <AddStructureAccess userId={id} />
                    <BoxContainer>
                        {accesses.map((access) => (
                            <Box key={`access_box_${access.id}`}>
                                <p>{t(`structure.types.${access.structure.type}`)}</p>
                                <p>{access.structure.customName}</p>
                                <p>{access.structure.structureAddress.addressLine}</p>
                                <p>{access.structure.structureAddress.cityLine}</p>
                                {/*<Button onClick={() => removeAccessCallback()}>{t('global.remove')}</Button>*/}
                            </Box>
                        ))}
                    </BoxContainer>
                </>
            )}
        </PageWrapper>
    );
};

export default EditUserPage;
