import React, { FC } from 'react';
import { Wrapper, Button, Span } from './styles';
import { Item, Title, SubTitle, Col } from './Item';
import { DotWithText } from "../../Primitives/DotWithText";
import {BuildingStructure, Device, FlatStructure} from "../../../modules/api/api-types";
import {formatWithUnit} from "../../../modules/units/format";
import {useTranslation} from "react-i18next";

type SubStructureListItemProps = {
    flat: FlatStructure,
    onClick?: () => void
}

export const FlatListItem: FC<SubStructureListItemProps> = ({ flat, onClick= undefined }) => {
    const { t } = useTranslation();
    const title: string[] = [];

    if(flat.floorNumber){
        title.push(flat.floorNumber.toString());
    }

    if(flat.flatNumber){
        title.push(flat.flatNumber.toString());
    }

    const handleClick = () => {
        if(onClick){
            onClick();
        }
    }

    const devices = flat.devices.map(device => {
        const lastState = device.lastState === null || device.lastState.value === null ? '-' :
            formatWithUnit(device.lastState.value as unknown as number, device.measurementTypeString);

        
        return <DotWithText key={`${device.id}`} dotVariant={device.measurementTypeString}>{lastState}</DotWithText>;
    });

    const titleFinal = `${t('structure.place')} ${title.join('.')}`;

    return (
        <Item onClick={handleClick} active={false}>
            <Col>
                <Title>{flat.customName === '' ? titleFinal : flat.customName }</Title>
                <SubTitle>{flat.customName === '' ? '' : titleFinal}</SubTitle>
            </Col>
            <Col>
                {devices}
            </Col>
        </Item>
    )
}

type BuildingListingItemProps = {
    building: BuildingStructure,
    onClick?: () => void
}

export const BuildingListItem: FC<BuildingListingItemProps> = ({building, onClick}) => {
    const click = () => {
        if(onClick){
            onClick();
        }
    }

    return (
        <Item variant="building" onClick={click}>
            <Title>{building.address.addressLine}</Title>
            <SubTitle>{building.address.cityLine}</SubTitle>
        </Item>
    )
}

export const List: FC = ({children}) => {
  return (
    <Wrapper>
        {children}
    </Wrapper>
  );
};

type DeviceListItemProps = {
    masterDevices: Device[],
    onClick?: () => void
}

export const DeviceListItem: FC<DeviceListItemProps> = ({ masterDevices, onClick }) => {
    const { t } = useTranslation();

    const devices = masterDevices.map(device => {
        const lastState = device.lastState && device.lastState.value ?
            formatWithUnit(device.lastState.value as unknown as number, device.measurementTypeString) : '-';

        return <DotWithText key={`${device.id}`} dotVariant={device.measurementTypeString}>{lastState}</DotWithText>;
    });

    const handleClick = () => {
        if(onClick){
            onClick();
        }
    }

    return (
        <Item onClick={handleClick} active={false}>
            <Col>
                <Title>{t(`device.measurementType.cntrl`)}</Title>
            </Col>
            <Col>
                {devices}
            </Col>
        </Item>
    )
}
