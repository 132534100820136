import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { BuildingDetails, Device, StructureDetail } from '../api-types';
import { useStructures } from '../useStructures';

type StructuresContextType = {
    subStructure: StructureDetail[];
    masterDevices: Device[];
    selectedUserBuilding: BuildingDetails | null;
    userBuildings: BuildingDetails[];
    refreshSelected: () => void
};

const StructuresContext = createContext<StructuresContextType>({} as StructuresContextType);

export const useStructuresContext = () => useContext<StructuresContextType>(StructuresContext);

type StructuresProviderProps = {
    children?: React.ReactNode;
};

const StructuresProvider: React.FC<StructuresProviderProps> = ({ children }) => {
    const { selectedUserBuilding, userBuildings, loadSubStructure } = useStructures();

    const [subStructure, setSubStructure] = useState<StructureDetail[]>([]);
    const [masterDevices, setMasterDevices] = useState<Device[]>([]);

    const refreshSelectedUserBuilding = useCallback(async () => {
        if (selectedUserBuilding) {
            const response = await loadSubStructure(selectedUserBuilding.structure.id);

            if (response) {
                response.structures.sort((a, b) => {
                    if (a.structure.flatNumber && b.structure.flatNumber) {
                        return a.structure.flatNumber - b.structure.flatNumber;
                    }

                    if (a.structure.floorNumber && b.structure.floorNumber) {
                        return a.structure.floorNumber - b.structure.floorNumber;
                    }

                    return Number(a.structure.id) - Number(a.structure.id);
                });

                setSubStructure(response.structures);
                setMasterDevices(response.masterDevices);
            }
        }
    }, [selectedUserBuilding]);

    useEffect(() => {
        setSubStructure([]);
        refreshSelectedUserBuilding();
    }, [selectedUserBuilding]);

    return (
        <StructuresContext.Provider
            value={{
                subStructure,
                masterDevices,
                selectedUserBuilding,
                userBuildings,
                refreshSelected: refreshSelectedUserBuilding
            }}
        >
            {children}
        </StructuresContext.Provider>
    );
};

export default StructuresProvider;
