import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-top: auto;
  & + & {
    margin-top: 22px;
  }
`;
export const Title = styled.div`
  position: relative;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
`;
export const Text = styled.div`
  position: relative;
  color: #2a0f51;
  font-size: 16px;
  font-weight: 500;
  margin-top: 1px;
`;