import React from 'react';

const IconChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <g clipPath="url(#A)">
                <path fill="#0179d4" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
            </g>
            <defs>
                <clipPath id="A">
                    <path fill="#fff" d="M0 0h24v24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export default IconChevronRight;
