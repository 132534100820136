import React, {FC, useCallback} from "react";
import {useHistory} from "react-router";
import {RowSpaceBetween} from "../../Primitives/Row";
import {Link} from "react-router-dom";
import {Button} from "../../Primitives/Button";
import {Form} from "react-final-form";
import InputField from "../../Primitives/InputField";
import {formValidator} from "../../../validator/validator";
import {requestNewPassword} from "../../../modules/api/user";

type FormValues = {
    email: string;
}

export const constraints = {
    email: {
        email: true
    }
}

export const RequestResetPasswordForm: FC = () => {
    const history = useHistory();

    const handleSubmit = useCallback(async (values: FormValues) => {
        const response = await requestNewPassword(values.email);
        console.log({response});


        // history.push('/login');
    }, []);

    const handleValidation = useCallback((values: FormValues) => formValidator(values, constraints), []);

    return (
        <>
            <Form<FormValues>
                initialValues={{email: ''}}
                onSubmit={handleSubmit}
                validate={handleValidation}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <InputField name='email' label='global.user.email' type='email'/>

                        <RowSpaceBetween variant="alignCenter">
                            <Link to="/login">
                                <Button variant='link'>Zpět na přihlášení</Button>
                            </Link>
                            <Button disabled={props.submitting} variant='big'>Odeslat</Button>
                        </RowSpaceBetween>
                    </form>
                )}
            </Form>
        </>
    )
}
