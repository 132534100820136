import i18next from 'i18next';
import validate from 'validate.js';

validate.formatters.custom = function (errors: { attribute: string; validator: string }[]) {
    const finalized: Record<string, string> = {};

    for (const error of errors) {
        finalized[error.attribute] = `validate.${error.validator}`;
    }

    return finalized;
};

export function containsLowercaseLetter(text: string): boolean {
    return !!text.match(/[a-z]/);
}

export function containsUppercaseLetter(text: string): boolean {
    return !!text.match(/[A-Z]/);
}

export function containsNumber(text: string): boolean {
    return !!text.match(/[0-9]/);
}

export default function customValidator(attributes: unknown, constraints: unknown) {
    return validate(attributes, constraints, { format: 'custom' });
}

type ValidatorFormatterError = {
    attribute: string;
    error: string;
    options: { message: string; minimum: number };
};

export type FormErrors<T> = Partial<Record<keyof T, string>>;

export function formValidator<T, C>(values: T, rules: C): FormErrors<T> {
    validate.formatters.custom = (errors: ValidatorFormatterError[]) => {
        return errors.map(function (error) {
            let message = error.options.message ? i18next.t(error.options.message) : error.error;

            message = error.options.minimum
                ? message.replace(/\{minimum\}/g, error.options.minimum.toString())
                : message;

            return { [error.attribute]: message };
        });
    };

    const errors = {} as FormErrors<T>;
    const validationResult: typeof errors[] | undefined = validate(values, rules, { format: 'custom' });

    return validationResult
        ? validationResult.reduce((result: typeof errors, error) => ({ ...result, ...error }), {})
        : errors;
}
