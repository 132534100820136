import { Access, User } from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type GetUserResponse = {
  user: User;
  accesses: Access[];
};

export async function getShowUser(
  id: number
): Promise<GetUserResponse> {
  const response = await axios.get(
    `${settings.apiBasePath}/api/user/show/${id}`,
  );

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.data.message);
}
