import React, { DependencyList, EffectCallback } from 'react';
import { debounce } from 'lodash';

export function useDebouncedEffect(
    callback: EffectCallback,
    dependency: DependencyList,
    timeout = 200,
    options = { trailing: true, leading: false },
) {
    const { leading, trailing } = options;
    // the source of truth will be _dependencyRef.current  always
    const [_dependency, _setdependency] = React.useState(dependency);

    // making use of second approach here we discussed above
    const makeChangeTodependency = React.useCallback(
        debounce(
            (dependency) => {
                console.log('runnign makeChangeTodependency', dependency);
                _setdependency(dependency);
            },
            timeout,
            { trailing, leading },
        ),
        [trailing, leading, timeout],
    );

    React.useEffect(() => {
        if (dependency) {
            makeChangeTodependency(dependency);
        }
    }, dependency);

    React.useEffect(callback, _dependency);
}
