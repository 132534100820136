import { BuildingStructure, StructureId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type UpdateStructureDetailsResponse = {
    building: BuildingStructure;
};

export async function postStructureDetails(id: StructureId, data: any): Promise<UpdateStructureDetailsResponse> {
    const response = await axios.post(`${settings.apiBasePath}/api/structure/edit/${id}`, data);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
