import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px;
  @media (max-width: 1023px) {
    min-width: 300px;
  }
  @media (max-width: 479px) {
    padding: 24px 10px;
  }  
`;

