import React, { FC } from 'react';
import styled from 'styled-components';

const S = {
    Container: styled.div`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 12px;
        margin-top: 10px;
        margin-bottom: 10px;
    `,
};

const BoxContainer: FC = ({ children }) => {
    return <S.Container>{children}</S.Container>;
};

export default BoxContainer;
