import React, { FC, MouseEvent } from 'react';
import styled, {css} from 'styled-components';
import {MarginProps, margin, variant,} from "styled-system";

type Variants = 'big'|'link';
type ButtonVariants = {
    variant?: Variants
}

const StyledButtonBase = styled.button<ButtonVariants|MarginProps>`
  border: none;
  background: ${props => props.theme.colors.lightBackground};
  color: ${props => props.theme.colors.main};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  flex: 0 0 auto;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  height: 24px;
  padding: 4px 9px;
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
  ${margin}
  &:hover {
    background: ${props => props.theme.colors.main};
    color: #fff;
  }
  ${(props) => variant({    
    variants: {
      big: css`
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        padding: 6px 14px;
        `,
      link: css`
        color: ${() => props.theme.colors.main};
        text-decoration: underline;
        margin: -4px -9px;
        background: none;
        &:hover {
          color: ${() => props.theme.colors.main};
          background: none;
          text-decoration: none;
        }
      `
    }
  })}
`;

type ButtonProps = {
  variant?: Variants;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const Button: FC<ButtonProps> = ({
  variant= undefined,
  onClick = () => {},
  disabled = false,
  ...rest
}) => {
  return (
    <StyledButtonBase variant={variant} onClick={onClick} disabled={disabled}>
      {rest.children}
    </StyledButtonBase>
  );
};
