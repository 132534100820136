import styled from "styled-components";
import { variant, margin, MarginProps } from 'styled-system';

type Variants = {
    variant?: 'alignCenter' | 'alignEnd'
}

export const Row = styled.div<MarginProps | Variants>`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
    ${margin}
    
    ${() => variant({
    variants: {
        alignCenter: {
            alignItems: 'center',
        },
        alignEnd: {
            alignItems: 'flex-end',
        },
    }
    })}
`

export const RowSpaceBetween = styled(Row)`
    justify-content: space-between;
`
