import React, {createContext, Dispatch, FC, useContext, useEffect, useReducer, useState} from "react";
import ModalsReducer, {ModalsAction, ModalsState} from "./modals-reducer";
import {useReactContextDevTool} from "../tools/context-dev-tools";
import {UserProfileModal} from "./UserProfileModal";
import {ExportBuildingModal} from "./ExportBuildingModal";
import StructureSettingsModal from "./StructureSettingsModal";
import StructureAccessesModal from "./StructureAccessesModal";
import ExportDeviceModal from "./ExportDeviceModal";
import ErrorInfoModal from "./ErrorInfoModal";
import ExportFlatModal from "./ExportFlatModal";

const initState = {
    opened: null,
    args: {}
} as ModalsState;

type ModalsContextState = {
    modalsState: ModalsState
    modalsDispatch: Dispatch<ModalsAction>
}

const ModalsContext = createContext<ModalsContextState>({
    modalsState: initState,
    modalsDispatch: () => {}
});

const useModalsContext = (): ModalsContextState => useContext(ModalsContext);

const ModalsProvider: FC = ({children}) => {
    const [state, dispatch] = useReducer(ModalsReducer, initState)

    const [contextValue, setContextValue] = useState<ModalsContextState>({
        modalsState: state, modalsDispatch: dispatch
    });

    useEffect(() => {
        setContextValue((contextValue: ModalsContextState) => ({
            ...contextValue,
            modalsState: state
        }));
    }, [state]);

    useReactContextDevTool('ModalsContext', contextValue.modalsState);

    return (
        <ModalsContext.Provider value={contextValue}>
            {children}
            {state.opened === 'EditUserProfile' && <UserProfileModal/>}
            {state.opened === 'ExportBuilding' && <ExportBuildingModal/>}
            {state.opened === 'StructureSettings' && <StructureSettingsModal/>}
            {state.opened === 'StructureAccesses' && <StructureAccessesModal/>}
            {state.opened === 'ExportDevice' && <ExportDeviceModal/>}
            {state.opened === 'ErrorInfo' && <ErrorInfoModal/>}
            {state.opened === 'ExportFlat' && <ExportFlatModal/>}
        </ModalsContext.Provider>);
}

export {ModalsProvider, useModalsContext};
