import React, { ChangeEvent, KeyboardEvent, ChangeEventHandler, FC, useCallback, useState } from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    position: relative;
    margin: 0 0 16px;
`;

const StyledLabel = styled.label`
    display: block;
    margin: 0 0 4px;
    color: #707070;
    font-size: 0.75rem;
    font-weight: 400;
    transition: all 0.3s ease;
`;

const StyledInput = styled.input`
    display: flex;
    min-height: 40px;
    width: 100%;
    padding: 8px 12px;
    border: none;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    box-shadow: 0 0.063rem 0.188rem rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    background: #fff;
    color: #000;
    font-size: 0.875rem;
    font-weight: 400;
    outline: none;
    transition: all 0.3s ease;
    &:focus {
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.main};
    }
`;

export type InputProps = {
    label: string;
    initValue: string;
    inputType?: 'text' | 'email' | 'password';
    onChange?: (value: string) => void;
    onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export const Input: FC<InputProps> = ({
    label,
    initValue,
    inputType = 'text',
    onChange = (): void => {},
    onKeyPress = (): void => {},
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onChange(event.target.value);
    };

    return (
        <StyledWrapper>
            <StyledLabel>{label}</StyledLabel>
            <StyledInput value={initValue} type={inputType} onChange={handleChange} onKeyPress={onKeyPress} />
        </StyledWrapper>
    );
};
