import React, {createContext, Dispatch, FC, useContext, useEffect, useReducer, useState} from "react";
import AuthReducer, {
    AuthAction,
    AuthState, finishAuthentication,
    logout,
    startAuthentication, throwError
} from "./auth-reducer";
import {useReactContextDevTool} from "../../tools/context-dev-tools";
import {getTokens} from "./helper/persistTokens";
import {refreshToken} from "../api/user";
import {AccessToken, RefreshToken} from "../api/api-types";
import {registerAuthInterceptor, unregisterAuthInterceptor} from "../api/interceptors/accessTokenInterceptor";

const initState = {
    authTokens: null,
    authentication: true,
    tokensOk: false
} as AuthState;

type AuthContextState = {
    authState: AuthState
    authDispatch: Dispatch<AuthAction>;
}

const AuthContext = createContext<AuthContextState>({
    authState: initState,
    authDispatch: () => {},
})

const useAuthContext = (): AuthContextState => useContext(AuthContext);

const AuthProvider: FC = ({children}) => {
    const [state, dispatch] = useReducer(AuthReducer, initState);
    const [contextValue, setContextValue ] = useState<AuthContextState>({
        authState: state,
        authDispatch: dispatch,
    });

    useEffect(() => {
        setContextValue((contextValue: AuthContextState) => ({
            ...contextValue,
            authState: state,
        }));
    }, [state]);

    useEffect(() => {
        const action = async (token: string) => {
            dispatch(startAuthentication());

            try{
                const refreshTokenResponse = await refreshToken(token);

                if (refreshTokenResponse.token) {
                    dispatch(finishAuthentication({
                        accessToken: refreshTokenResponse.token as AccessToken,
                        refreshToken: refreshTokenResponse.refresh_token as RefreshToken,
                    }));
                }else{
                    throw new Error('Missing token');
                }
            }catch(e){
                dispatch(throwError('Expired refresh token.'));
                dispatch(logout());
            }
        }

        const tokensInit = getTokens();
        if(tokensInit){
            action(tokensInit.refreshToken);
        }else{
            dispatch(logout());
        }
    }, []);

    useEffect(() => {
        console.log('Tokens changed');

        if(state.authTokens) {
            const id = registerAuthInterceptor(state.authTokens.accessToken);

            return () => {
                unregisterAuthInterceptor(id);
            }
        }
    }, [state.authTokens])

    useReactContextDevTool('AuthContext', contextValue.authState);

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export { AuthProvider, useAuthContext }
