import React, {FC} from 'react';
import S from './styles';

type Table = {}

const Table: FC<Table> = ({children}) => {

    return <S.Table>
        {children}
    </S.Table>
}

export default Table;