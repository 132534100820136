import React, {FC, useEffect, useState} from 'react';
import EventEmitter from 'events';
import {createPortal} from "react-dom";
import {MessageWrapper} from "./styles";

const eventEmitter = new EventEmitter();

export type MessageType = 'success'|'error'

type Message = {
    message: string
    type: MessageType
}

export function showFlashMessage(message: string, type: MessageType = 'success'): void{
    eventEmitter.emit('show', {message, type});
}

export const FlashMessage: FC<Message> = ({message, type}) => {
    return (
        <MessageWrapper type={type}>{message}</MessageWrapper>
    )
}

export const FlashMessages: FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);

    useEffect(() => {
        eventEmitter.on('show', ({message, type}: Message) =>{
            const newMessage = {message, type};
            setMessages([...messages, newMessage])


            setTimeout(() => {
                const position = messages.indexOf(newMessage);
                setMessages(messages.splice(position, 1));
            }, 3000);
        })

        return () => {
            eventEmitter.removeAllListeners('show');
        }
    })

    return createPortal((
        <>
            {messages.map(message => <FlashMessage {...message}/>)}
        </>
    ), document.body);
}


