import React, {FC} from 'react';
import styled from "styled-components";

const S = {
    Box: styled.div`
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 10px;
    `,
    Inner: styled.div`
      padding: 20px;
    `
}

type Box = {

}

const Box: FC<Box> = ({ children }) => {

    return (
        <S.Box>
            <S.Inner>
                {children}
            </S.Inner>
        </S.Box>
    )
}

export default Box;