import React, {FC} from "react";
import {UserWrapper} from "./styles";
import {useProfileContext} from "../../../modules/api/profile-context";
import {useModals} from "../../../modals/useModals";

export const UserMenu: FC = () => {
    const { openEditProfileModal } = useModals();
    const { profileState } = useProfileContext()

    const twoCharactersName = () => {
        return profileState.user && profileState.user.firstName ? `${profileState.user.firstName[0]}${profileState.user.lastName[0]}` : null;
    }

    return (
        <UserWrapper onClick={openEditProfileModal}>
            {profileState.user && <span>{twoCharactersName()}</span>}
            {/*<img src="/img/thumb-full.jpg" alt="User"/>*/}
        </UserWrapper>
    );
}
