export default {
  customName: "Custom name",
  description: "Description",
  access: {
    email: "E-mail",
    start: "Start",
    end: "End",
    immediately: "Access immediately",
    lifetime: "Lifetime",
  },
  place: "Place",
  noAccess: "This place has no other access assigned.",
  reallyRemoveAccess: "Do you want to delete this access?",
};
