import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  & + & {
    margin-top: 8px;
  }
`;
export const Span = styled.span`
  position: relative;
  color: #11181a;
  font-size: 14px;
  font-weight: 400;
`;