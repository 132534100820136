import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from '../components/Modal';
import { Device } from '../modules/api/api-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getUserThermalProfiles } from '../modules/api/thermalProfiles/getUserThermalProfiles';
import {
    TempButton,
    TempContent,
    TempNothing,
    TempPlanSwitchContent,
    TempShow,
    TempWrapper,
} from './deviceSettingsModalStuff';

import IconArrowPlus from '../icons/IconArrowPlus';
import IconArrowMinus from '../icons/IconArrowMinus';
import {
    ChildLock,
    ThermalPlan,
    ThermalProfileEditForm,
    ThermalProfileHeadline,
} from './deviceSettingsModalComponents';
import { postThermalDeviceAction } from '../modules/api/device/postDeviceControl';
import { showFlashMessage } from '../components/FlashMessages';
import { debounce } from 'lodash';
import {
    assignProfileToDevice,
    removeThermalProfile,
    unassignProfileToDevice,
} from '../modules/api/device/thermalProfiles';
import { useDebouncedEffect } from '../helper/useDeboundecEffect';
import { useStructuresContext } from '../modules/api/structure/StructuresProvider';
import styled from 'styled-components';

export const MIN_TEMP = 17;
export const MAX_TEMP = 30;

type ThermalDeviceSettingsProps = {
    device: Device;
    requestClose: () => void;
};

const SmallText = styled.p`
    font-size: 12px;
`

const ThermalDeviceSettings: React.FC<ThermalDeviceSettingsProps> = ({ device, requestClose }) => {
    const { t } = useTranslation();

    const { refreshSelected } = useStructuresContext();
    const [initialized, setInitialized] = useState(false);
    const [mode, setMode] = useState<'thermalPlans' | 'editThermalPlan'>('thermalPlans');
    const initialTemp = device.lastCommand?.setTemp || device.lastState?.data?.setTemp || 20;
    const [temp, setTemp] = useState<number>(initialTemp);
    const initialChildLock = device.lastCommand?.childLock || device.lastState?.data?.childLock || false;
    const [childLock, setChildLock] = useState<boolean>(initialChildLock);
    const { data, refetch } = useQuery(`userThermalProfiles-${device.id}`, () => getUserThermalProfiles(device.id));

    const [editProfile, setEditProfile] = useState<number | null>(null);

    const tempPlus = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (temp < MAX_TEMP) {
            setTemp((previous) => previous + 1);
        }
    };

    const tempMinus = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (MIN_TEMP < temp) {
            setTemp((previous) => previous - 1);
        }
    };

    useDebouncedEffect(() => {
        if (initialized) {
            updateDeviceDebounced(temp, childLock);
        }
    }, [temp, childLock]);

    useEffect(() => {
        setInitialized(true);
    }, []);

    const updateDevice = async (setTemp: number, childLock: boolean) => {
        console.log({
            setTemp,
            device: device.id,
            childLock,
        });
        if (
            await postThermalDeviceAction({
                setTemp,
                device: device.id,
                childLock,
            })
        ) {
            showFlashMessage(t('global.messages.successfullySaved'));
            refreshSelected();
        } else {
            showFlashMessage(t('global.messages.globalError'), 'error');
        }
    };

    const updateDeviceDebounced = useMemo(() => debounce(updateDevice, 1000), []);

    const profileActivityChanged = useCallback(
        async (profileId: number, active: boolean) => {
            try {
                if (active) {
                    await assignProfileToDevice(profileId, device.id);
                } else {
                    await unassignProfileToDevice(profileId, device.id);
                }
                showFlashMessage(t('global.messages.successfullySaved'));
            } catch (e) {
                showFlashMessage(t('global.messages.globalError'), 'error');
            }
        },
        [device],
    );

    const handleRemoveProfile = useCallback(
        async (profileId: number) => {
            if (window.confirm('Doopravdy smazat?')) {
                if (await removeThermalProfile(profileId)) {
                    refetch();
                    showFlashMessage(t('global.messages.successfullySaved'));
                } else {
                    showFlashMessage(t('global.messages.globalError'), 'error');
                }
            }
        },
        [device],
    );

    const handleEditProfile = useCallback(
        (profileId: number) => {
            setEditProfile(profileId);
            setMode('editThermalPlan');
        },
        [device],
    );

    const currentPlans = useMemo(() => {
        if (data?.profiles) {
            const plans = data.profiles.map((profile) => ({
                ...profile,
                active: false,
            }));

            return plans;
        }

        return [];
    }, [data, device]);

    return (
        <Modal position="right" title={t('thermal.changeTemp')} requestClose={requestClose}>
            <TempWrapper>
                <TempButton onClick={tempPlus}>
                    <IconArrowPlus />
                </TempButton>
                <TempShow>{temp} °C</TempShow>
                <TempButton onClick={tempMinus}>
                    <IconArrowMinus />
                </TempButton>
                <div style={{ display: 'flex' }}>
                    <ChildLock
                        value={childLock}
                        onChange={() => {
                            setChildLock((previous) => !previous);
                        }}
                    />
                    {t('device.childLock')}
                </div>
                {data?.deviceThermoProfiles.length ? <SmallText>Zařízení ma aktivní teplotní profil, změna bude platná jen do další instrukce.</SmallText> : null}
            </TempWrapper>

            <ThermalProfileHeadline
                showAddButton={mode === 'thermalPlans'}
                title={t('thermal.tempPlans')}
                openCreatePlan={() => {
                    setEditProfile(null);
                    setMode('editThermalPlan');
                }}
            />

            <TempContent>
                {mode === 'thermalPlans' ? (
                    currentPlans.length ? (
                        <TempPlanSwitchContent>
                            {currentPlans.map((profile) => (
                                <ThermalPlan
                                    name={profile.name}
                                    instructionsCount={profile.instructionsCount}
                                    active={!!data?.deviceThermoProfiles.includes(profile.id)}
                                    onActiveChange={(active) => profileActivityChanged(profile.id, active)}
                                    onRemove={() => handleRemoveProfile(profile.id)}
                                    onEdit={() => handleEditProfile(profile.id)}
                                />
                            ))}
                        </TempPlanSwitchContent>
                    ) : (
                        <TempNothing>{t('thermal.thereIsNoProfile')}</TempNothing>
                    )
                ) : (
                    <ThermalProfileEditForm
                        profileId={editProfile}
                        cancelEdit={() => {
                            setMode('thermalPlans');
                            setEditProfile(null);
                        }}
                        done={() => {
                            setMode('thermalPlans');
                            setEditProfile(null);
                            refetch();
                        }}
                    />
                )}
            </TempContent>
        </Modal>
    );
};

type DeviceSettingsModalProps = {
    device: Device;
    requestClose: () => void;
};

const DeviceSettingsModal: React.FC<DeviceSettingsModalProps> = ({ device, requestClose }) => {
    if (device.measurementTypeString === 'thermo') {
        return <ThermalDeviceSettings device={device} requestClose={requestClose} />;
    }

    return null;
};

export default DeviceSettingsModal;
