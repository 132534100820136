import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { AddStructureAccessInput } from '../api/api-types';
import { Button } from '../../components/Primitives/Button';
import { useTranslation } from 'react-i18next';
import useAdminStructureAccess from '../api/useAdminStructureAccess';
import BuildingSelectField from '../structure/components/BuildingSelectField';
import AreaSelectField from '../structure/components/AreaSelectField';
import SelectField from '../../components/Primitives/SelectField';
import { AutoColumns } from '../../components/Primitives/aligment';
import { FilterBox } from '../../components/Primitives/filter';
import RoleSelectField from '../structure/components/RoleSelectField';

type AddStructureAccessProps = {
    userId: number;
};

const AddStructureAccess: FC<AddStructureAccessProps> = ({ userId }) => {
    const { t } = useTranslation();
    const { addAccess } = useAdminStructureAccess();

    const saveCallback = useCallback(async (values: AddStructureAccessInput) => {
        const result = await addAccess({
            ...values,
            user: userId,
        });

        console.log(result);
    }, []);

    return (
        <Form<AddStructureAccessInput>
            onSubmit={saveCallback}
            initialValues={{
                role: 100,
            }}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <FilterBox>
                        <BuildingSelectField label={t('structure.types.building')} name="building" />
                        <AreaSelectField label={t('structure.types.flat')} name="flat" buildingSelectName="building" />
                        <RoleSelectField label={t('structure.access.role')} name="role" />
                        <Button variant="big" disabled={submitting}>
                            {t('global.add')}
                        </Button>
                    </FilterBox>
                </form>
            )}
        </Form>
    );
};

export default AddStructureAccess;
