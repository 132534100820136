import axios from '../axios';
import settings from '../settings';

export type ThermalProfile = {
    id: number;
    name: string;
    instructionsCount: null | number;
};

export type UserThermalProfiles = {
    profiles: ThermalProfile[];
    deviceThermoProfiles: number[];
};

export async function getUserThermalProfiles(deviceId?: number): Promise<UserThermalProfiles> {
    const response = await axios.get(`${settings.apiBasePath}/api/thermal-schedule/user-profiles`, {
        params: { deviceId },
    });

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
