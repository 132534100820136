export default {
    invitation: {
        successfullyAccepted: 'Invitation successfully accepted.'
    },
    login: {
        login: 'Log in'
    },
    editProfile: {
        title: 'User profile',
        editProfile: 'Edit',
        editPassword: 'Change password',
        changePassword: '',
        successfullySaved: 'Profile successfully saved.',

        cantBeEdited:'This profile cannot be edited',

        validation: {
            fillFirstName: 'First name could not stay empty.',
            fillLastName: 'Last name could not stay empty.',

            fillPassword: 'Fill new password if it is to be changed.',
            fillPasswordAgain: 'Fill password again, must be same as new password.',
            passwordRequirements: 'Password should have at least 5 characters.'
        }
    },
}
