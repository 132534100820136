import React from 'react';
import { Wrapper, Span } from './styles';
import { Pill } from "../Pill";
import {MeasurementType} from "../../../modules/api/api-types";

type DotWithTextProps = {
  dotVariant: MeasurementType,
  children: React.ReactNode
}

export function DotWithText(props: DotWithTextProps) {
  return (
    <Wrapper>
      <Pill variant={props.dotVariant} />
      <Span>{props.children}</Span>
    </Wrapper>
  );
};
