import { StructureDetails, StructureId } from './api-types';
import { useAuth } from '../auth';
import { useCallback, useEffect, useState } from 'react';
import { getStructureDetails, postStructureDetails } from './structure';
import { useStructures } from './useStructures';

export type UpdateStructureDetailValues = {
    customName: string;
    description: string;
};

const useStructureDetails = (id: StructureId) => {
    const { updateUserBuilding } = useStructures();
    const [structureDetails, setStructureDetails] = useState<StructureDetails | null>(null);

    const loadData = useCallback(async () => {
        if (id) {
            const result = await getStructureDetails(id);

            setStructureDetails(result.structure);
        }
    }, [id]);

    const updateStructureDetails = useCallback(
        async (values: any): Promise<boolean> => {
            if (id) {
                const result = await postStructureDetails(id, values);

                if (result) {
                    updateUserBuilding(result.building);
                }
            }

            return true;
        },
        [id],
    );

    useEffect(() => {
        loadData().then();
    }, [id]);

    return {
        refreshData: loadData,
        structureDetails,
        updateStructureDetails,
    };
};

export default useStructureDetails;
