import {RefreshToken} from "../api-types";
import axios from "./../axios";
import settings from "../settings";

type RefreshTokenResponse = {
    token: string
    refresh_token: string
}

export async function refreshToken(refreshToken: RefreshToken): Promise<RefreshTokenResponse> {
    const response = await axios.post(settings.apiBasePath + '/token/refresh', {
        refresh_token: refreshToken
    }, {
        validateStatus: () => true
    });

    if(response.status === 200){
        return response.data;
    }

    throw new Error(response.data.message);
}
