import React, { FC, useMemo } from "react";
import { Wrapper, Menu, MenuItem } from "./styles";
import { IconChart, IconDashboard, IconUsers, IconFilter } from "../../icons";
import { Logo } from "./Logo";
import { UserMenu } from "./UserMenu";
import { useProfileContext } from "../../modules/api/profile-context";

type MenuItem = {
  to: string;
  icon: JSX.Element;
};

export const SideMenu: FC = () => {
  const { profileState } = useProfileContext()
  
  const menuItems = useMemo(() => {
    const menuItems: MenuItem[] = [
      {
        to: "/measurements",
        icon: <IconChart />,
      },
    ];

    // if(profileState.us)
    // [
    //   // {
    //   //   to: '/',
    //   //   icon: <IconDashboard />,
    //   // },
      
    //   // {
    //   //   to: "/users",
    //   //   icon: <IconUsers />,
    //   // },
    //   // {
    //   //   to: '/thermal-profiles',
    //   //   icon: <IconDashboard />
    //   // }
    // ];

    return menuItems;
  }, [])

  return (
    <Wrapper>
      <Logo />
      <Menu>
        {menuItems.map((menuItem) => (
          <MenuItem to={menuItem.to}>{menuItem.icon}</MenuItem>
        ))}
      </Menu>
      <UserMenu />
    </Wrapper>
  );
};
