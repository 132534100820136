export default {
  customName: "Vlastné meno",
  description: "Popis",
  access: {
    email: "E-mail",
    start: "Začiatok",
    end: "Koniec",
    immediately: "Prístup ihneď",
    lifetime: "Neobmedzená platnosť",
  },
  noDevices: "Tento priestor nemá nainštalované žiadne meracie zariadenie",
  place: "Priestor",
  noAccess: "Tento priestor nemá priradený žiadny ďalší prístup.",
  reallyRemoveAccess: "Chcete odstrániť tento prístup?",
};