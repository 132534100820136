import {Reducer} from "react";
import {AuthTokens, LoggedUser} from "../api/api-types";
import {clearTokens, saveTokensLocally} from "./helper/persistTokens";

const START_AUTH = "startAuth";
const FINISH_AUTH = "finishAuth";
const ERROR = "error";
const LOGOUT = "logout";

type BaseAction<T, P = unknown> = {
    type: T;
    payload?: P;
};

function createAction<T, P>(type: T, payload?: P): BaseAction<T, P> {
    return {
        type,
        payload,
    };
}

export type AuthState = {
    authTokens: AuthTokens|null;
    authentication: boolean;
    tokensOk: boolean;
    error?: string;
}

export type AuthAction =
    | BaseAction<typeof START_AUTH>
    | BaseAction<typeof FINISH_AUTH, AuthTokens>
    | BaseAction<typeof ERROR, string>
    | BaseAction<typeof LOGOUT>

;

const AuthReducer: Reducer<AuthState, AuthAction> = (state, action) => {
    switch(action.type){
        case START_AUTH:
            return {
                ...state,
                authentication: true,
                tokensOk: false,
                error: undefined,
            }
        case FINISH_AUTH:
            saveTokensLocally(action.payload as AuthTokens);
            return {
                ...state,
                authentication: false,
                error: undefined,
                tokensOk: true,
                authTokens: action.payload as AuthTokens
            }
        case LOGOUT:
            clearTokens();
            return {
                ...state,
                error: undefined,
                authentication: false,
                tokensOk: false,
                authTokens: null
            }
        case ERROR:
            return {
                ...state,
                tokensOk: false,
                authentication: false,
                error: action.payload
            }
        default:
            return state;
    }
}

export const startAuthentication = (): AuthAction => createAction(START_AUTH);
export const finishAuthentication = (authTokens: AuthTokens): AuthAction => createAction(FINISH_AUTH, authTokens);

export const logout = (): AuthAction => createAction(LOGOUT);

export const throwError = (error: string): AuthAction => createAction(ERROR, error);

export default AuthReducer;
