export default {
    save: 'Save',
    logout: 'Logout',
    loginForm: {
        fill: 'Please fill your email and password',
        badCredentials: 'Wrong email or password.',
    },
    fromDate: 'From',
    toDate: 'To',

    all: 'Add',
    settings: 'Settings',
    change: 'Change',
    close: 'Close',
    edit: 'Edit',
    accesses: 'Accesses',
    removeAccess: 'Remove access',
    unlimited: 'Unlimited',

    search: 'Vyhledat',
    remove: 'Odstranit',

    user: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'E-mail',
        emailOrUsername: 'Email/Username',
        currentPassword: 'Current password',
        password: 'Password',
        passwordAgain: 'Password again',
        newPassword: 'New password',
        newPasswordAgain: 'New password again',
        contactEmail: 'Contact e-mail',
        contactPhone: 'Contact phone',
    },

    messages: {
        successfullySaved: 'Successfully saved',
        globalError: 'Something is broken, please try it later.',
    },

    introMessage: {
        odecty: 'Welcome in application for remote device readings.',
    },

    languages: {
        cs: 'Česky',
        en: 'English',
        de: 'Deutsche',
        sk: 'Slovensky',
    },

    daysShort: {
        mo: 'Mo', 
        tu: 'Tu', 
        we: 'We', 
        th: 'Th', 
        fr: 'Fr', 
        su: 'Su', 
        sa: 'Sa'
    },
    days: {
        mo: 'Monday', 
        tu: 'Tuesday', 
        we: 'Wednesday', 
        th: 'Thursday', 
        fr: 'Friday', 
        su: 'Sunday', 
        sa: 'Saturday'
    }
};
