import styled from 'styled-components';
import { variant } from "styled-system";

export const Title = styled.h2`
  position: relative;
  color: #11181a;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
`;
export const SubTitle = styled.h3`
  position: relative;
  color: ${props => props.theme.colors.main};
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0 0;
`;
export const Col = styled.div`
  position: relative;
`;

type ItemVariants = {
  variant?: 'building',
  active?: true | false
}
export const Item = styled.button<ItemVariants>`
  position: relative;
  box-shadow: ${(props) => props.active ? '0 3px 5px' + props.theme.colors.shadow : "0 1px 2px rgba(0, 0, 0, 0.1);"};
  border-radius: 2px;
  background: ${(props) => props.active ? props.theme.colors.main : "rgba(255, 255, 255, 0.95)"};
  border: solid 1px transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 8px;
  padding: 18px;
  text-align: left;
  cursor: pointer;
  outline: none;
  transition: all 300ms ease;
  &:hover {
    border-color: ${props => props.theme.colors.main};;
  }
  ${() => variant({
  variants: {
    building: {
      display: 'block',
      margin: '0',
      background: 'none',
      boxShadow: 'none',
      border: 'none',
    },
  }})}
  ${Title} {
    ${() => variant({
    variants: {
      building: {
        color: '#11181a',
        fontSize: '18px',
      },
    }
  })}},
  ${SubTitle} {
    ${() => variant({
    variants: {
      building: {
        color: '#6a6a6a',
      },
    }
  })}},
`;
Item.defaultProps = {
  active: false
}
