import {DeviceId, GraphData, Error} from "./api-types";
import {useAuth} from "../auth";
import {useCallback, useEffect, useState} from "react";
import {getDeviceMeasurements} from "./device";

export const useMeasurementData = (id: DeviceId) => {
    const [enableLoading, setEnableLoading] = useState(false);
    const [graphDataLoading, setGraphDataLoading] = useState(false);

    const [graphData, setGraphData] = useState<GraphData|null>(null);
    const [errors, setErrors] = useState<Error[]>([]);

    const [thisMonthConsumption, setThisMonthConsumption] = useState<number|null>(null)
    const [lastMonthConsumption, setLastMonthConsumption] = useState<number|null>(null);

    const [enable, setEnable] = useState<string[]>([])

    const loadGraphData = useCallback(async () => {
        if(enableLoading && !graphDataLoading && !graphData){
            setGraphDataLoading(true);

            const dateFrom = new Date();
            dateFrom.setDate(dateFrom.getDate() - 7);

            const dateTo = new Date();

            const result = await getDeviceMeasurements({
                id,
                dateFrom,
                dateTo,
            });

            setGraphData(result.graphData);

            setThisMonthConsumption(result.thisMonthConsumption);
            setLastMonthConsumption(result.lastMonthConsumption);

            setEnable(result.enable);

            if(null !== result.errors){
                setErrors(result.errors);
            }

            setGraphDataLoading(false);
        }
    }, [enableLoading, graphDataLoading, graphData]);

    useEffect(() => {
        loadGraphData().then();
    }, [enableLoading])

    return {
        graphData, errors, thisMonthConsumption, lastMonthConsumption, enable, setEnableLoading
    }
}
