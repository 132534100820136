import {useModalsContext} from "./modals-context";
import {
    closeAllModalsAction,
    openEditProfileModalAction, openErrorInfoModalAction,
    openExportBuildingModalAction, openExportDeviceModalAction, openStructureAccessesModalAction,
    openStructureSettingsModalAction, openExportFlatModalAction
} from "./modals-reducer";
import {DeviceId, StructureId} from "../modules/api/api-types";

export const useModals = () => {
    const {modalsDispatch, modalsState} = useModalsContext();

    const openEditProfileModal = () => {
        modalsDispatch(openEditProfileModalAction());
    }

    const openExportBuildingModal = () => {
        modalsDispatch(openExportBuildingModalAction());
    }

    const openExportDeviceModal = (id: DeviceId) => {
        modalsDispatch(openExportDeviceModalAction(id));
    }

    const openExportFlatModal = (id: StructureId) => {
        modalsDispatch(openExportFlatModalAction(id));
    }

    const openStructureSettingsModal = (id: StructureId) => {
        modalsDispatch(openStructureSettingsModalAction(id));
    }

    const openStructureAccessesModal = (id: StructureId) => {
        modalsDispatch(openStructureAccessesModalAction(id));
    }

    const openErrorInfoModal = (error: string) => {
        modalsDispatch(openErrorInfoModalAction(error));
    }

    const closeAllModals = () => {
        modalsDispatch(closeAllModalsAction());
    }

    return {
        openEditProfileModal,
        openExportBuildingModal,
        openExportDeviceModal,
        openExportFlatModal,
        openStructureSettingsModal,
        openStructureAccessesModal,
        openErrorInfoModal,
        closeAllModals
    }
}

export const useModalArguments = <T>(): T => {
    const {modalsState} = useModalsContext();

    return modalsState.args as unknown as T;
}
