import axios from "./../axios";
import {Languages} from "../../../translations";

type LanguageInterceptorId = null|number;
let currentInterceptor: LanguageInterceptorId = null;

const changeLanguageInterceptor = (lang: Languages) => {
    if(currentInterceptor){
        axios.interceptors.request.eject(currentInterceptor);
    }

    currentInterceptor = axios.interceptors.request.use((config) => {
        config.headers = {
            ...config.headers,
            'Accept-Language': lang
        }

        return config;
    });
}

export {
    changeLanguageInterceptor,
}
