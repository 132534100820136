export default {
    changeTemp: 'Ändern Sie die Temperatur',
    tempPlans: 'Heizplan',
    thereIsNoProfile: 'Hier gibt es noch nichts. Einen Heizplan erstellen',

    pleaseFillTempProfileName: 'Füllen Sie den Profilnamen aus',
    pleaseEnterSomeInstructions: 'Mindestens eine Anweisung eingeben',
    pleaseChoseDays: 'Wählen Sie die Tage',

    profileName: 'Name des Profils',

    thermalProfileSuccessfullySaved: 'Profil erfolgreich gespeichert',
    thermalProfileNotSaved: 'Profil konnte nicht gespeichert werden',

    instructionsCount: 'Anzahl der Anweisungen'
}