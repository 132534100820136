import ProfileReducer, {ProfileAction, profileIsLoading, ProfileState, setLoggedUser} from "./profile-reducer";
import React, {createContext, Dispatch, FC, useContext, useEffect, useReducer, useState} from "react";
import {useReactContextDevTool} from "../../tools/context-dev-tools";
import {useAuth, useAuthContext} from "../auth";
import {getUser} from "./user";

const initState = {
    state: 'notLoaded',
    user: null
} as ProfileState;

type ProfileContextState = {
    profileState: ProfileState
    profileDispatch: Dispatch<ProfileAction>
};

const ProfileContext = createContext<ProfileContextState>({
    profileState: initState,
    profileDispatch: () => {}
});

const useProfileContext = (): ProfileContextState => useContext(ProfileContext);

const ProfileProvider: FC = ({children}) => {
    const { authState } = useAuthContext();

    const [state, dispatch] = useReducer(ProfileReducer, initState);

    const [contextValue, setContextValue] = useState<ProfileContextState>({
        profileState: state, profileDispatch: dispatch
    });

    useEffect(() => {
        setContextValue((contextValue: ProfileContextState) => ({
            ...contextValue,
            profileState: state,
        }));
    }, [state]);

    useEffect(() => {
        const action = async (accessToken: string) => {
            const response = await getUser(accessToken);

            if(response.user){
                dispatch(setLoggedUser(response.user));
            }
        }

        if(authState.tokensOk && authState.authTokens?.accessToken && state.state === 'notLoaded'){
            dispatch(profileIsLoading())
            action(authState.authTokens.accessToken);
        }
    }, [authState]);

    useReactContextDevTool('ProfileContext', contextValue.profileState);

    return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>
}

export { ProfileProvider, useProfileContext, initState }
