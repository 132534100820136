import styled from "styled-components";

export const AppLayout = styled.div`
  height: 100%;
  display: flex;
  background: #fbf9f5;
  @media (max-width: 1023px) {
    overflow: hidden;
  }
`

export const MiddleContent = styled.div`
    width: 100%;
    max-width: 400px;
    margin: auto;
    position: relative;
`
