import React, {FC, useEffect, useState} from "react";
import Select from "react-select";
import usePortal from "../../portal/usePortal";
import {AVAILABLE_LANGUAGES, Languages} from "../../translations";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

type Item = {
    value: Languages
    label: string
}

const customStyles = {
    // @ts-ignore
    singleValue: (provided) => {
        return { ...provided, 'line-height': '15px' };
    }
}

const Wrapper = styled.div`
    width: 150px;
    font-size: 12px;
`

const LanguageSelector: FC = () => {
    const [choices, setChoices] = useState<Item[]>([])
    const {t, } = useTranslation();

    const { defaultLanguage, currentLanguage, changeLanguage} = usePortal();

    useEffect(() => {
        const preparedChoices = AVAILABLE_LANGUAGES.map(lang => ({
            value: lang, label: t(`global.languages.${lang}`)
        }))
        setChoices(preparedChoices)
    }, [currentLanguage]);

    return <Wrapper>
        <Select styles={customStyles} options={choices} value={choices.find(item => item.value === currentLanguage)} onChange={(value) => value && changeLanguage(value.value as Languages)}/>
    </Wrapper>
}
export default LanguageSelector;
