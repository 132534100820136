export default {
    invitation: {
        successfullyAccepted: 'Pozvánka úspěšně přijata.'
    },
    login: {
        login: 'Přihlásit se'
    },
    editProfile: {
        title: 'Uživatelský profil',
        editProfile: 'Upravit',
        editPassword: 'Změnit heslo',
        changePassword: '',
        successfullySaved: 'Profil uspěšně uložen.',

        cantBeEdited:'Tento profil nemůže být upravován',

        validation: {
            fillFirstName: 'Jméno nesmí zůstat prázdné.',
            fillLastName: 'Příjmení nesmí zůstat prázdné.',

            fillPassword: 'Vyplň nové heslo pokud má být změněno. V opačném případě není nutné vyplňovat současné heslo.',
            fillPasswordAgain: 'Vyplň heslo znovu, Musí být stejné jako nové heslo.',
            passwordRequirements: 'Nové heslo musí mít alespoň 5 znaků.'
        }
    }
}
