import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, useField } from 'react-final-form';
import Switch from 'react-switch';
import IconAddCircle from '../icons/IconAddCircle';
import {
    HeadlineWrap,
    SmallButton,
    TempDropdownBox,
    TempDropdownButton,
    TempDropdownItem,
    TempHeader,
    TempPlanBox,
    TempPlanFooter,
    TempPlanFooterButtonAccept,
    TempPlanFooterButtonReject,
    TempPlanItem,
    TempPlanItemButton,
    TempPlanItemFlex,
    TempPlanLabel,
    TempPlanSwitchBox,
    TempPlanSwitchContent,
    TempPlanSwitchHeader,
    TempPlanText,
} from './deviceSettingsModalStuff';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import InputField from '../components/Primitives/InputField';
import IconCheck from '../icons/IconCheck';
import IconChevronRight from '../icons/IconChevronRight';
import { MAX_TEMP, MIN_TEMP } from './DeviceSettingsModal';
import { editThermalProfile, getProfileById } from '../modules/api/device/thermalProfiles';
import { showFlashMessage } from '../components/FlashMessages';
import IconChange from '../icons/IconEdit';
import IconRemove from '../icons/IconRemove';
import { useQueries, useQuery } from 'react-query';

const inclusiveRange = (start: number, end: number): number[] => {
    return Array.from(Array.from(Array(Math.ceil(end - start + 1)).keys()), (x) => start + x);
};

const zeroPad = (num: number) => String(num).padStart(2, '0');

type ThermalProfileHeadline = {
    title: string;
    showAddButton: boolean;
    openCreatePlan?: () => void;
};

export const ThermalProfileHeadline: React.FC<ThermalProfileHeadline> = ({
    title,
    showAddButton,
    openCreatePlan = () => {},
}) => {
    return (
        <TempHeader>
            <span>{title}</span>
            {showAddButton && (
                <TempDropdownButton type="button" onClick={openCreatePlan}>
                    <IconAddCircle />
                </TempDropdownButton>
            )}
        </TempHeader>
    );
};

type ThermalPlanProps = {
    name: string;
    active: boolean;
    instructionsCount: null | number;
    onActiveChange?: (isActive: boolean) => void;
    onRemove?: () => void;
    onEdit?: () => void;
};

export const ThermalPlan: React.FC<ThermalPlanProps> = ({
    name,
    active,
    instructionsCount,
    onActiveChange = () => {},
    onRemove = () => {},
    onEdit = () => {},
}) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(active);

    const handleChange = (val: boolean) => {
        onActiveChange(val);
        setIsActive(val);
    };

    return (
        <TempPlanSwitchBox>
            <TempPlanSwitchHeader>
                <Switch
                    onChange={() => handleChange(!isActive)}
                    checked={isActive}
                    onColor="#0179D4"
                    offColor="#B9B9B9"
                    onHandleColor="#FFFFFF"
                    offHandleColor="#FFFFFF"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow=" 0px 1px 4px rgba(0, 0, 0, 0.25)"
                    activeBoxShadow=" 0px 1px 4px rgba(0, 0, 0, 0.25)"
                    height={16}
                    width={40}
                />
                <HeadlineWrap>
                    <SmallButton onClick={onEdit}>
                        <IconChange />
                    </SmallButton>

                    <SmallButton onClick={onRemove}>
                        <IconRemove />
                    </SmallButton>
                </HeadlineWrap>
            </TempPlanSwitchHeader>
            <TempPlanItem>
                <TempPlanText>{name}</TempPlanText>

                <TempPlanItemFlex>
                    <TempPlanLabel>
                        {t('thermal.instructionsCount')}: {instructionsCount}
                    </TempPlanLabel>
                </TempPlanItemFlex>
            </TempPlanItem>
        </TempPlanSwitchBox>
    );
};

type ChildLockProps = {
    value: boolean;
    onChange: () => void;
};

export const ChildLock: React.FC<ChildLockProps> = ({ value, onChange }) => {
    return (
        <Switch
            onChange={onChange}
            checked={value}
            onColor="#0179D4"
            offColor="#B9B9B9"
            onHandleColor="#FFFFFF"
            offHandleColor="#FFFFFF"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow=" 0px 1px 4px rgba(0, 0, 0, 0.25)"
            activeBoxShadow=" 0px 1px 4px rgba(0, 0, 0, 0.25)"
            height={16}
            width={40}
        />
    );
};

type ThermalProfileEditFormProps = {
    deviceId?: number;
    profileId?: number | null;
    cancelEdit: () => void;
    done: () => void;
};

export const ThermalProfileEditForm: React.FC<ThermalProfileEditFormProps> = ({
    deviceId = null,
    profileId = null,
    cancelEdit,
    done,
}) => {
    const { t } = useTranslation();
    const { data, refetch } = useQuery(`getThermalProfileById-${profileId}`, () => getProfileById(profileId as number), {
        enabled: !!profileId,
    });

    const onSubmit = useCallback(async (values) => {
        if (!values?.name || values.name.length === 0) {
            showFlashMessage(t('thermal.pleaseFillTempProfileName'), 'error');
            return;
        }

        if (!values?.instructions || values.instructions.length === 0) {
            showFlashMessage(t('thermal.pleaseEnterSomeInstructions'), 'error');
            return;
        }

        try {
            const result = await editThermalProfile({
                ...values,

                deviceId,
            });
            showFlashMessage(t('thermal.thermalProfileSuccessfullySaved'));
            done();
        } catch (e) {
            showFlashMessage(t('thermal.thermalProfileNotSaved'), 'error');
        }
    }, []);

    console.log(data);

    return (
        <Form<any>
            mutators={{
                ...arrayMutators,
            }}
            initialValues={data ? data : {}}
            onSubmit={onSubmit}
            render={(formProps) => (
                <form
                    onSubmit={formProps.handleSubmit}>
                    <InputField 
                        name="name" 
                        label="thermal.profileName" 
                        type="text" 
                        required={true} 
                        disabled={!!profileId}
                        />

                    <TempPlanSwitchContent>
                        <FieldArray name="instructions">
                            {(props) =>
                                props.fields.map((name, index) => (
                                    <Instruction
                                        name={name}
                                        index={index}
                                        remove={() => formProps.form.mutators.remove(`instructions`, index)}
                                    />
                                ))
                            }
                        </FieldArray>
                    </TempPlanSwitchContent>

                    <br />
                    <TempPlanFooterButtonAccept
                        onClick={(e) => {
                            e.preventDefault();
                            formProps.form.mutators.push('instructions', {
                                externalId: null,
                                active: true,
                                temp: 20,
                                days: ['mo', 'tu', 'we', 'th', 'fr', 'su', 'sa'],
                                start: '12:00',
                            });
                        }}
                    >
                        +
                    </TempPlanFooterButtonAccept>

                    <br />
                    <TempPlanFooterButtonAccept onClick={() => formProps.form.submit()}>
                        {t('global.save')}
                    </TempPlanFooterButtonAccept>
                    <TempPlanFooterButtonReject onClick={cancelEdit}>{t('global.close')}</TempPlanFooterButtonReject>
                </form>
            )}
        />
    );
};

type InstructionProps = {
    name: string;
    index: number;
    remove: () => void;
};

export const Instruction: React.FC<InstructionProps> = ({ name, index, remove }) => {
    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const { input, meta } = useField(name, {
        validate: (value, values) => {
            console.log(value);
            if (value?.days.length === 0) {
                return 'missing';
            }
        },
    });

    useEffect(() => {
        setEditMode(false);
    }, [index]);

    const namesTranslated = useMemo(() => {
        return input.value.days.map((val: string) => t(`global.daysShort.${val}`));
    }, [input.value]);

    if (editMode) {
        return (
            <TempPlanBox>
                <div>
                    <TempPicker name={`${name}.temp`} minTemp={MIN_TEMP} maxTemp={MAX_TEMP} />
                    <DayPicker name={`${name}.days`} error={!meta.valid} />
                    <TimePicker name={`${name}.start`} />
                </div>
                <TempPlanFooter>
                    <TempPlanFooterButtonAccept onClick={() => setEditMode(false)}>
                        {t('global.save')}
                    </TempPlanFooterButtonAccept>
                    <TempPlanFooterButtonReject onClick={() => remove()}>
                        {t('global.remove')}
                    </TempPlanFooterButtonReject>
                </TempPlanFooter>
            </TempPlanBox>
        );
    }

    return (
        <TempPlanSwitchBox error={!meta.valid}>
            <TempPlanSwitchHeader>
                <Switch
                    onChange={() =>
                        input.onChange({
                            ...input.value,
                            active: !input.value.active,
                        })
                    }
                    checked={input.value.active}
                    onColor="#0179D4"
                    offColor="#B9B9B9"
                    onHandleColor="#FFFFFF"
                    offHandleColor="#FFFFFF"
                    handleDiameter={20}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow=" 0px 1px 4px rgba(0, 0, 0, 0.25)"
                    activeBoxShadow=" 0px 1px 4px rgba(0, 0, 0, 0.25)"
                    height={16}
                    width={40}
                />
            </TempPlanSwitchHeader>
            <TempPlanItem onClick={() => setEditMode(true)}>
                <TempPlanText>
                    {namesTranslated.length ? namesTranslated.join(', ') : t('thermal.pleaseChoseDays')}
                </TempPlanText>
                <TempPlanItemFlex>
                    <TempPlanLabel>
                        {t('start')}: {input.value.start}
                    </TempPlanLabel>
                    <TempPlanLabel>{input.value.temp} °C</TempPlanLabel>
                </TempPlanItemFlex>
            </TempPlanItem>
        </TempPlanSwitchBox>
    );
};

type DayPickerProps = {
    name: string;
    error: boolean;
};

export const DayPicker: React.FC<DayPickerProps> = ({ name, error }) => {
    const { t } = useTranslation();

    const wrapper = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const { input, meta } = useField(name);

    useEffect(() => {
        const handler = () => {
            setOpen(false);
        };

        wrapper?.current?.addEventListener('mouseleave', handler);

        return () => {
            wrapper?.current?.removeEventListener('mouseleave', handler);
        };
    }, []);

    const days = ['mo', 'tu', 'we', 'th', 'fr', 'su', 'sa'];

    const handleClick = (day: string) => {
        let values = [...input.value];
        if (input.value.includes(day)) {
            values.splice(values.indexOf(day), 1);
        } else {
            values.push(day);
        }
        input.onChange(values);
    };

    const namesTranslated = useMemo(() => {
        return input.value.map((val: string) => t(`global.daysShort.${val}`));
    }, [input.value]);

    return (
        <TempPlanItemButton ref={wrapper} error={error}>
            <div>
                <TempPlanLabel>{t('global.days')}</TempPlanLabel>
                <TempPlanText>
                    {namesTranslated.length ? namesTranslated.join(', ') : t('thermal.pleaseChoseDays')}
                </TempPlanText>
            </div>

            <TempDropdownButton type="button" onClick={() => setOpen(true)}>
                <IconChevronRight />
                {open && (
                    <TempDropdownBox hidden={!open}>
                        {days.map((day) => (
                            <TempDropdownItem type="button" onClick={() => handleClick(day)}>
                                {day}
                                {input?.value?.includes(day) && <IconCheck />}
                            </TempDropdownItem>
                        ))}
                    </TempDropdownBox>
                )}
            </TempDropdownButton>
        </TempPlanItemButton>
    );
};

export type TempPicker = {
    name: string;
    minTemp: number;
    maxTemp: number;
};

export const TempPicker: React.FC<TempPicker> = ({ name, minTemp, maxTemp }) => {
    const { t } = useTranslation();

    const wrapper = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const { input, meta } = useField(name);

    useEffect(() => {
        const handler = () => {
            setOpen(false);
        };

        wrapper?.current?.addEventListener('mouseleave', handler);

        return () => {
            wrapper?.current?.removeEventListener('mouseleave', handler);
        };
    }, []);

    const temps = inclusiveRange(minTemp, maxTemp);

    return (
        <TempPlanItemButton ref={wrapper}>
            <div>
                <TempPlanLabel>{t('device.temp')}</TempPlanLabel>
                <TempPlanText>{input.value} °C</TempPlanText>
            </div>
            <TempDropdownButton type="button" onClick={() => setOpen(true)}>
                <IconChevronRight />
                <TempDropdownBox hidden={!open}>
                    {temps.map((temp) => (
                        <TempDropdownItem type="button" onClick={() => input.onChange(temp)}>
                            {temp} °C
                        </TempDropdownItem>
                    ))}
                </TempDropdownBox>
            </TempDropdownButton>
        </TempPlanItemButton>
    );
};

type TimePickerProps = {
    name: string;
};

export const TimePicker: React.FC<TimePickerProps> = ({ name }) => {
    const { t } = useTranslation();

    const wrapper = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const { input, meta } = useField(name);

    useEffect(() => {
        const handler = () => {
            setOpen(false);
        };

        wrapper?.current?.addEventListener('mouseleave', handler);

        return () => {
            wrapper?.current?.removeEventListener('mouseleave', handler);
        };
    }, []);

    const values = useMemo(() => {
        const values: string[] = [];
        const hours = Array.from({ length: 24 }, (_, index) => index);

        for (let hour of hours) {
            values.push(zeroPad(hour) + ':00');
            values.push(zeroPad(hour) + ':30');
        }

        return values;
    }, []);

    return (
        <TempPlanItemButton ref={wrapper}>
            <div>
                <TempPlanLabel>{t('start')}</TempPlanLabel>
                <TempPlanText>{input.value}</TempPlanText>
            </div>
            <TempDropdownButton type="button" onClick={() => setOpen(true)}>
                <IconChevronRight />
                <TempDropdownBox hidden={!open}>
                    {values.map((val) => (
                        <TempDropdownItem type="button" onClick={() => input.onChange(val)}>
                            {val}
                        </TempDropdownItem>
                    ))}
                </TempDropdownBox>
            </TempDropdownButton>
        </TempPlanItemButton>
    );
};
