import {useCallback, useEffect, useState} from "react";
import {useAuthContext} from "./auth-context";
import {finishAuthentication, logout,  startAuthentication, throwError} from "./auth-reducer";
import {getUser, loginCheck, refreshToken} from "../api/user";
import {AccessToken, LoginCheckParams, RefreshToken} from "../api/api-types";
import {profileIsLoaded, setLoggedUser} from "../api/profile-reducer";
import {useHistory} from "react-router";
import {useStructures} from "../api/useStructures";
import {useProfile} from "../api/useProfile";

export const useAuth = () => {
    const history = useHistory();
    const {authState, authDispatch} = useAuthContext();

    const loginAction = useCallback(async (loginParams: LoginCheckParams) => {
        authDispatch(startAuthentication());
        try {
            const loginCheckResult = await loginCheck(loginParams);

            if (loginCheckResult.token) {
                authDispatch(finishAuthentication({
                    accessToken: loginCheckResult.token as AccessToken,
                    refreshToken: loginCheckResult.refresh_token as RefreshToken,
                }));
            }else{
                console.log('ELSE');
                // authDispatch(throwError(e.message));
            }

        } catch (e) {
            console.log('CATCH');
            authDispatch(throwError(e.message));
        }
    }, [authDispatch]);

    const logoutAction = useCallback(() => {
        authDispatch(logout());
    }, [authDispatch]);

    return {
        error: authState.error,
        accessToken: authState.authTokens?.accessToken,

        authInProgress: authState.authentication,
        enablePrivateRoutes: authState.tokensOk,

        loginAction,
        logoutAction
    }
}
