import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 14px;
  overflow: auto;
`;

type ButtonActive = {
  active?: true | false
}
export const Button = styled.button<ButtonActive>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: solid 1px transparent;
  min-height: 58px;
  padding: 4px 16px;
  cursor: pointer;
  outline: none;  
  box-shadow: ${(props) => props.active ? '0 3px 5px' + props.theme.colors.shadow : "0 1px 2px rgba(0, 0, 0, 0.1);"};
  border-radius: 2px;
  background: ${(props) => props.active ? props.theme.colors.main : "#fff"};
  color: ${(props) => props.active ? "#fefefe" : "#11181a"};
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  transition: all 300ms ease;
  &:hover {
    border-color: ${props => props.theme.colors.main};;
  }
`;
Button.defaultProps = {
  active: false
}
export const Span = styled.span`
  position: relative;
`;
