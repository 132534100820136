import styled from "styled-components";

export const UserWrapper = styled.div`
  display: flex;
  border-radius: 100px;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin: auto auto 17px;
  background-color: ${props => props.theme.colors.shadow};
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  span{
    color: white;
    
      font-size: calc(var(32px) / 2);
      line-height: 1;
      position: relative;
      top: calc(var(32px) / 4); 
  }
`;
