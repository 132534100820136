import React, { FC } from "react";
import { Switch } from "react-router";
import AppPage from "../pages/AppPage";
import DashboardPage from "../pages/DashboardPage";
import { PrivateRoute } from "../modules/auth/router/PrivateRoute";
import { PublicRoute } from "../modules/auth/router/PublicRoute";

import { routes as authRoutes } from "../modules/auth/router/routes";
import LogoutPage from "../pages/LogoutPage";
import UsersPage from "../pages/UsersPage";

const routes = [
  ...authRoutes,
  {
    path: "/",
    component: DashboardPage,
    exact: true,
    needAuth: true,
  },
  {
    path: "/measurements",
    component: AppPage,
    exact: true,
    needAuth: true,
  },
  {
    path: "/users",
    component: UsersPage,
    needAuth: true,
  },
  {
    path: "/logout",
    component: LogoutPage,
    exact: true,
    needAuth: true,
  },
] as Route[];

type Route = {
  component: FC;
  path: string;
  exact: boolean;
  needAuth: boolean;
};

export default function Routes() {
  return (
    <Switch>
      {routes.map((route, index) => {
        if (route.needAuth) {
          return (
            <PrivateRoute
              key={index}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          );
        }

        return (
          <PublicRoute
            key={index}
            path={route.path}
            component={route.component}
            exact={route.exact}
          />
        );
      })}
    </Switch>
  );
}
