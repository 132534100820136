import styled, { keyframes } from "styled-components";
import {MessageType} from "./index";

type MessageWrapperProps = {
    type: MessageType
}

const backgroundColor = (type: MessageType): string => {
    switch(type){
        case 'success':
            return '#4BCA81';
        case 'error':
            return '#cc0000';
        default:
            return '#333';
    }
}

const FadeInAnimation = keyframes`
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
`

const FadeOutAnimation = keyframes`
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
`

export const MessageWrapper = styled.div<MessageWrapperProps>`
  min-width: 250px;
  margin-left: -125px; 
  background-color: ${(props) => backgroundColor(props.type)};
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 100;
  left: 50%;
  bottom: 30px;  
  -webkit-animation: ${FadeInAnimation} 0.5s, ${FadeOutAnimation} 0.5s 2.5s;
  animation: ${FadeInAnimation} 0.5s, ${FadeOutAnimation} 0.5s 2.5s;
`
