import React, { FC, useMemo } from 'react';
import useAdminStructures from '../../api/useAdminStructures';
import SelectField from '../../../components/Primitives/SelectField';

type BuildingSelectField = {
    label: string;
    name: string;
};

const BuildingSelectField: FC<BuildingSelectField> = ({ label, name }) => {
    const { buildings } = useAdminStructures();

    const preparedBuildings = useMemo(() => {
        return buildings.map((building) => ({
            label: `${building.address.addressLine}, ${building.address.cityLine}`,
            value: building.id,
        }));
    }, [buildings]);

    return <SelectField width={250} label={label} name={name} choices={preparedBuildings} />;
};

export default BuildingSelectField;
