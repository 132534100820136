import {useAuth} from "../auth";
import {useProfileContext} from "./profile-context";
import {updateProfile} from "./user";
import {UpdateProfileInput} from "./api-types";
import {clear, setLoggedUser} from "./profile-reducer";
import {useCallback} from "react";

export const useProfile = () => {
    const {accessToken} = useAuth();
    const {profileState, profileDispatch} = useProfileContext();

    const saveProfile = async (input: UpdateProfileInput): Promise<boolean> => {
        if(accessToken){
            const result = await updateProfile(accessToken, input);

            if(result.user){
                profileDispatch(setLoggedUser(result.user));

                return true;
            }

            return false;
        }

        throw Error('Missing access token');
    }

    const clearProfile = useCallback(() => {
        profileDispatch(clear());
    }, [profileDispatch]);

    return {
        loggedUser: profileState.user,
        saveProfile,
        clearProfile
    }
}
