import { Reducer } from 'react';
import { Device, DeviceId, StructureId } from '../modules/api/api-types';

const OPEN_MODAL = 'openModal';
const CLOSE_MODAL = 'closeModal';

type BaseAction<T, P = unknown> = {
    type: T;
    payload?: P;
};

function createAction<T, P>(type: T, payload?: P): BaseAction<T, P> {
    return {
        type,
        payload,
    };
}

type ModalsTypes =
    | 'EditUserProfile'
    | 'ExportBuilding'
    | 'ExportDevice'
    | 'ExportFlat'
    | 'StructureSettings'
    | 'StructureAccesses'
    | 'ErrorInfo';

export type ModalsState = {
    opened: null | ModalsTypes;
    args: object;
};

export type ModalsAction =
    | BaseAction<typeof OPEN_MODAL, { modal: ModalsTypes; args: object | null }>
    | BaseAction<typeof CLOSE_MODAL, null>;

const ModalsReducer: Reducer<ModalsState, ModalsAction> = (state, action) => {
    console.log(action.type, action.payload);
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                opened: action.payload && action.payload.modal ? action.payload.modal : null,
                args: action.payload && action.payload.args ? action.payload.args : {},
            };

        case CLOSE_MODAL: {
            return {
                ...state,
                opened: null,
                args: {},
            };
        }

        default:
            return state;
    }
};

export const openEditProfileModalAction = (): ModalsAction =>
    createAction(OPEN_MODAL, { modal: 'EditUserProfile', args: null });

export const openExportBuildingModalAction = (): ModalsAction =>
    createAction(OPEN_MODAL, { modal: 'ExportBuilding', args: null });

export const openStructureSettingsModalAction = (id: StructureId): ModalsAction =>
    createAction(OPEN_MODAL, { modal: 'StructureSettings', args: { id } });

export const openStructureAccessesModalAction = (id: StructureId): ModalsAction =>
    createAction(OPEN_MODAL, { modal: 'StructureAccesses', args: { id } });

export const openExportDeviceModalAction = (id: DeviceId): ModalsAction =>
    createAction(OPEN_MODAL, { modal: 'ExportDevice', args: { id } });

export const openErrorInfoModalAction = (error: string): ModalsAction =>
    createAction(OPEN_MODAL, { modal: 'ErrorInfo', args: { error } });

export const openExportFlatModalAction = (id: StructureId): ModalsAction => 
    createAction(OPEN_MODAL, { modal: 'ExportFlat', args: { id }});

export const closeAllModalsAction = (): ModalsAction => createAction(CLOSE_MODAL, null);

export default ModalsReducer;
