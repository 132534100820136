import styled from 'styled-components';

export const FilterBox = styled.div`
    display: flex;
    background: #fcfbfa;
    padding: 10px;
    align-items: end;
    & > div {
        margin-right: 10px;
    }
`;
