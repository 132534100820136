import React, { FC, useCallback } from 'react';
import { Modal } from '../components/Modal';
import { useTranslation } from 'react-i18next';
import { BigTitle } from './styles';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import InputField from '../components/Primitives/InputField';
import { Button } from '../components/Primitives/Button';
import { useModalArguments, useModals } from './useModals';
import { DeviceId, StructureId } from '../modules/api/api-types';
import { useExportMeasurements } from '../modules/export/useExportMeasurements';
import { showFlashMessage } from '../components/FlashMessages';
import { yearEnd, yearStart } from '../helper/date';

type ExportDeviceModalValues = {
    from: string;
    to: string;
};

type Arguments = {
    id: DeviceId;
};

const Row = styled.div`
    display: -webkit-flex;
    display: flex;
`;

const Column = styled.div`
    padding-right: 15px;
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExportDeviceModal: FC = () => {
    const { t } = useTranslation();
    const { id } = useModalArguments<Arguments>();
    const { closeAllModals } = useModals();
    const { exportDevice } = useExportMeasurements();

    const handleSubmit = useCallback(
        async (values: ExportDeviceModalValues) => {
            console.log({ id, values });
            if (id) {
                try {
                    showFlashMessage(t(`device.export.runningExportDevice`));
                    await exportDevice(id, new Date(values.from), new Date(values.to));
                    closeAllModals();
                } catch (e) {
                    showFlashMessage(t(`device.export.runningExportFailed`), 'error');
                }
            }
        },
        [id],
    );

    return (
        <Modal>
            <BigTitle>{t('device.export.title')}</BigTitle>

            <Form<ExportDeviceModalValues>
                initialValues={{
                    from: yearStart(),
                    to: yearEnd(),
                }}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <form onSubmit={props.handleSubmit}>
                        <Row>
                            <Column>
                                <InputField name="from" label="global.fromDate" type="date" max={props.values.to} />
                            </Column>
                            <Column>
                                <InputField name="to" label="global.toDate" type="date" min={props.values.from} />
                            </Column>
                            <Column>
                                <Button variant="big" disabled={props.submitting}>
                                    {t('device.export.button')}
                                </Button>
                            </Column>
                        </Row>
                    </form>
                )}
            </Form>
        </Modal>
    );
};

export default ExportDeviceModal;
