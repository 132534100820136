import { DeviceId } from '../api-types';
import axios from './../axios';
import settings from '../settings';

type ExportDeviceDataProps = {
    language: string;
    device: DeviceId;
    from: Date;
    to: Date;
};

export async function exportDeviceData(props: ExportDeviceDataProps) {
    const response = await axios.get(`${settings.apiBasePath}/api/export/export-device-data`, {
        responseType: 'arraybuffer',
        params: props,
    });

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(response.data.message);
}
