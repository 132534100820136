export default {
    save: 'Auferlegen',
    logout: 'Ausloggen',
    loginForm: {
        fill: 'Geben sie ihre email-adresse und ihr passwort ein.',
        badCredentials: 'Falsche email oder Passwort.',
    },
    fromDate: 'Von',
    toDate: 'Zu',

    all: 'Hinzufügen',
    settings: 'die Einstellungen',
    change: 'Veränderung',
    close: 'Schließen',
    edit: 'Bearbeiten',
    accesses: 'Zugriffe',
    removeAccess: 'Zugriff entfernen',
    unlimited: 'Unbegrenzt',

    search: 'Suche',
    remove: 'Entfernen',

    user: {
        firstName: 'Name',
        lastName: 'Nachname',
        email: 'Email',
        emailOrUsername: 'Email/Nutzername',
        currentPassword: 'Jetziges Passwort',
        password: 'Passwort',
        passwordAgain: 'Passwort erneut',
        newPassword: 'Neues kennwort',
        newPasswordAgain: 'Neues passwort nochmal',
        contactEmail: 'Kontakt e-mail',
        contactPhone: 'Kontakt-Telefon',
    },

    messages: {
        successfullySaved: 'Erfolgreich gespeichert',
        globalError: 'Etwas ist kaputt gegangen, bitte versuchen Sie es später noch einmal.',
    },

    introMessage: {
        odecty: 'Willkommen bei der Anwendung von Fernablesungen.',
    },

    languages: {
        cs: 'Česky',
        en: 'English',
        de: 'Deutsche',
        sk: 'Slovensky',
    },

    daysShort: {
        mo: 'Mo', 
        tu: 'Di', 
        we: 'Mi', 
        th: 'So', 
        fr: 'Fr', 
        su: 'Sa', 
        sa: 'So'
    },

    days: {
        mo: 'Montag', 
        tu: 'Dienstag', 
        we: 'Mittwoch', 
        th: 'Donnerstag', 
        fr: 'Freitag', 
        su: 'Samstag', 
        sa: 'Sonntag'
    }
};
