export default {
    loadingData: 'Načítam',
    noData: 'Bez údajov',
    todayConsumption: 'Denná spotreba',
    monthlyConsumption: 'Mesačná spotreba',
    thisMonthConsumption: 'Spotreba tohto mesiaca',
    lastMonthConsumption: 'Spotreba minulý mesiac',
    valve: 'Ventil',
    measurementState: {
        cold: 'Stav vodomeru',
        hot: 'Stav vodomeru',
        itn: 'Stav RTN',
        cal: 'Stav kalorimetra',
        cntrl: 'Stav vodomeru',
        thermo: 'Teplota',
        '-': 'Stav',
    },
    measurementType: {
        cold: 'Studená voda',
        hot: 'Teplá voda',
        itn: 'RTN',
        cal: 'Kalorimeter',
        cntrl: 'Kontrolný vodomer',
        thermo: 'Termohlavica',
        '-': 'Neznáme zariadenie',
    },
    export: {
        title: 'Export',
        runningExportDevice: 'Spúšťam export zariadenia',
        runningExportFinalMeasurements: 'Spúšťam export koncových námerov',
        runningExportFailed:
            'Export zlyhal. Náš tím bol o tomto incidente informovaný, čoskoro sa ho pokúsime vyriešiť.',
        button: 'Export námerov za obdobie',
        buttonStructure: 'Export koncových námerov v období',
    },
    errors: {
        leak: 'Únik',
        'battery warning': 'Blíži sa vybitie batérie',
        'no consumption': 'Žiadna spotreba',
        tamper: 'Pokus o ovplyvnenie',
        removal: 'Odstránenie modulu',
        'reverse installation': 'Opačná inštalácia',
        oversized: 'Naddimenzovaný merač',
        undersized: 'Poddimenzovaný merač',
        burst: 'Prasklina',
        'battery EOL': 'Koniec životnosti batérie',
        dry: 'Vodomer "na suchu"',
        frost: 'Zamŕza!',
        backflow: 'Spätný prietok',
        'battery prewarning': 'Blíži sa vybitie batérie',
    },
    errorDescriptions: {
        leak: 'Prietok meradlom je nenulový viac ako 24 hodín, skontrolujte vedenie a spotrebiče.',
        'battery warning': 'Stav batérie indikuje 3 mesiace do vybitia vysielača, kontaktujte správcu.',
        'no consumption': 'Pri vodomere nie je dlhšiu dobu zaznamnaná spotreba, môže sa jednať o nevyužitý priestor.',
        tamper: 'Podozrenie na vonkajšie ovplyvnenie spotreby, vykonajte vizuálnu kontrolu meradla.',
        removal: 'Podozrenie na odstránenie modulu z merača, vykonajte vizuálnu kontrolu meradla.',
        'reverse installation': 'Obrátený prietok meradlom viac ako 12 hodín, kontaktujte servisného technika.',
        oversized: 'Nastane, ak prietok nikdy neprekročí 10% Q3 (po dobu 30 dní)',
        undersized: 'Nastane, ak je prietok bez prerušenia väčší ako Q3 (po dobu 6 hodín)',
        burst: 'Únik je vyšší ako 30% nominálneho prietoku za dobu 30 minút, uzavrite prívod vody a skontrolujte vedenie.',
        'battery EOL': 'Batéria meradla sa vybila, kontaktujte správcu pre výmenu meradla.',
        dry: 'Zatvorený prívod vody do meradla. Skontrolujte uzáver vody.',
        frost: 'Riziko zamrznutia a poškodenia tela vodomeru. Nutná kontrola servisného technika.',
        backflow:
            'Meradlo zaznamenalo spätný prietok, odporúčania kontroly zmiešavacích zariadení a montáže spätnej klapky.',
        'battery prewarning': 'Stav batérie indikuje 3 roky do vybitia vysielača, kontaktujte správcu.',
    },
};
