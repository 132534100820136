import React, {FC, useEffect, useRef, useState} from 'react';
// @ts-ignore
import Chart from "./../../modules/chart";
import {GraphData, Measure} from "../../modules/api/api-types";
import {maxWidth} from "styled-system";
import styled, {useTheme} from "styled-components";
import {AppTheme} from "../../theme/theme";

type ConsuptionChartProps = {
  graphData: GraphData
}

const Graph = styled.canvas`
  //56 - side menu, 10 and 15 - padding
  max-width: calc(100vw - 56px - 10px*2 - 15px*2);
  max-height: 250px;
  width: 100% !important;
`

const formatDate = (val: string): string => {
  const date = new Date(val);

  return `${date.getDate()}.${date.getMonth() + 1}`
}

export const ConsuptionChart: FC<ConsuptionChartProps> = ({graphData }) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const [graph, setGraph] = useState<Chart|null>(null);

  const theme = useTheme() as AppTheme;

  // ${app.type === 2? 'kWh' : 'm³'}

  useEffect(() => {
    if(canvas.current){
      const dataset = {
        label: '',
        borderColor: theme.colors.main,
        backgroundColor: theme.colors.main,
        data: graphData.measures.map(measurement => measurement.value)
      }

      const labels = graphData.measures.map(measurement => formatDate(measurement.date));

      const newChart = new Chart(canvas.current, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [dataset]
        },
        options: {
          maintainAspectRatio: false,
          // @ts-ignore
          cornerRadius: 200,
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data){
                // @ts-ignore
                return `${tooltipItem.yLabel.toFixed(4)}`;
              }
            },
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                display: false,
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                // @ts-ignore
                maxBarThickness: 20,
                // scaleShowLabels : true,
                drawBorder: false,
                gridLines: {
                  display: false,
                  lineWidth: 0
                },
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 20,
                  maxRotation: 0,
                  minRotation: 0
                }
              }
            ]
          }
        }
      });
    }
  }, [canvas])

  return (
      <Graph ref={canvas} width="100%" height="250"/>
  );
};
