import React, {useEffect} from "react";
import {AppLayout, MiddleContent} from "../../../components/Layout/AppLayout";
import {Logo} from "../../../components/Primitives/Logo";
import {ShadowedBox} from "../../../components/Primitives/Box";
import {LoginForm} from "../../../components/Forms/Login";
import {useAuth} from "../useAuth";
import {useStructures} from "../../api/useStructures";
import {useProfile} from "../../api/useProfile";
import usePortal from "../../../portal/usePortal";
import {useTranslation} from "react-i18next";
import LanguageSelector from "../../../components/LanguageSelector/LanguageSelector";

export default function LoginPage() {
    const { logoutAction } = useAuth();
    const { clearStructures } = useStructures();
    const { clearProfile } = useProfile();
    const {t} = useTranslation();
    const { introMessage } = usePortal();

    useEffect(() => {
        logoutAction();
        clearStructures();
        clearProfile();
    }, [])

    return (
        <AppLayout>
            <MiddleContent>
                <Logo/>
                <ShadowedBox>
                    {introMessage && <p style={{textAlign:'center'}}>{t(introMessage)}</p>}
                    <LoginForm/>


                </ShadowedBox>
            </MiddleContent>
        </AppLayout>
    );
}
