import { useCallback, useEffect, useState } from 'react';
import { StructureId, UserBuilding } from './api-types';
import { filterBuildings } from './structure/filterBuildings';
import { getSubStructureSimple } from './structure/getSubStructureSimple';

const useAdminStructures = () => {
    const [buildings, setBuildings] = useState<UserBuilding[]>([]);

    const loadStructures = useCallback(async () => {
        const result = await filterBuildings({
            fullText: '',
            page: 1,
            limit: 1000,
        });

        if (result) {
            setBuildings(result.filter.records);
        }
    }, []);

    const loadSubStructures = useCallback(async (structureId: StructureId) => {
        const result = await getSubStructureSimple(structureId);

        if (result) {
            return result.structures;
        }

        return [];
    }, []);

    useEffect(() => {
        loadStructures().then();
    }, []);

    return {
        buildings,
        loadSubStructures,
    };
};

export default useAdminStructures;
