import React, {useEffect} from "react";
import {AppLayout} from "../components/Layout/AppLayout";
import { SideMenu } from "../components/SideMenu";
import {useProfile} from "../modules/api/useProfile";
import {useHistory} from "react-router";

export default function DashboardPage() {
    const history = useHistory();

    useEffect(() => {
        history.push('/measurements');
    })

    return (
        <AppLayout>
            <SideMenu/>
            {/*<SideBar/>*/}
            {/*<Content />*/}
        </AppLayout>
    );
}
